import react from "react";
import { Modal, Button } from "antd";
import { isInternetConnected } from "../Utils/InternetConnection";
import { appMessages } from "../themes/apiConstants";

const ModalPop = (props) => {
  const {
    title,
    content,
    btnSuccess,
    btnCancel,
    handleSuccess,
    handleCancel,
    isVisible,
    center,
  } = props;

  return (
    <Modal
      centered
      className={center}
      title={title}
      visible={isVisible}
      onOk={handleSuccess}
      onCancel={handleCancel}
      footer={[btnSuccess ? btnSuccess : null]}>
      <p>{content}</p>
    </Modal>
  );
};

export default ModalPop;

export const confirmationModalBlockAndDelete = (
  record,
  action,
  Delete = false,
  type = 'user'
) => {
  let titleMsg = record?.isBlocked === true ? "Unblock" : "Block";

  Modal.confirm({
    title: Delete ? "Delete" : titleMsg,
    content: appMessages.areYouSure(Delete ? "delete" : titleMsg.toLowerCase(), type),
    okText: "Yes",
    centered: true,
    cancelText: "No",
    onOk() {
      isInternetConnected() && action();
    },
    onCancel() { },
    className: "new-button",
  });
};


export const confirmationModalAcceptReject = (
  action,
  Accept = false,
  type
) => {

  Modal.confirm({
    title: Accept ? "Accept" : "Reject",
    content: appMessages.areYouSureForAccept(Accept ? "accept" : "reject", type),
    okText: "Yes",
    centered: true,
    cancelText: "No",
    onOk() {
      isInternetConnected() && action();
    },
    onCancel() { },
    className: "new-button",
  });
};
