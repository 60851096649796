export const apiConstants = {
  // ************ AUTHENTICATION ************
  // Authentications types
  API_AUTHENTICATION_FAILED: "API_AUTHENTICATION_FAILED",
  API_AUTHENTICATION_ERROR: "API_AUTHENTICATION_ERROR",

  // Login
  API_LOGIN_LOAD: "API_LOGIN_LOAD",
  API_LOGIN_SUCCESS: "API_LOGIN_SUCCESS",

  // forgot password
  API_FORGOT_PASSWORD_LOAD: "API_FORGOT_PASSWORD_LOAD",
  API_FORGOT_PASSWORD_SUCCESS: "API_FORGOT_PASSWORD_SUCCESS",

  // Change forgot password success status
  API_FORGOT_PASSWORD_SUCCESS_STATUS_CHANGE: "API_FORGOT_PASSWORD_SUCCESS_STATUS_CHANGE",


  // Reset Password
  API_RESET_PASSWORD_LOAD: "API_RESET_PASSWORD_LOAD",
  API_RESET_PASSWORD_SUCCESS: "API_RESET_PASSWORD_SUCCESS",

  // Reset Password
  API_CHECK_RESET_PASSWORD_LOAD: "API_CHECK_RESET_PASSWORD_LOAD",
  API_CHECK_RESET_PASSWORD_SUCCESS: "API_CHECK_RESET_PASSWORD_SUCCESS",

  // change password
  API_CHANGE_PASSWORD_LOAD: "API_CHANGE_PASSWORD_LOAD",
  API_CHANGE_PASSWORD_SUCCESS: "API_CHANGE_PASSWORD_SUCCESS",

  //user answer list
  API_USER_ANSWER_LISTING_LOAD: "API_USER_ANSWER_LISTING_LOAD",
  API_USER_ANSWER_LISTING_SUCCESS: "API_USER_ANSWER_LISTING_SUCCESS",


  //logout
  API_LOGOUT_LOAD: "API_LOGOUT_LOAD",
  API_LOGOUT_SUCCESS: "API_LOGOUT_SUCCESS",

  // ************ DASHBOARD ************

  // dashboard
  API_DASHBOARD_LOAD: "API_DASHBOARD_LOAD",
  API_DASHBOARD_SUCCESS: "API_DASHBOARD_SUCCESS",

  // update referral
  API_REFERRAL_LOAD: "API_REFERRAL_LOAD",
  API_REFERRAL_SUCCESS: "API_REFERRAL_SUCCESS",

  // DASHBOARD FAILED CASE
  API_DASHBOARD_FAILED: "API_DASHBOARD_FAILED",
  API_DASHBOARD_ERROR: "API_DASHBOARD_ERROR",

  // ************ SUGGESTED CATEGORY MANAGEMENT ************
  // category management
  API_SUGGESTED_CATEGORY_MANAGEMENT_LISTING_LOAD: "API_SUGGESTED_CATEGORY_MANAGEMENT_LISTING_LOAD",
  API_SUGGESTED_CATEGORY_MANAGEMENT_LISTING_SUCCESS: "API_SUGGESTED_CATEGORY_MANAGEMENT_LISTING_SUCCESS",

  // category management FAILED CASE
  API_SUGGESTED_CATEGORY_MANAGEMENT_FAILED: "API_SUGGESTED_CATEGORY_MANAGEMENT_FAILED",
  API_SUGGESTED_CATEGORY_MANAGEMENT_ERROR: "API_SUGGESTED_CATEGORY_MANAGEMENT_ERROR",

  // ************ SUGGESTED QUESTION MANAGEMENT ************
  // category management
  API_SUGGESTED_QUESTION_MANAGEMENT_LISTING_LOAD: "API_SUGGESTED_QUESTION_MANAGEMENT_LISTING_LOAD",
  API_SUGGESTED_QUESTION_MANAGEMENT_LISTING_SUCCESS: "API_SUGGESTED_QUESTION_MANAGEMENT_LISTING_SUCCESS",

  // category management FAILED CASE
  API_SUGGESTED_QUESTION_MANAGEMENT_FAILED: "API_SUGGESTED_QUESTION_MANAGEMENT_FAILED",
  API_SUGGESTED_QUESTION_MANAGEMENT_ERROR: "API_SUGGESTED_QUESTION_MANAGEMENT_ERROR",


  // ************ CATEGORY MANAGEMENT ************
  // category management
  API_CATEGORY_MANAGEMENT_LISTING_LOAD: "API_CATEGORY_MANAGEMENT_LISTING_LOAD",
  API_CATEGORY_MANAGEMENT_LISTING_SUCCESS: "API_CATEGORY_MANAGEMENT_LISTING_SUCCESS",

  // category management drop
  API_CATEGORY_MANAGEMENT_LISTING_DROP_LOAD: "API_CATEGORY_MANAGEMENT_LISTING_DROP_LOAD",
  API_CATEGORY_MANAGEMENT_LISTING_DROP_SUCCESS:
    "API_CATEGORY_MANAGEMENT_LISTING_DROP_SUCCESS",

  // category management add
  API_CATEGORY_MANAGEMENT_ADD_LOAD: "API_CATEGORY_MANAGEMENT_ADD_LOAD",
  API_CATEGORY_MANAGEMENT_ADD_SUCCESS: "API_CATEGORY_MANAGEMENT_ADD_SUCCESS",

  // category management details
  API_CATEGORY_MANAGEMENT_DETAILS_LOAD: "API_CATEGORY_MANAGEMENT_DETAILS_LOAD",
  API_CATEGORY_MANAGEMENT_DETAILS_SUCCESS:
    "API_CATEGORY_MANAGEMENT_DETAILS_SUCCESS",

  // category management update
  API_CATEGORY_MANAGEMENT_UPDATE_LOAD: "API_CATEGORY_MANAGEMENT_UPDATE_LOAD",
  API_CATEGORY_MANAGEMENT_UPDATE_SUCCESS:
    "API_CATEGORY_MANAGEMENT_UPDATE_SUCCESS",

  // category management delete
  API_CATEGORY_MANAGEMENT_DELETE_LOAD: "API_CATEGORY_MANAGEMENT_DELETE_LOAD",
  API_CATEGORY_MANAGEMENT_DELETE_SUCCESS:
    "API_CATEGORY_MANAGEMENT_DELETE_SUCCESS",

  // category management enable disable
  API_CATEGORY_MANAGEMENT_ENABLE_DISABLE_LOAD: "API_CATEGORY_MANAGEMENT_ENABLE_DISABLE_LOAD",
  API_CATEGORY_MANAGEMENT_ENABLE_DISABLE_SUCCESS: "API_CATEGORY_MANAGEMENT_ENABLE_DISABLE_SUCCESS",

  // category management map list
  API_CATEGORY_MANAGEMENT_MAP_LIST_LOAD: "API_CATEGORY_MANAGEMENT_MAP_LIST_LOAD",
  API_CATEGORY_MANAGEMENT_MAP_LIST_SUCCESS: "API_CATEGORY_MANAGEMENT_MAP_LIST_SUCCESS",

  // category management delete
  API_SUGGESTED_CATEGORY_MANAGEMENT_ACCEPT_LOAD: "API_SUGGESTED_CATEGORY_MANAGEMENT_ACCEPT_LOAD",
  API_SUGGESTED_CATEGORY_MANAGEMENT_ACCEPT_SUCCESS: "API_SUGGESTED_CATEGORY_MANAGEMENT_ACCEPT_SUCCESS",

  API_SUGGESTED_CATEGORY_MANAGEMENT_ARCHIVE_LOAD: "API_SUGGESTED_CATEGORY_MANAGEMENT_ARCHIVE_LOAD",
  API_SUGGESTED_CATEGORY_MANAGEMENT_ARCHIVE_SUCCESS: "API_SUGGESTED_CATEGORY_MANAGEMENT_ARCHIVE_SUCCESS",

  // question management delete
  API_SUGGESTED_QUESTION_MANAGEMENT_ACCEPT_LOAD: "API_SUGGESTED_QUESTION_MANAGEMENT_ACCEPT_LOAD",
  API_SUGGESTED_QUESTION_MANAGEMENT_ACCEPT_SUCCESS: "API_SUGGESTED_QUESTION_MANAGEMENT_ACCEPT_SUCCESS",

  // question management archive
  API_SUGGESTED_QUESTION_MANAGEMENT_ARCHIVE_LOAD: "API_SUGGESTED_QUESTION_MANAGEMENT_ARCHIVE_LOAD",
  API_SUGGESTED_QUESTION_MANAGEMENT_ARCHIVE_SUCCESS: "API_SUGGESTED_QUESTION_MANAGEMENT_ARCHIVE_SUCCESS",

  // category management FAILED CASE
  API_CATEGORY_MANAGEMENT_FAILED: "API_CATEGORY_MANAGEMENT_FAILED",
  API_CATEGORY_MANAGEMENT_ERROR: "API_CATEGORY_MANAGEMENT_ERROR",

  // ************ QUESTIONS MANAGEMENT ************
  API_QUESTION_MANAGEMENT_LISTING_LOAD: "API_QUESTION_MANAGEMENT_LISTING_LOAD",
  API_QUESTION_MANAGEMENT_LISTING_SUCCESS: "API_QUESTION_MANAGEMENT_LISTING_SUCCESS",

  API_PLAYER_ANSWER_LISTING_LOAD: "API_PLAYER_ANSWER_LISTING_LOAD",
  API_PLAYER_ANSWER_LISTING_SUCCESS: "API_PLAYER_ANSWER_LISTING_SUCCESS",

  API_PLAYER_ANSWER_LISTING_FAILED: "API_PLAYER_ANSWER_LISTING_FAILED",

  API_QUESTION_MANAGEMENT_DETAILS_LOAD: "API_QUESTION_MANAGEMENT_DETAILS_LOAD",
  API_QUESTION_MANAGEMENT_DETAILS_SUCCESS: "API_QUESTION_MANAGEMENT_DETAILS_SUCCESS",

  API_QUESTION_MANAGEMENT_ADD_LOAD: "API_QUESTION_MANAGEMENT_ADD_LOAD",
  API_QUESTION_MANAGEMENT_ADD_SUCCESS: "API_QUESTION_MANAGEMENT_ADD_SUCCESS",

  API_QUESTION_MANAGEMENT_UPDATE_LOAD: "API_QUESTION_MANAGEMENT_UPDATE_LOAD",
  API_QUESTION_MANAGEMENT_UPDATE_SUCCESS: "API_QUESTION_MANAGEMENT_UPDATE_SUCCESS",

  API_QUESTION_MANAGEMENT_DELETE_LOAD: "API_QUESTION_MANAGEMENT_DELETE_LOAD",
  API_QUESTION_MANAGEMENT_DELETE_SUCCESS: "API_QUESTION_MANAGEMENT_DELETE_SUCCESS",

  API_QUESTION_MANAGEMENT_IMPORT_LOAD: "API_QUESTION_MANAGEMENT_IMPORT_LOAD",
  API_QUESTION_MANAGEMENT_IMPORT_SUCCESS: "API_QUESTION_MANAGEMENT_IMPORT_SUCCESS",

  API_QUESTION_MANAGEMENT_EXPORT_LOAD: "API_QUESTION_MANAGEMENT_EXPORT_LOAD",
  API_QUESTION_MANAGEMENT_EXPORT_SUCCESS: "API_QUESTION_MANAGEMENT_EXPORT_SUCCESS",

  API_QUESTION_MANAGEMENT_FAILED: "API_QUESTION_MANAGEMENT_FAILED",
  API_QUESTION_MANAGEMENT_ERROR: "API_QUESTION_MANAGEMENT_ERROR",

  // ************ USER MANAGEMENT ************
  // user management
  API_USER_MANAGEMENT_LISTING_LOAD: "API_USER_MANAGEMENT_LISTING_LOAD",
  API_USER_MANAGEMENT_LISTING_SUCCESS: "API_USER_MANAGEMENT_LISTING_SUCCESS",

  // user management details
  API_USER_MANAGEMENT_DETAILS_LOAD: "API_USER_MANAGEMENT_DETAILS_LOAD",
  API_USER_MANAGEMENT_DETAILS_SUCCESS: "API_USER_MANAGEMENT_DETAILS_SUCCESS",

  // user management update
  API_USER_MANAGEMENT_UPDATE_LOAD: "API_USER_MANAGEMENT_UPDATE_LOAD",
  API_USER_MANAGEMENT_UPDATE_SUCCESS: "API_USER_MANAGEMENT_UPDATE_SUCCESS",

  // user management block
  API_USER_MANAGEMENT_BLOCK_LOAD: "API_USER_MANAGEMENT_BLOCK_LOAD",
  API_USER_MANAGEMENT_BLOCK_SUCCESS: "API_USER_MANAGEMENT_BLOCK_SUCCESS",

  // user management delete
  API_USER_MANAGEMENT_DELETE_LOAD: "API_USER_MANAGEMENT_DELETE_LOAD",
  API_USER_MANAGEMENT_DELETE_SUCCESS: "API_USER_MANAGEMENT_DELETE_SUCCESS",


  // user management FAILED CASE
  API_USER_MANAGEMENT_FAILED: "API_USER_MANAGEMENT_FAILED",
  API_USER_MANAGEMENT_ERROR: "API_USER_MANAGEMENT_ERROR",

  // ************ POLL MANAGEMENT ************
  // In-App management
  API_INAPP_MANAGEMENT_LISTING_LOAD: "API_INAPP_MANAGEMENT_LISTING_LOAD",
  API_INAPP_MANAGEMENT_LISTING_SUCCESS: "API_INAPP_MANAGEMENT_LISTING_SUCCESS",


  // poll management FAILED CASE
  API_POLL_MANAGEMENT_FAILED: "API_POLL_MANAGEMENT_FAILED",
  API_POLL_MANAGEMENT_ERROR: "API_POLL_MANAGEMENT_ERROR",

  // clear poll details
  API_CLEAR_POLL_DETAILS_LOAD: "API_CLEAR_POLL_DETAILS_LOAD",

  // total no. of votes
  API_TOTAL_VOTES_LOAD: "API_TOTAL_VOTES_LOAD",
  API_TOTAL_VOTES_SUCCESS: "API_TOTAL_VOTES_SUCCESS",

  // ************ REPORT MANAGEMENT ************
  // report management
  API_REPORT_MANAGEMENT_LISTING_LOAD: "API_REPORT_MANAGEMENT_LISTING_LOAD",
  API_REPORT_MANAGEMENT_LISTING_SUCCESS:
    "API_REPORT_MANAGEMENT_LISTING_SUCCESS",

  // REPORT management details
  API_REPORT_MANAGEMENT_DETAILS_LOAD: "API_REPORT_MANAGEMENT_DETAILS_LOAD",
  API_REPORT_MANAGEMENT_DETAILS_SUCCESS:
    "API_REPORT_MANAGEMENT_DETAILS_SUCCESS",

  // user report management block
  API_USER_REPORT_MANAGEMENT_BLOCK_LOAD:
    "API_USER_REPORT_MANAGEMENT_BLOCK_LOAD",
  API_USER_REPORT_MANAGEMENT_BLOCK_SUCCESS:
    "API_USER_REPORT_MANAGEMENT_BLOCK_SUCCESS",

  // poll report management block
  API_POLL_REPORT_MANAGEMENT_BLOCK_LOAD:
    "API_POLL_REPORT_MANAGEMENT_BLOCK_LOAD",
  API_POLL_REPORT_MANAGEMENT_BLOCK_SUCCESS:
    "API_POLL_REPORT_MANAGEMENT_BLOCK_SUCCESS",

  // poll option delete
  API_POLL_REPORT_MANAGEMENT_DELETE_LOAD:
    "API_POLL_REPORT_MANAGEMENT_DELETE_LOAD",
  API_POLL_REPORT_MANAGEMENT_DELETE_SUCCESS:
    "API_POLL_REPORT_MANAGEMENT_DELETE_SUCCESS",

  // report management FAILED CASE
  API_REPORT_MANAGEMENT_FAILED: "API_REPORT_MANAGEMENT_FAILED",
  API_REPORT_MANAGEMENT_ERROR: "API_REPORT_MANAGEMENT_ERROR",

  //Super admin management
  API_SUPER_ADMIN_LISTING_LOAD: "API_SUPER_ADMIN_LISTING_LOAD",
  API_SUPER_ADMIN_LISTING_SUCCESS: "API_SUPER_ADMIN_LISTING_SUCCESS",

  //View uper admin 
  API_SUPER_ADMIN_DETAIL_LOAD: "API_SUPER_ADMIN_DETAIL_LOAD",
  API_SUPER_ADMIN_DETAIL_SUCCESS: "API_SUPER_ADMIN_DETAIL_SUCCESS",

  //Delete super admin 
  API_SUPER_ADMIN_DELETE_LOAD: "API_SUPER_ADMIN_DELETE_LOAD",
  API_SUPER_ADMIN_DELETE_SUCCESS: "API_SUPER_ADMIN_DELETE_SUCCESS",

  //Add super admin 
  API_SUPER_ADMIN_ADD_LOAD: "API_SUPER_ADMIN_ADD_LOAD",
  API_SUPER_ADMIN_ADD_SUCCESS: "API_SUPER_ADMIN_ADD_SUCCESS",

  //Super admin management failed
  API_SUPER_ADMIN_FAILED: "API_SUPER_ADMIN_FAILED",
  API_SUPER_ADMIN_MANAGEMENT_ERROR: "API_SUPER_ADMIN_MANAGEMENT_ERROR",
};

export const appMessages = {
  offline: "Your internet connection appears to be offline. Please try again.",
  // 401 message
  messageStatus401: "The user is not authorized to make the request.",
  messageStatus500: "Something went wrong.",
  resetLinkExpired: "Your password reset link has expired",
  clickBelow: "Click below to generate a new one.",
  wrongPage: "Sorry, we couldn't' find this page",
  tryAgain: "Try again searching or go to",
  //*****functions*******//
  areYouSure: (msg, type) => `Are you sure, you want to ${msg} this ${type}?`,
  areYouSureForAccept: (msg, type) => `Are you sure, you want to ${msg} this ${type}?`,
};
