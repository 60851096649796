import { toast } from "react-toastify";
import { isInternetConnected } from "./InternetConnection";
import moment from "moment";

export const imageValidation = (value) => {
  let fileTypes = ["jpg", "jpeg", "png"];
  return fileTypes.indexOf(value) > -1;
};

export const handleImageChange = (event, setImage, setImageUrl) => {
  if (event.target.files && event.target.files[0]) {
    let uploadFile = event.target.files[0];
    let splitName = uploadFile.name.split(".").pop().toLowerCase();
    let isSuccess = imageValidation(splitName);
    if (isSuccess) {
      setImageUrl(event.target.files[0]);
      // setImageName(splitName[0]);
      let reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
      return;
    } else {
      toast.error("Only .jpg, .jpeg, .png and .svg format images are allowed.");
    }
  }
};

// pagination of table
export const handlePaginationChange = (
  e,
  search,
  limit,
  sortBy,
  order,
  setOffset,
  fetchDataListing
) => {
  const offsetTo = (e - 1) * 10;
  setOffset(offsetTo);
  fetchDataListing(search, offsetTo, limit, sortBy, order);
};

//pagination
export const handlePaginationChange1 = (
  e,
  search,
  limit,
  sortBy,
  order,
  setOffset,
  fetchDataListing
) => {
  const offsetTo = (e - 1) * 20;
  setOffset(offsetTo);
  fetchDataListing(search, offsetTo, limit, sortBy, order);
};

//searching of a table
export const handleSearching = async (
  e,
  limit,
  setSearch,
  fetchDataListing,
  tabValue
) => {
  var tempText = e.target.value;
  if (e.target.value.charAt(0) === " ") {
    tempText = e.target.value.replace(/^ +/gm, "");
  }
  setSearch(tempText);
  if (tabValue) {
    isInternetConnected() &&
      fetchDataListing(tempText, 0, limit, "", null, tabValue);
  } else {
    isInternetConnected() && fetchDataListing(tempText, 0, limit, "", null);
  }
};

//modify subcategory name
export const subCategoryNames = (item) => {
  if (item.name === "Whatever") {
    return "Whatever!";
  } else {
    return item.name;
  }
};

//employement name
export const getNames1 = (e) => {
  if (e === "Employed") {
    return "employed";
  }
  if (e === "Not Applicable") {
    return "notApplicable";
  }
};

//education names
export const getNames = (e) => {
  if (e === "Bacherlor") {
    return "bachelor";
  }
  if (e === "Doctrate") {
    return "doctrate";
  }
  if (e === "Master") {
    return "master";
  }
  if (e === "Not Eligible") {
    return "notEligible";
  }
  if (e === "Student") {
    return "student";
  }
};

//set end date
export const endDateTime = (time, require) => {
  return moment(time)?.format(require);
};

//education title
export const showEducationTitle = (educationReactionTitle) => {
  let arr = educationReactionTitle?.map((item, index) => {
    if (item === "notEligible") {
      return "Not Eligible";
    } else {
      return item?.charAt(0).toUpperCase() + item?.slice(1);
    }
  });
  return arr;
};

//employement title
export const showEmployementTitle = (employementReactionTitle) => {
  let arr = employementReactionTitle?.map((item, index) => {
    if (item === "notApplicable") {
      return "Not Applicable";
    }
    if (item === "employed") {
      return "Employed";
    }
  });
  return arr;
};

//show gender title
export const showGenderTitle = (genderReactionTitle) => {
  let arr = genderReactionTitle?.map((item, index) => {
    if (item === "other") {
      return "LGBTQ+";
    }
    if (item === "male") {
      return "Male";
    }
    if (item === "female") {
      return "Female";
    }
  });
  return arr;
};

export const tableInitialData = { search: "", offset: 0, sortBy: "", order: null, limit: 10 }

var nav
export const navigateFn = (navigation) => {
  nav = navigation
}
export const navTo = (route) => {
  nav(route)
}
export const capitalFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);

}

export const animationNameForCategory = (str) => {


  const pathArray = str?.split('/');
  const filename = pathArray[pathArray.length - 1];

  if (!filename) return pathArray;

  const originalName = filename?.split('_');

  if (!originalName) return filename;

  const animationName = originalName[originalName.length - 2];

  if (!animationName) return originalName;

  const split = (originalName[originalName.length - 1]).split('.')

  const extension = split[1];

  const result = `${animationName}.${extension}`;

  return result;

}

export const convertSecondsToHoursMinutesSeconds = (seconds) => {
  // Convert seconds to hours
  let hours = Math.floor(seconds / 3600);
  // Calculate remaining seconds after extracting hours
  let remainingSeconds = seconds % 3600;
  // Convert remaining seconds to minutes
  let minutes = Math.floor(remainingSeconds / 60);
  // Calculate remaining seconds after extracting minutes
  let remainingSecondsAfterMinutes = remainingSeconds % 60;
  // Remaining seconds after extracting minutes are the seconds
  let secondsResult = remainingSecondsAfterMinutes;

  return {
    hours: hours,
    minutes: minutes,
    seconds: Math.round(secondsResult.toFixed(2))
  };
}


