import React, { useState } from "react";
import { Select, Tooltip } from "antd";
//***This input field is use to show data in the view */

const InputField = ({ label, value, style, type, placeHolder, customStyle, handleOnClick, disable, inputType, customClassName, maxLength = 1000 }) => {
  const { Option } = Select;
  const [valueVal, setval] = useState(undefined);
  const selectOptions = [
    {
      value: '1',
      name: 'One'
    },
    {
      value: '2',
      name: 'Two'
    }
  ]
  const children = [];
  for (let i = 0; i < selectOptions.length; i++) {
    children.push(<Option key={i.toString(36) + i}>{i.name}</Option>);
  }

  return (
    <div className={`fields ${customClassName}`} >
      {
        inputType === 'input'
          ?
          <>
            <label>{label}</label>
            <input
              disabled={disable}
              id={disable ? 'disable' : 'active'}
              value={value}
              onChange={handleOnClick}
              placeHolder={placeHolder}
              style={customStyle}
              className={customClassName}
              maxLength={maxLength}
            />
          </>
          :
          inputType === 'dropdown'
            ?
            <>
              <label>{label}</label>
              <Select
                id={'id'}
                // showSearch={showSearch} // for search
                size="large"
                value={valueVal}
                mode={'multiple'}
                style={{ width: "100%" }}
                onChange={(val) => {
                  setval(val)
                }}
                // className={
                //   localStorage.lang === "ar"
                //     ? "language-dropdown ar"
                //     : "language-dropdown"
                // } // 'language-dropdown'
                // onClick={() => {
                //   console.log(localStorage.getItem("lang"),'lang');
                //   if (localStorage.getItem("lang") === "ar") {
                //     setTimeout(() => {
                //       const ele = document.querySelectorAll(
                //         ".rc-virtual-list-holder-inner"
                //       );
                //       console.log(ele,'ele');
                //       ele.forEach((element) => {
                //         element.classList.add("new_layout");
                //         element.classList.add("ar");
                //       });
                //     }, 10);
                //   } else {
                //     const ele = document.querySelectorAll(
                //       ".rc-virtual-list-holder-inner"
                //     );
                //     ele.forEach((element) => {
                //       element.classList.remove("new_layout");
                //       element.classList.remove("en");
                //     });
                //   }
                // }}
                // onChange={(value, option) => {
                //   selectOnChange(value, option, labelTitle);
                // }}
                // onKeyDown={() => {
                //   if (localStorage.getItem("lang") === "ar") {
                //     setTimeout(() => {
                //       const ele = document.querySelectorAll(
                //         ".rc-virtual-list-holder-inner"
                //       );
                //       ele.forEach((element) => {
                //         element.classList.add("new_layout");
                //         element.classList.add("ar");
                //       });
                //     }, 10);
                //   } else {
                //     const ele = document.querySelectorAll(
                //       ".rc-virtual-list-holder-inner"
                //     );
                //     ele.forEach((element) => {
                //       element.classList.remove("new_layout");
                //       element.classList.remove("ar");
                //     });
                //   }
                // }}
                placeholder={'Select category name'}
              // disabled={isDisabled}
              // onSearch={(value) => console.log(value, "value searched select")} // for search  optional
              // optionFilterProp="children" // for search
              >
                {
                  children
                }
                {/* {selectOptions.map((items, index) => {
              return (
                <Select.Option
                  key={index}
                  style={{
                    backgroundColor: "transparent",
                  }}
                  value={items.value}
                >
                  {items.name}
                </Select.Option>
              );
            })} */}
              </Select>
            </>
            :
            <>
              <label>{label}</label>
              <p style={style}

              >{value} </p>
            </>
      }
    </div>
  );
};

export default InputField;