import React, { useState, useEffect } from "react";
import { Button, Dropdown, Menu, Pagination, Tabs } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Table } from "antd";
import appconstant from "../../themes/appconstant";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { useSelector, useDispatch } from "react-redux";
import { playerListingAction } from "../../Redux/Action/questionManagementAction";
import { isInternetConnected } from "../../Utils/InternetConnection";
import { capitalFirstLetter, tableInitialData } from "../../Utils/helpers";
import LayoutHoc from "../../common/LayoutHoc";
import "./playerAnswer.css";


const PlayerAnswer = () => {
  const dispatch = useDispatch();
  const playerAnswerList = useSelector((state) => state.playerAnswerReducer);
  let { result, pagination, isLoading, fileLoading } = playerAnswerList;
  const [payloadData, setPayload] = useState({ ...tableInitialData })
  const [selectedRowKeys, setSelectedRowKeys] = useState([])


  const fetchDataListing = () => {
    dispatch(playerListingAction({ ...payloadData }))
  };


  useEffect(() => {
    isInternetConnected()
      &&
      fetchDataListing()
  }, [payloadData]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [result])


  const itemsT = [
    {
      key: '5',
      label: (
        "5"
      ),
    },
    {
      key: '10',
      label: (
        "10"
      ),
    },
    {
      key: '20',
      label: (
        "20"
      ),
    },
    {
      key: '50',
      label: (
        "50"
      ),
    },
    // {
    //   key: '100',
    //   label: (
    //     "100"
    //   ),
    // },
  ];



  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",
      sorter: false,
      render: (text, record, index) => <div>{
        payloadData.offset + (index + 1)
      }</div>
    },
    {
      title: "Category Image",
      dataIndex: "categoryImage",
      key: "categoryImage",
      sorter: false,
      render: (category, record) => (
        < div >
          {record?.questions?.questionCategory?.icon ? <img src={appconstant.imageUrl + record?.questions?.questionCategory?.icon} ></img> : 'N/A'}

        </div >
      ),
    },
    {
      title: "Category Name",
      dataIndex: "categoryName",
      key: "name",
      sorter: true,
      render: (categoryName, record) => (
        <div>
          {record?.questions?.questionCategory?.name || "N/A"}
        </div>
      ),
    },
    {
      title: "Questions",
      dataIndex: "qns",
      key: "question",
      sorter: true,
      render: (qns, record) => <div className="question-table-question-col">{record?.questions?.question ? record?.questions?.question : "N/A"}</div>,
    },
    {
      title: "Player",
      dataIndex: "player",
      key: "userName",
      sorter: true,
      render: (player, record) => <div className="question-table-question-col">{record?.userData?.userName ? capitalFirstLetter(record?.userData?.userName) : "N/A"}</div>,
    },
    {
      title: "Answer Type",
      dataIndex: "ansType",
      key: "ansType",
      sorter: false,
      render: (ansType, record) => <div className="question-table-question-col">{record?.type ? capitalFirstLetter(record?.type) : "N/A"}</div>,
    },
    {
      title: "Answer",
      dataIndex: "answer",
      key: "answer",
      sorter: false,
      render: (answer, record) => <div className="question-table-question-col">
        {record?.file ? (
          record?.type === 'audio' ? (
            <audio controls>
              <source src={appconstant.imageUrl + record.file} type="audio/mp3" />
              Your browser does not support the audio tag.
            </audio>) : (
            <video width="200" height="150" controls>
              <source src={appconstant.imageUrl + record.file} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )
        ) : (
          "N/A"
        )}</div>,
    },

  ];

  const onPaginationChange = (e) => {
    setPayload((state) => ({ ...state, offset: (e - 1) * payloadData.limit }))
  }

  const handleChange = async (pagination, filters, sorter) => {
    setPayload((state) => ({
      ...state,
      order: sorter.order === "ascend"
        ? 1
        : sorter.order === "descend"
          ? -1 : null,
      sortBy: sorter.order ? sorter.columnKey : ""
    }))
  };


  return (
    <div>
      <BreadcrumbFn
        path={["dashboard"]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          appconstant.playerAnswer,
        ]}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          background: "#fff",
          // padding: "10px 28px 0 25px",
          padding: "17px 28px 0px 25px",
          alignItems: "center",
        }}
      >
        <h6 class="text-white text-capitalize ps-3 pt-10" >
          {appconstant.playerAnswer}
        </h6>
        <input
          type="text"
          className="iocn-search  search-bar"
          placeholder="Search"
          value={payloadData.search}
          onChange={(e) => {
            setPayload((state) => ({ ...state, search: e.target.value.trimStart() }));
          }
          }
        />
      </div>
      <LayoutHoc
        customClass={"userManagement"}
        child2={
          <>
            <Table
              className="user-management-table for-player-answer"
              dataSource={result?.map(e => ({ ...e, key: e._id }))}
              loading={isLoading}
              columns={columns}
              showSorterTooltip={false}
              onChange={handleChange}
              pagination={false}


            />
            <div className="table-pagination table-pagination-with-per-page">
              <Pagination
                current={pagination?.currentPage}
                onChange={onPaginationChange}
                total={pagination?.totalCount}
                showSizeChanger={false}
                pageSize={payloadData.limit}
              ></Pagination>


              <Dropdown overlay={<Menu items={itemsT} selectable={true} selectedKeys={[payloadData.limit?.toString() ?? ""]} onSelect={(e) => {
                setPayload(prev => ({ ...prev, limit: +e.key }))
              }} />}>
                <div className="button-para">
                  <div className="text-wrapper">Show result</div><Button className="pagination-limit-btn">{payloadData.limit}</Button>
                </div>
              </Dropdown>


            </div>
          </>
        }
      >
      </LayoutHoc >
    </div >
  );
};

export default PlayerAnswer;
