import React, { useState, useMemo, useEffect } from "react";
import appconstant from "../../themes/appconstant";
import images from "../../themes/appImage";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { forgotPasswordAction, forgotPasswordStatusAction } from "../../Redux/Action/authentication";
import { isInternetConnected } from "../../Utils/InternetConnection";
import "./forgot.css";
import ModalPop from "../../common/modal";
import { apiConstants as types } from "../../themes/apiConstants";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter valid email address.")
    .required("Please enter email address."),
});

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const authModule = useSelector((state) => state.authenticationReducer);
  const showModal = useSelector((state) => state?.authenticationReducer?.forgetPasswordSuccess);

  // const [showModal, setShowModal] = useState(false);


  // useMemo(() => {
  //   console.log("authModule.success", authModule.success)
  //   authModule.success && setShowModal(true);
  // }, [authModule.success]);
  const initialValues = {
    email: "",
  };

  const onSubmit = (values) => {
    isInternetConnected() &&
      dispatch(forgotPasswordAction(values.email, navigate));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  useEffect(() => {

    return () => dispatch(forgotPasswordStatusAction())

  }, [])

  return (
    <div className="image-bg">
      <div className="page-header">
        <div className="main-div">
          <div className="login-form mt-top" style={{ minWidth: "380px" }}>
            <div className="back">
              <Link to="/login">
                <button type="submit" className=" btn2  ">
                  <FontAwesomeIcon
                    className="icon-angle-left"
                    icon={faAngleLeft}
                  />
                </button>
              </Link>
            </div>
            <div className="logo-container-wrapper mt-89">
              <div className="logo-container">
                {" "}
                <img
                  src={images.login}
                  style={{
                    marginTop: 0,
                  }}
                />
              </div>
            </div>

            <h4>{appconstant.forgotPassword} </h4>
            <form onSubmit={formik.handleSubmit} noValidate="noValidate">
              <div className="user">
                <input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  onKeyDown={handleKeyDown}
                ></input>
                {formik.errors.email ? (
                  <div className="error">{formik.errors.email}</div>
                ) : null}
              </div>
              <div className="login-link">
                <button type="submit">{appconstant.Send} </button>
              </div>
            </form>
          </div>
          <ModalPop
            center="forgot-success"
            isVisible={showModal}
            content={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <h1
                  style={{
                    marginbottom: "15px",
                    fontweight: 600,
                  }}
                >
                  Success
                </h1>
                <p
                  style={{
                    textAlign: "center",
                    marginBottom: "30px",
                    lineHeight: "30px",
                    fontSize: "20px",
                    marginTop: "13px",
                  }}
                >
                  A reset password link has been sent successfully to your
                  registered email address.
                </p>
                <button
                  onClick={() => {
                    navigate("/login");
                    // setShowModal(false);
                    dispatch(forgotPasswordStatusAction())
                    isInternetConnected() && dispatch({
                      type: types.API_FORGOT_PASSWORD_SUCCESS,
                      result: { success: false },
                    });
                  }}
                  style={{
                    fontWeight: "500",
                    borderRadius: "8px",
                    marginTop: "15px 0 0 0",
                  }}
                  className="button-list ok-button"
                >
                  {"OK"}
                </button>
              </div>

            }
          />
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
