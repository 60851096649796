import { Tooltip } from "antd";
import images from "../themes/appImage";
import { ValidationConstant } from "../themes/validationConstant";

const ImageUpload = (props) => {
    const{onChange,image}=props;

    return (
        <div className="datacenter data_news">
            <div className="datacenter-icon">
                <input
                    accept=".png,.jpg,.jpeg"
                    style={{ display: "none" }}
                    id="icon-button-file"
                    type="file"
                    onChange={onChange}
                    onClick={(event) => {
                        event.target.value = null
                    }}
                />

            </div>
            <label
                htmlFor="icon-button-file"
            >
                <Tooltip
                    title={image ? ValidationConstant.changeTooltip :
                        ValidationConstant.uploadTooltip}
                    placement="right"
                >
                    {image ? (
                        <img
                            src={image}
                            className="upload_image"
                        />
                    ) : (
                        <img
                            src={images.user10}
                            style={{ cursor: "pointer" }}
                            className="upload_image"
                            onChange={onChange}
                        />
                    )}
                    <img className="plus_icon_new" src={images.pluss}
                        onChange={onChange}
                    />
                </Tooltip>
            </label>
        </div>

    )
}

export default ImageUpload;