import { apiConstants as types } from "../../themes/apiConstants";

// category listing Action
export const categoryListingAction = (data, navigate) => ({
  type: types.API_CATEGORY_MANAGEMENT_LISTING_LOAD,
  data,
  navigate
});

// category listing Action
export const categoryListingActionForDropDown = (navigate) => ({
  type: types.API_CATEGORY_MANAGEMENT_LISTING_DROP_LOAD,
  navigate
});


// category details
export const categoryDetailsAction = (id, navigate) => ({
  type: types.API_CATEGORY_MANAGEMENT_DETAILS_LOAD,
  id,
  navigate,
});

// category add
export const categoryAddAction = (data, navigate) => ({

  type: types.API_CATEGORY_MANAGEMENT_ADD_LOAD,
  data,
  navigate,
});

// category update
export const categoryUpdateAction = (data, navigate) => ({
  type: types.API_CATEGORY_MANAGEMENT_UPDATE_LOAD,
  data,
  navigate
});

// category delete
export const categoryDeleteAction = (id, addToUnassigned, navigate) => ({
  type: types.API_CATEGORY_MANAGEMENT_DELETE_LOAD,
  id,
  addToUnassigned,
  navigate
});

// category enable disable
export const categoryEnableDisable = (data, navigate) => ({
  type: types.API_CATEGORY_MANAGEMENT_ENABLE_DISABLE_LOAD,
  data,
  navigate
});

// category enable disable
export const categoryMapListAction = (data, navigate) => ({
  type: types.API_CATEGORY_MANAGEMENT_MAP_LIST_LOAD,
  data,
  navigate
});
