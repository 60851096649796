import { apiConstants as types } from '../../themes/apiConstants'

// report user listing Action
export const reportListingAction = (data, navigate) => ({
    type: types.API_REPORT_MANAGEMENT_LISTING_LOAD,
    data,
    navigate
})

// report details Action
export const reportDetailsAction = (id, navigate) => ({
    type: types.API_REPORT_MANAGEMENT_DETAILS_LOAD,
    id,
    navigate,
});