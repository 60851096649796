import { apiConstants as types } from "../../themes/apiConstants";

// Login Action
export const loginAction = (email, password, navigate) => ({
  type: types.API_LOGIN_LOAD,
  email,
  password,
  navigate,
});

// forgot password Action
export const forgotPasswordAction = (email, navigate) => ({
  type: types.API_FORGOT_PASSWORD_LOAD,
  email,
  navigate,
});

// forgot password Action
export const forgotPasswordStatusAction = () => ({
  type: types.API_FORGOT_PASSWORD_SUCCESS_STATUS_CHANGE
});


export const resetPasswordAction = (
  newPassword,
  query,
  tokenType,
  navigate
) => ({
  type: types.API_RESET_PASSWORD_LOAD,
  newPassword,
  query,
  tokenType,
  navigate,
});

export const checkResetPasswordAction = (id) => ({
  type: types.API_CHECK_RESET_PASSWORD_LOAD,
  id,
});

export const changePasswordAction = (oldPassword, newPassword, navigate) => ({
  type: types.API_CHANGE_PASSWORD_LOAD,
  oldPassword,
  newPassword,
  navigate,
});

export const logoutAction = (navigate) => ({
  type: types.API_LOGOUT_LOAD,
  navigate,
});
