import { apiConstants as types } from '../../themes/apiConstants'
const initialState = {
    isLoading: false,
    result: [],
    pagination: {},
    deleteData: false,
    adminDetail: {},
}

export const superAdminManagementReducer = (state = initialState, action) => {

    switch (action.type) {

        //super admin listing
        case types.API_SUPER_ADMIN_LISTING_LOAD:
            return { ...state, isLoading: true }
        case types.API_SUPER_ADMIN_LISTING_SUCCESS:
            return { ...state, isLoading: false, result: action.result.data.list, pagination: action.result.data.pagination }

        //super admin detail
        case types.API_SUPER_ADMIN_DETAIL_LOAD:
            return { ...state, isLoading: true }
        case types.API_SUPER_ADMIN_DETAIL_SUCCESS:
            return { ...state, isLoading: false, adminDetail: action.result.data }

        //super admin delete 
        case types.API_SUPER_ADMIN_DELETE_LOAD:
            return { ...state, isLoading: true }
        case types.API_SUPER_ADMIN_DELETE_SUCCESS:
            return { ...state, isLoading: false }

        //super admin add
        case types.API_SUPER_ADMIN_ADD_LOAD:
            return { ...state, isLoading: true, }
        case types.API_SUPER_ADMIN_ADD_SUCCESS:
            return { ...state, isLoading: false, }

        //super admin Failed Case
        case types.API_SUPER_ADMIN_FAILED:
            return { ...state, isLoading: false }
        case types.API_SUPER_ADMIN_MANAGEMENT_ERROR:
            return { ...state, isLoading: false }


        //Default case
        default:
            return { ...state }
    }
}
