let year = new Date();

const appconstant = {
  // imageUrl: "http://192.168.3.176:4002/", //local
  // baseUrl: "http://192.168.3.176:4002/api/v1", //local
  imageUrl: "https://tqgapi.thatquestionsgame.com/",
  baseUrl: "https://tqgapi.thatquestionsgame.com/api/v1",
  // imageUrl: "http://1.6.98.141:2027/",  //dell
  // baseUrl: "http://1.6.98.141:2027/api/v1", //dell
  // imageUrl: "https://13.53.187.130:3000/",
  // baseUrl: "https://13.53.187.130:3000/api/v1",

  MANIFEST_MOTO: "That questions game",
  changepassword: "Change Password",
  Oldpassword: "Old Password",
  NewPassword: "New Password",
  ConfirmNewPassword: "Confirm New Password",
  contactus: "Contact us",
  home: "Dashboard",
  EmailAddress: "Email Address",
  eventManagement: "Event Management",
  UserManagement: "User Management",
  reportManagement1: "Report Management",
  Password: "Password",
  ConfrmPassword: "Confrm Password",
  email: "Email",
  contactNo: "Contact No",
  gender: "Gender",
  age: "Age",
  name: "Name",
  createdOn: "Created On",
  signUpDate: "Sign Up Date",
  playTime: "Play Time",

  Send: "Send",
  // categorymanagement: 'Category Management',

  add: "Add",
  edit: "Edit",
  delete: "Delete",
  disable: "Disable",
  enable: "Enable",
  export: "Export",
  import: "Import",
  addmanagement: "Add Category",
  categoryName: "Category Name",
  update: "Update",
  editmanagement: "Edit Category",
  funny: "Funny",
  brainy: "Brainy",
  whatever: "Whatever!",
  view: "View",
  userDetails: "User Details",
  appealDetails: "Appeal Details",
  firstName: "First Name",
  John: "John",
  LastName: "Last Name",
  Doe: "Doe",
  xyzyopmail: " xyz@yopmail.com",
  MobileNumber: "Phone Number",
  num: "1234567892",
  Gender: "Gender",
  male: "Male",
  Education: "Education",
  Postgraduate: "Post-graduate",
  Country: "Country",
  India: "India",
  City: "City",
  Mohali: "Mohali",
  Employed: "Employed",
  Unemployed: "Unemployed",
  Following: "Following",
  numb: "12",
  Followers: "Followers",
  folnum: "12",
  Bio: "Bio",
  edituserDetails: "Edit User Details",
  pollManagement: "Poll Management",
  block: "Block",
  pollDetails: "Poll Details",
  UserFullName: "Username",
  JohnDoe: "John Doe",
  Title: "Title",
  polltitle: "Poll Title",
  dfasfasdfasdfasd: "dfasfasdfasdfasd",
  Category: "Category",
  pollCategory: "Poll Category",
  avc: "avc",
  EndDate: "End Date",
  VotesByMales: "Votes By Males",
  votemale: "22",
  date: " 12-12-2000 ",
  VotesByFemales: "Votes By Females",
  Femalesvote: "22",
  VotesByLGBTQ: "Votes By LGBTQ+",
  VotesByla: "22",
  SelectCountry: "Select Country",
  votes: "22",
  Ageyears: "Age Range (20-40) years",
  five: "5",
  AgeRangeyears: "Age Range (40-60) years",
  AgeRange: "Age Range (60-80) years",
  Range: "Age Range (80-100)",
  SelectEducation: "Select Education",
  bsc: "BSC",
  SelectEmployment: "Select Employment",
  TotalNoOfVotes: "Total No. Of Votes",
  buttonupate: "Update",
  fiveone: "51",
  categorysub: "Sub Category",
  ReportManagement: "Report Management",
  TotalNoOfReports: "Total No. Of Reports",
  repor: "12",
  UserReportedDetail: "User Reported Detail",
  reportedpolls: "Reported Polls",
  PollsReportDetails: "Polls Report Details",
  fsfdfdsfadsf: "fsfdfdsfadsf",
  dfasfdf: "dfasfdf",
  OptionAddBy: "Option Add By",
  Xyz: "Xyz",
  rep: "12",
  PollsOptionDetails: "Polls Option Details",
  editpollDetails: "Edit Poll Details",
  PollReportManagement: "Poll Report Management",
  UserReportManagement: "User Report Management",
  PollsOptionReportManagement: "Polls Option Report Management",
  ReportedBy: "Reported By",
  Date: "Date ",
  dateofrep: "13-04-2000 ",
  emailid: "axyz@gmail.com",
  login: "Login",
  PollOptions: "Poll Options",
  Images: "Images",
  videos: "Videos",
  Audios: "Audios",
  Text: "Text",
  Reason: "Reason",
  abc: "Abc",
  CreatedBy: "Created By",
  UsersDetails: "Users Details",
  PollsDetails: "Polls Details",
  pollsOption: "Poll Options",
  lastWeek: "Total number of user registered in a week",
  lastMonth: "Total number of user registered in a month",
  lastQuarter: "Total number of user registered quarterly",
  sixMonths: "Total number of user registered in 6 months",
  lastYear: "Total number of user registered in a year",
  lastWeekPoll: "Total number of polls created in a week",
  lastMonthPoll: "Total number of polls created in a month",
  lastQuarterPoll: "Total number of polls created quarterly",
  sixMonthsPoll: "Total number of polls created in  6 months",
  lastYearPoll: "Total number of polls created in a year",
  usersApplication: " Total users registered on the application",
  registeredToday: "Total number of user registered today",
  registeredTotal: "Total number of user registered count",
  totalPolls: "Total Polls created on the application",
  pollCreated: "Total number of poll created today",
  pollCount: "Total number of poll created count",
  forgotPassword: "Forgot Password",
  adminLogin: "Admin Login ",
  referralAmount: "Referral Amount ",
  forgotPasswordQuestion: "Forgot Password?",
  Female: "Female",
  LGBTQ: "LGBTQ+",
  passwordChanged: "Your reset password link has been expired.",
  passwordReset: "Your password has been reset successfully.",
  copyRight: `Copyright ${year.getFullYear()} © That Questions Game. All rights reserved.`,
  reset: "Reset",
  noOptionFound: "No Options Found.",
  votesAccAge: "Votes According To Age",
  votesAccCountry: "Votes According To Country",
  novotesAccCountry: "No Votes According To Country",
  votesAccEducation: "Votes According To Education",
  noVotesAccEducation: "No votes according to education.",
  votesAccGender: "Votes According To Gender",
  noVotesAccGender: "No votes according to gender.",
  votesAccEmployement: "Votes According To Employment.",
  noVotesAccEmployement: "No votes according to employment.",
  users: "User",
  polls: "Poll",
  votesGraph: "Votes Graph",
  selectCity: "Select City",
  Votes: "Votes",
  selectedOption: "Selected Option",
  all: "All",
  categoryRank: "Category Rank",

  // new
  linkExp: "Link Expired",
  gamesPlayed: "Games Played",
  earning: "Earning",
  pendingReq: "Pending Requests",
  UsersManagement: "User Management",
  reportedUsersManagement: "Reported Users Management",
  inAppPurchaseManagement: "In-App Purchase Management",
  coins: "Coins",
  inapp: "In-App Purchase Management",
  amount: "Amount",
  dateTime: "Date & Time",
  categoryMngmnt: "Category Management",
  addCategory: "Add Category",
  editCategory: "Edit Category",
  suggestedCategoryMngmnt: "Suggested Category Management",
  questionsMngmnt: "Questions Management",
  addDailyQuestion: "Add Daily Questions",
  dailyQuestion: "Daily Questions",
  superAdmin: "Sub Admin",
  superAdminDetail: "Sub Admin Details",
  addSuperAdmin: "Add Sub Admin",
  suggestedQuestionsMngmnt: "Suggested Questions Management",
  suggestedCategoryManagement: "Suggested Category Management",
  accept: "Accept",
  reject: "Reject",
  archive: "Archive",
  qnsMngmnt: "Questions Management",
  playerAnswer: "Player Answer Management",
  userAnswer: "User Answers",
  addQn: "Add Question",
  editQn: "Edit Question",
  qns: "Question",
  qn: "Question",
  desc: "Description",
  upload: "Upload",
  submit: "Submit",
  purchaseHist: "Purchase History",

  MapManagement: "Map Management",
  ViewMap: "View Map",
  LevelIcon: "Level Icon",
  LevelName: "Level Name",
  AddMap: "Add Map",
  EditMap: "Edit Map",
  StarManagement: "Star Management",
  NoofStars: "No. of Stars",
  PurchasePrice: "Purchase Price",
  ViewStars: "View Stars",
  EditStars: "Edit Stars",
  AddStars: "Add Stars",
  MapandQuestionPackManagement: "Map and Question Pack Management",
  EditPack: "Edit Pack",
  AddPack: "Add Pack",
  ViewPack: "View Pack",
  PackType: "Pack Type",
  PackName: "Pack Name",

  categoryDisableConfirmation:
    "Are you sure you want to disable this category.",
  categoryEnableConfirmation: "Are you sure you want to enable this category.",
  categoryDeleteConfirmation: "Are you sure you want to delete this category.",
  questionDeleteConfirmation: "Are you sure you want to delete this question.",
  adminDeleteConfirmation: "Are you sure you want to delete this sub admin.",
  questionMultipleDeleteConfirmation:
    "Are you sure you want to delete selected questions.",
  questionArchiveConfirmation:
    "Are you sure you want to archive this question?",
  categoryArchiveConfirmation:
    "Are you sure you want to archive this category?",
  download: "Download.",
};

export default appconstant;
