import { takeLatest, call, put } from "redux-saga/effects";
import { apiConstants as types, appMessages } from "../../themes/apiConstants";
import axios from "../api";
import { toast } from "react-toastify";

function* authenicationUser(result, navigate) {
  console.log('navigate', navigate)
  toast.error(result?.error);
  localStorage.removeItem("token");
  localStorage.clear();
  navigate.navigate("/");
}

function* questionFailedSaga(result) {

  yield put({
    type: types.API_QUESTION_MANAGEMENT_FAILED,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

function* questionErrorSaga(result) {


  yield put({
    type: types.API_QUESTION_MANAGEMENT_ERROR,
  });
  if (result.status === 2) {
    toast.error(appMessages.messageStatus500);
  } else {
    toast.error(result?.error, { toastId: result?.status || "est" });
  }
}

function* questionListingSaga(action) {
  try {
    const result = yield call(
      axios.questionListing,
      action.data
    );
    if (result.status === 1) {
      yield put({
        type: types.API_QUESTION_MANAGEMENT_LISTING_SUCCESS,
        result: result.result.data,
      });
    } else if (result.status === 4) {
      yield call(authenicationUser, result, action);
    } else {
      yield call(questionFailedSaga, result);
    }
  } catch (error) {
    yield call(questionErrorSaga, error);
  }
}

function* playerAnswerListSaga(action) {

  try {
    const result = yield call(
      axios.playerAnswerList,
      action.data
    );
    if (result.status === 1) {
      yield put({
        type: types.API_PLAYER_ANSWER_LISTING_SUCCESS,
        result: result.result.data,
      });
    } else if (result.status === 4) {
      yield call(authenicationUser, result, action);
    } else {
      toast.error(result?.error, { toastId: result?.status || "est" });
      yield put({
        type: types.API_PLAYER_ANSWER_LISTING_FAILED,
      });
    }
  } catch (error) {
    yield call(questionErrorSaga, error);
  }
}

function* questionExportSaga(action) {
  try {
    const result = yield call(
      axios.questionListing,
      action.payload
    );
    if (result.status === 1) {
      yield put({
        type: types.API_QUESTION_MANAGEMENT_EXPORT_SUCCESS,
        result: result.result.data,
      });
    } else if (result.status === 4) {
      yield call(authenicationUser, result, action);
    } else {
      yield call(questionFailedSaga, result);
    }
  } catch (error) {
    yield call(questionErrorSaga, error);
  }
}

function* questionUpdateSaga(action) {
  try {
    const result = yield call(axios.questionUpdate, action.data);
    if (result.status === 1) {
      const boolCheck = localStorage.getItem("isUnassigned")
      const resultStatus = yield call(
        axios.questionListing,
        { ...action.data, offset: 0, limit: 10, isUnassigned: boolCheck == 'true' ? true : false, offset: 0, limit: 10 }
      );
      if (resultStatus.status === 1) {
        yield put({
          type: types.API_QUESTION_MANAGEMENT_LISTING_SUCCESS,
          result: resultStatus.result.data,
        });
        action.navigate(action.path)
      }

      yield put({
        type: types.API_QUESTION_MANAGEMENT_UPDATE_SUCCESS,
        result: result.result.data,
      });
      toast.success(result.result.data.message);
    } else if (result.status === 4) {
      yield call(authenicationUser, result, action);
    } else {
      yield call(questionFailedSaga, result);
    }
  } catch (error) {
    yield call(questionErrorSaga, error);
  }
}

function* questionAddSaga(action) {
  try {
    const result = yield call(axios.questionAdd, action.data);
    if (result.status === 1) {

      const boolCheck = localStorage.getItem("isUnassigned")
      const resultStatus = yield call(
        axios.questionListing,
        { ...action.data, offset: 0, limit: 10, isUnassigned: boolCheck == 'true' ? true : false, offset: 0, limit: 10 }
      );
      if (resultStatus.status === 1) {
        yield put({
          type: types.API_QUESTION_MANAGEMENT_LISTING_SUCCESS,
          result: resultStatus.result.data,
        });

        action.navigate(action.path)
      }
      else if (resultStatus.status === 4) {
        yield call(authenicationUser, result, action);
      }

      yield put({
        type: types.API_QUESTION_MANAGEMENT_ADD_SUCCESS,
        result: result.result.data,
      });
      toast.success(result.result.data.message);
    } else if (result.status === 4) {


      yield call(authenicationUser, result, action);
    } else {

      yield call(questionFailedSaga, result);
    }
  } catch (error) {
    yield call(questionErrorSaga, error);
  }
}

function* questionDetailsSaga(action) {
  const { id } = action;
  try {
    const result = yield call(axios.questionDetails, id);
    if (result.status === 1) {
      yield put({
        type: types.API_QUESTION_MANAGEMENT_DETAILS_SUCCESS,
        result: result.result.data,
      });
    } else if (result.status === 4) {
      yield call(authenicationUser, result, action);
    } else {
      yield call(questionFailedSaga, result);
    }
  } catch (error) {
    yield call(questionErrorSaga, error);
  }
}

function* questionDeleteSaga(action) {
  try {
    const result = yield call(axios.questionDelete, { ids: action.ids, type: action?.questionType, addToUnassigned: action.addToUnassigned, limit: action.limit });
    if (result.status === 1) {
      const boolCheck = localStorage.getItem("isUnassigned")
      const resultStatus = yield call(
        axios.questionListing,
        { ...action.data, type: action.questionType, isUnassigned: boolCheck == 'true' ? true : false, offset: 0, limit: action.limit }
      );

      if (resultStatus.status === 1) {
        yield put({
          type: types.API_QUESTION_MANAGEMENT_LISTING_SUCCESS,
          result: resultStatus.result.data,
        });
      }

      yield put({
        type: types.API_QUESTION_MANAGEMENT_DELETE_SUCCESS,
        result: result.result.data,
      });
      toast.success(result.result.data.message);
    } else if (result.status === 4) {
      yield call(authenicationUser, result, action);
    } else {
      yield call(questionFailedSaga, result);
    }
  } catch (error) {
    yield call(questionErrorSaga, error);
  }
}

function* questionImportSaga(action) {
  try {
    const result = yield call(axios.questionImport, action.payload);
    if (result.status === 1) {
      const resultStatus = yield call(
        axios.questionListing,
        { ...action.data, type: action.questionType, isUnassigned: action.isUnassigned, offset: 0, limit: 10 }
      );
      if (resultStatus.status === 1) {
        yield put({
          type: types.API_QUESTION_MANAGEMENT_LISTING_SUCCESS,
          result: resultStatus.result.data,
        });
      }
      toast.success(result.result.data.message);

      yield put({
        type: types.API_QUESTION_MANAGEMENT_IMPORT_SUCCESS,
        result: result.result.data,
      });

    } else if (result.status === 4) {
      yield call(authenicationUser, result, action);
    } else {
      yield call(questionFailedSaga, result);
    }
  } catch (error) {
    yield call(questionErrorSaga, error);
  }
}

export default function* rootQuestionManagementSaga() {
  yield takeLatest(
    types.API_QUESTION_MANAGEMENT_LISTING_LOAD,
    questionListingSaga
  );
  yield takeLatest(
    types.API_QUESTION_MANAGEMENT_UPDATE_LOAD,
    questionUpdateSaga
  );
  yield takeLatest(
    types.API_QUESTION_MANAGEMENT_DETAILS_LOAD,
    questionDetailsSaga
  );
  yield takeLatest(types.API_QUESTION_MANAGEMENT_ADD_LOAD, questionAddSaga);
  yield takeLatest(
    types.API_QUESTION_MANAGEMENT_DELETE_LOAD,
    questionDeleteSaga
  );

  yield takeLatest(
    types.API_QUESTION_MANAGEMENT_IMPORT_LOAD,
    questionImportSaga
  );

  yield takeLatest(
    types.API_QUESTION_MANAGEMENT_EXPORT_LOAD,
    questionExportSaga
  );

  yield takeLatest(
    types.API_PLAYER_ANSWER_LISTING_LOAD,
    playerAnswerListSaga
  )
}
