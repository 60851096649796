import { takeLatest, call, put } from "redux-saga/effects";
import { apiConstants as types, appMessages } from "../../themes/apiConstants";
import axios from "../api";
import { toast } from "react-toastify";

function* authenicationUser(result, navigate) {
  console.log('navigate', navigate)
  toast.error(result?.error);
  localStorage.removeItem("token");
  localStorage.clear();
  navigate.navigate("/");
}

function* adminFailedSaga(result) {

  yield put({
    type: types.API_SUPER_ADMIN_FAILED,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

function* adminErrorSaga(result) {
  yield put({
    type: types.API_SUPER_ADMIN_MANAGEMENT_ERROR,
  });
  if (result.status === 2) {
    toast.error(appMessages.messageStatus500);
  } else {
    toast.error(result?.error, { toastId: result?.status || "est" });
  }
}

function* adminListingSaga(action) {
  try {
    const result = yield call(
      axios.adminListingApi,
      action.data
    );
    if (result.status === 1) {
      yield put({
        type: types.API_SUPER_ADMIN_LISTING_SUCCESS,
        result: result.result.data,
      });
    } else if (result.status === 4) {
      yield call(authenicationUser, result, action);
    } else {
      yield call(adminFailedSaga, result);
    }
  } catch (error) {
    yield call(adminErrorSaga, error);
  }
}

function* adminDetailsSaga(action) {
  try {
    const result = yield call(axios.adminDetailApi, action?.data);
    if (result.status === 1) {
      yield put({
        type: types.API_SUPER_ADMIN_DETAIL_SUCCESS,
        result: result.result.data,
      });

    } else if (result.status === 4) {
      yield call(authenicationUser, result, action);
    } else {
      yield call(adminFailedSaga, result);
    }
  } catch (error) {
    yield call(adminErrorSaga, error);
  }
}

function* adminDeleteSaga(action) {
  try {
    const result = yield call(axios.adminDeleteApi, action?.data);
    if (result.status === 1) {
      const resultStatus = yield call(
        axios.adminListingApi,
        { offset: 0, limit: 10, search: action?.data?.search }
      );
      if (resultStatus.status === 1) {
        yield put({
          type: types.API_SUPER_ADMIN_LISTING_SUCCESS,
          result: resultStatus.result.data,
        });
      }
      yield put({
        type: types.API_SUPER_ADMIN_DELETE_SUCCESS,
        result: result.result.data,
      });
      toast.success(result.result.data.message);

    } else if (result.status === 4) {
      yield call(authenicationUser, result, action);
    } else {
      yield call(adminFailedSaga, result);
    }
  } catch (error) {
    yield call(adminErrorSaga, error);
  }
}

function* adminAddSaga(action) {
  try {
    const result = yield call(axios.adminAddApi, action?.data);
    if (result.status === 1) {
      yield put({
        type: types.API_SUPER_ADMIN_ADD_SUCCESS,
        result: result.result.data,
      });
      toast.success(result.result.data.message);
      action.navigate(action.path)
    } else if (result.status === 4) {
      yield call(authenicationUser, result, action);
    } else {
      yield call(adminFailedSaga, result);
    }
  } catch (error) {
    yield call(adminErrorSaga, error);
  }
}


export default function* rootQuestionManagementSaga() {
  yield takeLatest(
    types.API_SUPER_ADMIN_LISTING_LOAD,
    adminListingSaga
  );
  yield takeLatest(
    types.API_SUPER_ADMIN_DETAIL_LOAD,
    adminDetailsSaga
  );
  yield takeLatest(
    types.API_SUPER_ADMIN_DELETE_LOAD,
    adminDeleteSaga
  );
  yield takeLatest(
    types.API_SUPER_ADMIN_ADD_LOAD,
    adminAddSaga
  );

}
