import React, { useEffect } from "react";
import images from "../../themes/appImage";
import appconstant from "../../themes/appconstant";
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ValidationConstant } from "../../themes/validationConstant";
import { useSelector, useDispatch } from "react-redux";
import { loginAction } from "../../Redux/Action/authentication";
import { isInternetConnected } from "../../Utils/InternetConnection";
import "./login.css";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(\.[a-zA-z]{2,10})+$/,
      ValidationConstant.validEmail
    )
    .email(ValidationConstant.validEmail)
    .max(255)
    .required(ValidationConstant.emptyEmail),
  password: Yup.string()
    .required(ValidationConstant.emptyPass)
    .matches(/^(\S+$)/, ValidationConstant.validPass),
});

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginData = useSelector((state) => state.authenticationReducer);
  const { isLoading } = loginData;

  const initialValues = {
    email: "",
    password: "",
  };

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    isInternetConnected() &&
      dispatch(loginAction(values.email, values.password, navigate));
  };

  useEffect(() => {
    document.title = "That questions game";
    window.history?.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history?.pushState(null, document.title, window.location.href);
    });
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  return (
    <div className="image-bg">
      <div className="page-header">
        <div className="main-div">
          <div className="login-form login">
            <div className="logo-container-wrapper">
              <div className="logo-container">
                {" "}
                <img src={images.login} />
              </div>
            </div>
            <h3>{appconstant.adminLogin}</h3>
            <form noValidate onSubmit={formik.handleSubmit}>
              <div className="user">
                <input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  onChange={formik.handleChange}
                  onKeyDown={handleKeyDown}
                  value={formik.values.email}
                />
                {formik.errors.email ? (
                  <div className="error">{formik.errors.email}</div>
                ) : null}

                <input
                  id={"password"}
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={formik.handleChange}
                  onKeyDown={handleKeyDown}
                  value={formik.values.password}
                ></input>
                {formik.errors.password ? (
                  <div className="error">{formik.errors.password}</div>
                ) : null}
              </div>
              <div className="login-link button_login">
                <div className="login-link">
                  <button
                    disabled={isLoading}
                    className="login-btn"
                    type="submit"
                  >
                    {appconstant.login}
                  </button>
                </div>
              </div>
              <div className="forgot-link">
                <Link to="/forgotpassword">
                  {appconstant.forgotPasswordQuestion}
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
