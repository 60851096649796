const images = {
  login: require("../images/applogo.png"),
  userDefault: require("../images/user_def.png"),
  plusCircle: require("../images/plusCircle.png"),
  uploadImg: require("../images/uploadImg.png"),
  musicPng: require("../images/musical-note.png"),

  gamePlayed: require("../images/total.svg").default,
  gameToday: require("../images/today.svg").default,
  gameWeek: require("../images/thisweek.svg").default,
  gameMonth: require("../images/thismonth.svg").default,
  gameQrter: require("../images/thisqua.svg").default,
  gameYear: require("../images/thisyear.svg").default,

  userTotal: require("../images/user-grd-total.png"),
  userToday: require("../images/user-grd-today.png"),
  userWeek: require("../images/user-grd-week.png"),
  userMonth: require("../images/user-grd-month.png"),
  userQrter: require("../images/user-grd-qrter.png"),
  userYear: require("../images/user-grd-year.png"),

  earningTotal: require("../images/stotal.svg").default,
  earningToday: require("../images/stoday.svg").default,
  earningWeek: require("../images/sweek.svg").default,
  earningMonth: require("../images/smonth.svg").default,
  earningQrter: require("../images/squa.svg").default,
  earningYear: require("../images/syear.svg").default,

  idCard: require("../images/idcard.png"),
  clean1: require("../images/clean1.png"),
  clean2: require("../images/clean2.png"),
  dirt1: require("../images/dirt1.png"),
  dirt2: require("../images/dirt2.png"),
  blankImage: require("../images/blankImage.png"),

  dashboard: require("../images/dashboard.svg").default,
  dashboardWhite: require("../images/dashboard-white.svg").default,

  userMngmnt: require("../images/user management.svg").default,
  userMngmntWhite: require("../images/user management-white.svg").default,

  mapMngmntWhite: require("../images/Map-white.svg").default,
  mapMngmnt: require("../images/Map.svg").default,

  starMngmntWhite: require("../images/Star-white.svg").default,
  starMngmnt: require("../images/Star.svg").default,

  questionMngmntWhite: require("../images/Qa-white.svg").default,
  questionMngmnt: require("../images/Qa-green.svg").default,

  reportUserMngmnt: require("../images/report-svgrepo-com (1) 1.svg").default,
  reportUserMngmntWhite: require("../images/report-svgrepo-com (1) 1-white.svg")
    .default,

  inAppUserMngmnt: require("../images/In app purchase.svg").default,
  inAppUserMngmntWhite: require("../images/In app purchase-white.svg").default,

  categoryMngmnt: require("../images/category management.svg").default,
  categoryMngmntWhite: require("../images/category management-white.svg")
    .default,

  suggestedCategoryMngmnt:
    require("../images/suggested category management.svg").default,
  suggestedCategoryMngmntWhite:
    require("../images/suggested category management-white.svg").default,

  qnsMngmnt: require("../images/Question management.svg").default,
  qnsMngmntWhite: require("../images/Question management-white.svg").default,

  playerAnswer: require("../images/playerAnswrs.svg").default,
  playerAnswerWhite: require("../images/playerAnswrsWhite.svg").default,

  suggestedQnsMngmnt: require("../images/Suggested Question management.svg")
    .default,
  suggestedQnsMngmntWhite:
    require("../images/Suggested Question management-white.svg").default,

  changePass: require("../images/change Password.svg").default,
  changePassWhite: require("../images/change Password-white.svg").default,

  logout: require("../images/Logout.svg").default,
  logoutWhite: require("../images/Logout-white.svg").default,

  hidden: require("../images/hidden.svg").default,
  eye: require("../images/eye.svg").default,

};

export default images;
