import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import appconstant from "../../themes/appconstant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import Textfiled1 from "../../common/Textfiled";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { changePasswordAction } from "../../Redux/Action/authentication";
import { isInternetConnected } from "../../Utils/InternetConnection";
import LayoutHoc from "../../common/LayoutHoc";

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Please enter old password."),
  newPassword: Yup.string()
    .notOneOf(
      [Yup.ref("oldPassword"), null],
      "New password should not be same as old password, please try new one."
    )
    .min(8, "New password should be at least 8 characters long.")
    .matches(/^(\S+$)/, "Space is not allowed.")
    .required("Please enter new password."),
  confirmPassword: Yup.string()
    .matches(/^(\S+$)/, "Space is not allowed.")
    .required("Please enter confirm new password.")
    .oneOf(
      [Yup.ref("newPassword"), null],
      "New password and confirm new password should be same."
    ),
});

const Changepassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  localStorage.setItem("isUnassigned", false);


  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const onSubmit = (values) => {
    isInternetConnected() &&
      dispatch(
        changePasswordAction(values.oldPassword, values.newPassword, navigate)
      );
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div>
      <BreadcrumbFn
        path={["dashboard"]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          appconstant.changepassword,
        ]}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          background: "#fff",
          padding: "17px 28px 0px 25px",
          alignItems: "center",
        }}
      >
        <h6 class="text-white text-capitalize ps-3">
          {appconstant.changepassword}
        </h6>
      </div>
      <LayoutHoc
        customClass={"displayNone"}
        child2={
          <form
            onSubmit={formik.handleSubmit}
            noValidate="noValidate"
            style={{ margin: "25px 30px" }}
          >
            <div className="wrapper_line">
              <Textfiled1
                label={appconstant.Oldpassword}
                id={"oldPassword"}
                type="password"
                name="oldPassword"
                onChange={formik.handleChange}
                value={formik.values.oldPassword}
                onKeyDown={handleKeyDown}
                placeholder="Old Password"
              />
              {formik.errors.oldPassword ? (
                <div className="error1">{formik.errors.oldPassword}</div>
              ) : null}
              <Textfiled1
                label={appconstant.NewPassword}
                id={"newPassword"}
                type="password"
                name="newPassword"
                onChange={formik.handleChange}
                value={formik.values.newPassword}
                onKeyDown={handleKeyDown}
                placeholder="New Password"
              />
              {formik.errors.newPassword ? (
                <div className="error1">{formik.errors.newPassword}</div>
              ) : null}
              <Textfiled1
                label={appconstant.ConfirmNewPassword}
                id={"confirmPassword"}
                type="password"
                name="confirmPassword"
                onChange={formik.handleChange}
                value={formik.values.confirmPassword}
                onKeyDown={handleKeyDown}
                placeholder="Confirm New Password"
              />
              {formik.errors.confirmPassword ? (
                <div className="error1">{formik.errors.confirmPassword}</div>
              ) : null}
              <div
                className="up-cent"
                style={{
                  margin: "40px 0 0",
                }}
              >
                <button
                  type="submit"
                  className="button-list"
                >
                  {appconstant.buttonupate}
                </button>
              </div>
            </div>
          </form>
        }
      />
    </div>
  );
};

export default Changepassword;
