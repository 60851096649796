import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import appconstant from "../../themes/appconstant";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { useSelector, useDispatch } from "react-redux";
import LayoutHoc from "../../common/LayoutHoc";
import "./superAdmin.css";
import { isInternetConnected } from "../../Utils/InternetConnection";
import * as Yup from "yup";
import { AutoComplete } from "antd";
import { useFormik } from "formik";
import { ValidationConstant } from "../../themes/validationConstant";
import { addSuperAdminAction } from "../../Redux/Action/superAdminAction";
import images from "../../themes/appImage"



const AddSubAdmin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const removeEmojis = (string) => {

    let regex = /(?:[\u00A9\u00AE\u203C\u2049\u2122\u2139\u2194-\u2199\u21A9-\u21AA\u231A-\u231B\u2328\u23CF\u23E9-\u23F3\u23F8-\u23FA\u24C2\u25AA-\u25AB\u25B6\u25C0\u25FB-\u25FE\u2600-\u2604\u260E\u2611\u2614-\u2615\u2618\u261D\u2620\u2622-\u2623\u2626\u262A\u262E-\u262F\u2638-\u263A\u2640\u2642\u2648-\u2653\u2660\u2663\u2665-\u2666\u2668\u267B\u267F\u2692-\u2697\u2699\u269B-\u269C\u26A0-\u26A1\u26AA-\u26AB\u26B0-\u26B1\u26BD-\u26BE\u26C4-\u26C5\u26C8\u26CE-\u26CF\u26D1\u26D3-\u26D4\u26E9-\u26EA\u26F0-\u26F5\u26F7-\u26FA\u26FD\u2702\u2705\u2708-\u270D\u270F\u2712\u2714\u2716\u271D\u2721\u2728\u2733-\u2734\u2744\u2747\u274C\u274E\u2753-\u2755\u2757\u2763-\u2764\u2795-\u2797\u27A1\u27B0\u27BF\u2934-\u2935\u2B05-\u2B07\u2B1B-\u2B1C\u2B50\u2B55\u3030\u303D\u3297\u3299]|(?:\uD83C[\uDC04\uDCCF\uDD70-\uDD71\uDD7E-\uDD7F\uDD8E\uDD91-\uDD9A\uDDE6-\uDDFF\uDE01-\uDE02\uDE1A\uDE2F\uDE32-\uDE3A\uDE50-\uDE51\uDF00-\uDF21\uDF24-\uDF93\uDF96-\uDF97\uDF99-\uDF9B\uDF9E-\uDFF0\uDFF3-\uDFF5\uDFF7-\uDFFF]|\uD83D[\uDC00-\uDCFD\uDCFF-\uDD3D\uDD49-\uDD4E\uDD50-\uDD67\uDD6F-\uDD70\uDD73-\uDD7A\uDD87\uDD8A-\uDD8D\uDD90\uDD95-\uDD96\uDDA4-\uDDA5\uDDA8\uDDB1-\uDDB2\uDDBC\uDDC2-\uDDC4\uDDD1-\uDDD3\uDDDC-\uDDDE\uDDE1\uDDE3\uDDE8\uDDEF\uDDF3\uDDFA-\uDE4F\uDE80-\uDEC5\uDECB-\uDED2\uDEE0-\uDEE5\uDEE9\uDEEB-\uDEEC\uDEF0\uDEF3-\uDEF6]|\uD83E[\uDD10-\uDD1E\uDD20-\uDD27\uDD30\uDD33-\uDD3A\uDD3C-\uDD3E\uDD40-\uDD45\uDD47-\uDD4B\uDD50-\uDD5E\uDD80-\uDD91\uDDC0]))/g;
    let result = string?.replace(regex, '')?.replace(/ /g, "");

    return result.trimLeft()
  }

  const [eye, setEye] = useState(false)

  const validationSchema = Yup.object().shape({
    email: Yup.string().test("trim", ValidationConstant.emailRequired, (value) => value?.trim()?.length > 0)
      .matches(/^(([a-zA-Z0-9]+)||([a-zA-Z0-9_.-]+[a-zA-Z0-9]+))@([0-9a-zA-Z]+)([^0-9])([0-9a-zA-Z]+)||([a-zA-Z0-9])(\.[a-zA-z]{2,5})$/, ValidationConstant.invalidEmail)
      .email(ValidationConstant.invalidEmail)
      .required(ValidationConstant.emailRequired)
      .test("trim", ValidationConstant.emailRequired,
        (value) => {

          value = value?.replace(/ /g, "")
          if (value) value = removeEmojis(value)
          return value?.trim()?.length > 0
        }),
    name: Yup.string()
      .required(ValidationConstant.nameRequired)
      .min(2, ValidationConstant.minNameValidation)
      .max(15, ValidationConstant.maxNameValidation)
      .test("trim", ValidationConstant.nameRequired,
        (value) => {

          value = value?.replace(/ /g, "")
          return value?.trim()?.length > 0
        }),
    password: Yup.string().required(ValidationConstant.passwordRequired)
      .min(8, ValidationConstant.minPass)
      .test("trim", ValidationConstant.passwordRequired,
        (value) => {

          value = value?.replace(/ /g, "")
          if (value) value = removeEmojis(value)
          return value?.replace(/ /g, "").trim()?.length > 0
        }).matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>/?]).+$/, ValidationConstant.passwordValidation),
  });


  const initialValues = {
    name: "",
    email: "",
    password: ""
  };


  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      isInternetConnected() && dispatch(addSuperAdminAction(values, navigate, "/sub-admin"));
    }
  });


  return (
    <div style={{ width: "100%" }}>
      <BreadcrumbFn
        path={["dashboard", "sub-admin"]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          appconstant.superAdmin,
          appconstant.addSuperAdmin,
        ]}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          background: "#fff",
          padding: "17px 28px 0px 25px",
          // padding: "10px 28px 0 25px",
          alignItems: "center",
        }}
      >
        <h6 class="text-white text-capitalize ps-3 ">
          {appconstant.addSuperAdmin}
        </h6>
      </div>
      <LayoutHoc customClass={"userManagement"}>
        <div
          className="wrapper_line view_modules view"
          style={{
            width: "100%",
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <div>

              <div className="form-group margin-0">
                <label>Name</label>

                <input
                  className="add-question-auto-complete"
                  name="name"
                  maxLength={20}
                  placeholder="Name"
                  value={formik.values?.name}
                  onChange={(e) => {
                    e.target.value.trim()
                    formik.setFieldValue("name", e.target.value.trimStart())
                  }}
                />
              </div>
              <div className="error-question-um">{formik.errors.name || ""}</div>


              <div className="form-group margin-0">
                <label>Email</label>

                <input
                  className="add-question-auto-complete"
                  name="email"
                  maxLength={30}
                  placeholder="Email"
                  value={formik.values?.email}
                  onChange={(e) => {
                    e.target.value.trim()
                    formik.setFieldValue("email", e.target.value.trimStart())
                  }}
                />
              </div>
              <div className="error-question-um">{formik.errors.email || ""}</div>


              <div className="form-group margin-0  password-wrapper">
                <label>Password</label>

                <input
                  className="add-question-auto-complete"
                  name="password"
                  type={!eye ? "password" : "text"}
                  maxLength={14}
                  placeholder="Password"
                  value={formik.values?.password}
                  onChange={(e) => {
                    e.target.value.trim()
                    formik.setFieldValue("password", e.target.value.trimStart())
                  }}
                />
                <img className="eyemask" onClick={() => setEye((prev) => !prev)} src={!eye ? images.hidden : images.eye}></img>
              </div>
              <div className="error-question-um">{formik.errors.password || ""}</div>

            </div>


            <div className="add-question-btn-wrapper">
              <button
                type="submit"
                className="addturna submit-button"
              >
                {appconstant.submit}
              </button>
            </div>
          </form>
        </div>
      </LayoutHoc>

    </div >
  );
};

export default AddSubAdmin;
