import React, { useState, useEffect } from "react";
import { Pagination } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Table, Modal } from "antd";
import appconstant from "../../themes/appconstant";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { useSelector, useDispatch } from "react-redux";
import {
  clearUserDetails,
  userBlockAction,
  userDeleteAction,
  userListingAction,
} from "../../Redux/Action/userManagementAction";
import { isInternetConnected } from "../../Utils/InternetConnection";
import { convertSecondsToHoursMinutesSeconds, handlePaginationChange, handleSearching } from "../../Utils/helpers";
import LayoutHoc from "../../common/LayoutHoc";
import "./user.css";
import images from "../../themes/appImage";
import { confirmationModalBlockAndDelete } from "../../common/modal";
const UsersManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState("");
  const [order, setOrder] = useState(null);
  const userListingData = useSelector((state) => state.userManagementReducer);
  const { result, pagination, isLoading } = userListingData;
  localStorage.setItem("isUnassigned", false);

  const fetchDataListing = (search, offset, limit, sortBy, order) => {
    dispatch(userListingAction(search, offset, limit, sortBy, order, navigate));
  };

  useEffect(() => {
    isInternetConnected() &&
      fetchDataListing(search, offset, limit, sortBy, order, navigate);
    dispatch(clearUserDetails());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [result])

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",
      sorter: false,
      render: (text, record, index) =>
        index + 1 + (pagination?.nextPage - limit),
    },
    {
      title: "Profile Picture",
      dataIndex: "profilePicture",
      key: "profilePicture",
      sorter: false,
      render: (profilePic, record) => (
        <div>
          <img src={
            profilePic && profilePic.includes('http')
              ? profilePic
              : profilePic ?
                appconstant.imageUrl + profilePic
                : images.blankImage
          }></img>
        </div >
      ),
    },
    {
      title: "Username",
      dataIndex: "userName",
      key: "userName",
      sorter: true,
      render: (userName, record) => (
        <div>
          {userName}
        </div>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      sorter: true,
      render: (email, record) => <div>{email ? email : "N/A"}</div>,
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      sorter: true,
      render: (country, record) => <div>{country ? country : "N/A"}</div>,
    },
    {
      title: "Play Time",
      dataIndex: "playTime",
      key: "playTime",
      className: "min-width-playTime",
      sorter: true,
      render: (playTime, record) => <div>{playTime >= 0 ?
        `${convertSecondsToHoursMinutesSeconds(record.playTime).hours} h , 
        ${convertSecondsToHoursMinutesSeconds(record.playTime).minutes} m , 
        ${convertSecondsToHoursMinutesSeconds(record.playTime).seconds} s`
        : "N/A"}</div>,
    },
    {
      title: "Sign Up Date",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      render: (createdAt, record) => <div> {createdAt ?
        new Date(createdAt).toLocaleDateString('en-AU', {
          day: '2-digit',
          month: 'long',
          year: 'numeric'
        })
        : "N/A"
      }</div>,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      align: 'center',
      key: "actions",
      width: "300px",
      render: (text, record) => {
        return (
          <div className="action-icon">
            {/* view button */}
            <button
              onClick={() =>
                navigate("/userdetails", { state: { id: record } })
              }>
              {appconstant.view}
            </button>
            {/* delete button */}

            <button
              onClick={() => {
                confirmationModalBlockAndDelete(
                  record,
                  () =>
                    isInternetConnected() && dispatch(userDeleteAction(record?._id, search, navigate)),
                  true
                );
              }}>
              {appconstant.delete}
            </button>
            {/* block/unblock button */}
            <button
              onClick={() => {
                confirmationModalBlockAndDelete(record, () =>
                  isInternetConnected() && dispatch(
                    userBlockAction(
                      { userId: record?._id, status: !record?.isBlocked },
                      search,
                      navigate
                    )
                  )
                );
              }}
            >
              {record?.isBlocked === true ? "Unblock" : appconstant.block}
            </button>
          </div>
        );
      },
    },
  ];

  const handleChange = async (pagination, filters, sorter) => {
    if (sorter.order === "ascend") {
      setOrder(1);
      setSortBy(sorter.columnKey);
      fetchDataListing(search, offset, limit, sorter.columnKey, 1);
    } else if (sorter.order === "descend") {
      setOrder(-1);
      setSortBy(sorter.columnKey);
      fetchDataListing(search, offset, limit, sorter.columnKey, -1);
    } else {
      setOrder(null);
      setSortBy("");
      fetchDataListing(search, offset, limit, "", null);
    }
  };

  const inputSearch = (e) => {
    if (e.target.value.charAt() === " ") {
      return;
    }
    setSearch(e.target.value);
    handleSearching(e, limit, setSearch, fetchDataListing);
  };

  return (
    <div>
      <BreadcrumbFn
        path={["dashboard"]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          appconstant.UsersManagement,
        ]}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          background: "#fff",
          padding: "17px 28px 0px 25px",
          alignItems: "center",
        }}>
        <h6 class="text-white text-capitalize ps-3">
          {appconstant.UsersManagement}
        </h6>
        <input
          type="text"
          className="iocn-search  search-bar"
          placeholder="Search"
          value={search}
          onChange={inputSearch}
        />
      </div>
      <LayoutHoc
        customClass={"userManagement"}
        child2={
          <>
            <Table
              className="user-management-table"
              dataSource={result}
              loading={isLoading}
              columns={columns}
              showSorterTooltip={false}
              onChange={handleChange}
              pagination={false}
            />
            <div className="table-pagination">
              <Pagination
                current={pagination?.currentPage}
                onChange={(e) => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                  handlePaginationChange(
                    e,
                    search,
                    limit,
                    sortBy,
                    order,
                    setOffset,
                    fetchDataListing
                  );
                }}
                total={pagination?.totalCount}
                showSizeChanger={false}></Pagination>
            </div>
          </>
        }>
        <div></div>

      </LayoutHoc>
    </div>
  );
};

export default UsersManagement;
