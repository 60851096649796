export const ValidationConstant = {
    uploadTooltip: "Click here to upload image.",
    changeTooltip: "Click here to change image.",
    validEmail: "Please enter valid email address.",
    emptyEmail: "Please enter email address.",
    minEmail: "Email address should be at least 20 characters.",
    emptyPass: "Please enter password.",
    validPass: "Please enter valid password.",
    minPass: "Password should be at least 8 characters long.",
    matchPass: "Confirm password should match with password.",
    confirmPass: "Please enter confirm password.",
    emailRequired: "Please enter email address.",
    invalidEmail: "Please enter valid email address.",
    oldPasswordRequired: "Please enter old password.",
    passwordRequired: "Please enter password.",
    newPasswordRequired: "Please enter new password.",
    confirmNewPasswordRequired: "Please enter confirm new password.",
    confirmNewPasswordMustBeSame: "Confirm new password must match with new password.",
    passwordValidation: "Password must include 8 characters, 1 upper case letter, 1 lower case letter, 1 numeric value, 1 special character and no spaces.",
    nameRequired: "Please enter name.",
    minNameValidation: "Name should contain atleast 2 characters.",
    maxNameValidation: "Name should not contain more than 15 characters.",
    // *********************CATEGORY MANAGEMENT*****************************
    category: {
        empty: "Please enter category name.",
        categoryAccessibility: "Please select category accessibility.",
        descriptionRequire: "Please enter description.",
        starRequire: "Please enter stars.",
        min: "Category name should be at least 2 characters long.",
        descriptionMin: "Description should be at least 2 characters long.",
        descriptionMax: "Description should be less than 150 characters.",
        starMin: "Stars should be at least 2 characters long.",
        starMax: "Stars should be less than 10 characters.",
        max: "Category should be less than 22 characters.",
        color: "Please select color.",
        animation: "Please select animation.",
        questionBackground: "Please add question background image.",
        categoryBackground: "Please add category background image.",
        icon: "Please add icon.",
        mapType: "Please select map type."
    },


    // *********************QUESTION MANAGEMENT*****************************
    question: {
        empty: "Please enter question.",
        categoryAccessibility: "Please select category accessibility.",
        min: "Question should be at least 2 characters long.",
        max: "Question should be less than 150 characters.",
        color: "Please select color.",
        audio: "Please select animation.",
        questionBackground: "Please add question background image.",
        categoryBackground: "Please add category background image.",
        icon: "Please add icon.",
    },

    // *********************USER MANAGEMENT*****************************
    firstName: {
        empty: "Please enter first name.",
        min: "First name should be at least 2 characters long.",
        valid: "Only alphabets are allowed in first name."
    },
    lastName: {
        empty: "Please enter last name.",
        min: "Last name should be at least 2 characters long.",
        valid: "Only alphabets are allowed in last name."
    },
    bio: {
        empty: "Please enter bio.",
        min: "Bio should be at least 2 characters long."
    },

    // *********************POLL MANAGEMENT*****************************
    title: {
        empty: "Please enter title.",
        min: "Title should be at least 2 characters long."
    },
}