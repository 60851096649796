import { apiConstants as types } from '../../themes/apiConstants'
const initialState = {
    isLoading: false,
    result: [],
    errorState: null,
    pagination: {},
    deleteData: false,
    categoryDetails: {}
}

export const suggestedCategoryManagementReducer = (state = initialState, action) => {
    switch (action.type) {

        // suggested category listing
        case types.API_SUGGESTED_CATEGORY_MANAGEMENT_LISTING_LOAD:
            return { ...state, isLoading: true, errorState: null }
        case types.API_SUGGESTED_CATEGORY_MANAGEMENT_LISTING_SUCCESS:
            return { ...state, isLoading: false, result: action.result.data.list, pagination: action.result.data.pagination }

        // suggested category accept reject
        case types.API_SUGGESTED_CATEGORY_MANAGEMENT_ACCEPT_LOAD:
            return { ...state, isLoading: true, errorState: null };
        case types.API_SUGGESTED_CATEGORY_MANAGEMENT_ACCEPT_SUCCESS:
            return { ...state, isLoading: false };

        // suggested category Failed Case
        case types.API_SUGGESTED_CATEGORY_MANAGEMENT_FAILED:
            return { ...state, isLoading: false }
        case types.API_SUGGESTED_CATEGORY_MANAGEMENT_ERROR:
            return { ...state, isLoading: false }
        //Default case
        default:
            return { ...state }
    }
}
