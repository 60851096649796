import React, { useState, useEffect, useRef } from "react";
import { Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Table, Modal } from "antd";
import appconstant from "../../themes/appconstant";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { useSelector, useDispatch } from "react-redux";
import { reportListingAction } from "../../Redux/Action/reportManagementAction";
import { handlePaginationChange, handleSearching, tableInitialData } from "../../Utils/helpers";
import LayoutHoc from "../../common/LayoutHoc";
import "./reportedUser.css";
import { isInternetConnected } from "../../Utils/InternetConnection";
import moment from "moment/moment";

const ReportedUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [payloadData, setPayload] = useState(tableInitialData)
  const userListingData = useSelector((state) => state.reportManagementReducer);
  const { pagination, isLoading } = userListingData;
  localStorage.setItem("isUnassigned", false);

  const currentVideoRef = useRef(null);

  const handleVideoPlay = (event) => {
    if (currentVideoRef.current && currentVideoRef.current !== event.target) {
      currentVideoRef.current.pause();
    }
    currentVideoRef.current = event.target;
  };


  const fetchDataListing = (data) => {
    dispatch(reportListingAction(data, navigate));
  };

  React.useLayoutEffect(() => {
    isInternetConnected() && fetchDataListing(payloadData)
  }, [payloadData]);



  useEffect(() => {
    window.scrollTo(0, 0)
  }, [userListingData.result])

  const onPaginationChange = (e) => {
    setPayload((state) => ({ ...state, offset: (e - 1) * 10 }))
    fetchDataListing(payloadData)
  }


  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",
      sorter: false,
      render: (text, record, index) => index + 1 + (pagination?.nextPage - payloadData.limit),
      width: 100
    },
    {
      title: "Reported User",
      dataIndex: "reportedUser",
      key: "reportedUser",
      sorter: true,
      render: (reportedUser) => (
        <div>
          {reportedUser?.userName}
        </div>
      ),
      width: "200px"

    },
    {
      title: "Reported By",
      dataIndex: "reportedBy",
      key: "reportedBy",
      sorter: true,
      render: (reportedBy) => (
        <div>
          {reportedBy?.userName}
        </div>
      ),
      width: "150px"
    },
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
      sorter: true,
      render: (qns, record) => <div>{qns?.question ?? "N/A"}</div>,
      ellipsis: true,
      width: "300px"
    },
    {
      title: "Answer",
      dataIndex: "answer",
      key: "answer",
      // sorter: true,
      render: (answer) => <div>
        {
          answer && answer.type === 'video' ?
            <video
              width="300"
              height="200"
              controls onPlay={handleVideoPlay}
            >
              <source
                src={appconstant.imageUrl + answer?.file}
                type="video/mp4"
              />
            </video>
            : answer && answer.type === 'audio'
              ?
              <audio
                height="100"
                controls // Add the 'controls' attribute here
                onPlay={handleVideoPlay}
              >
                <source
                  src={appconstant.imageUrl + answer.file}
                  type="audio/mpeg"
                />
              </audio>
              : 'N/A'
        }
      </div>,
      width: "380px"
    },
    {
      title: "Reason To Report",
      dataIndex: "reason",
      key: "reason",
      sorter: true,
      width: "200px"
    },
    {
      title: "Date & Time",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      render: (dateTime) => <div>{dateTime ? moment(dateTime).format("DD-MM-YYYY hh:mmA") : "N/A"}</div>,
      width: "200px"
    },
    {
      title: "Total No. Of Reports",
      dataIndex: "totalCount",
      key: "totalCount",
      width: '100',
      sorter: true,
      width: "200px"
    },
    {
      title: "Action",
      dataIndex: "reportedUser",
      key: "action",
      width: 200,
      render: ({ _id }, row) => {
        return (
          <div className="action-icon">
            <button
              className={row.isAppealed ? '' : "disabledButton"}
              onClick={() => navigate("/view-appeal", { state: { id: _id } })
              }
            >
              {appconstant.view}
            </button>
          </div>
        );
      }
    }
  ];

  const handleChange = async (pagination, filters, sorter) => {
    setPayload((state) => ({
      ...state,
      order: sorter.order === "ascend"
        ? 1
        : sorter.order === "descend"
          ? -1 : null,
      sortBy: sorter.order ? sorter.columnKey : ""
    }))
  };

  return (
    <div>
      <BreadcrumbFn
        path={["dashboard"]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          appconstant.reportedUsersManagement,
        ]}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          background: "#fff",
          padding: "17px 28px 0px 25px",
          alignItems: "center",
        }}
      >
        <h6 class="text-white text-capitalize ps-3 pt-10">
          {appconstant.reportedUsersManagement}
        </h6>
        <input
          type="text"
          className="iocn-search  search-bar"
          placeholder="Search"
          value={payloadData.search}
          onChange={(e) => {
            if (e.target.value.charAt() === " ") {
              return;
            }
            setPayload((state) => ({ ...state, search: e.target.value.trimStart() }));
          }
          }
        />
      </div>
      <LayoutHoc
        customClass={"userManagement"}
        child2={
          <>
            <Table
              className="user-management-table"
              dataSource={userListingData.result}
              loading={isLoading}
              columns={columns}
              showSorterTooltip={false}
              onChange={handleChange}
              pagination={false}
            />
            <div className="table-pagination">
              <Pagination
                current={pagination?.currentPage}
                onChange={onPaginationChange}
                total={pagination?.totalCount}
                showSizeChanger={false}
              ></Pagination>
            </div>
          </>
        }
      >

      </LayoutHoc>
    </div>
  );
};

export default ReportedUser;
