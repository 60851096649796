import React, { useState, useEffect } from "react";
import { Pagination, Tooltip } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Table, Modal } from "antd";
import appconstant from "../../themes/appconstant";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { useSelector, useDispatch } from "react-redux";
import {
  clearUserDetails,
  userListingAction,
} from "../../Redux/Action/userManagementAction";
import { handlePaginationChange, handleSearching } from "../../Utils/helpers";
import LayoutHoc from "../../common/LayoutHoc";
import "./inappPurchase.css";
import ModalPop from "../../common/modal";
import InputField from "../../common/DisableInputField";
import { isInternetConnected } from "../../Utils/InternetConnection";
import { inAppListingAction } from "../../Redux/Action/inAppManagementAction";
import moment from "moment";

const InAppPurchase = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState("");
  const [order, setOrder] = useState(null);
  const [userDetails, setUserDetails] = useState([]);
  const inAppListingData = useSelector((state) => state.inAppManagementReducer);
  const { result, pagination, isLoading } = inAppListingData;

  const fetchDataListing = (search, offset, limit, sortBy, order) => {
    dispatch(
      inAppListingAction(search, offset, limit, sortBy, order, navigate)
    );
  };

  useEffect(() => {
    isInternetConnected() &&
      fetchDataListing(search, offset, limit, sortBy, order, navigate);
    dispatch(clearUserDetails());
  }, []);

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",
      sorter: false,
      render: (text, record, index) =>
        index + 1 + (pagination?.nextPage - limit),
    },
    {
      title: "Username",
      dataIndex: "userName",
      key: "userName",
      sorter: true,
      render: (userName, record) => (
        <div
          onClick={() => {
            setShowModal(true);
            setUserDetails(record);
          }}>
          <Tooltip title={"Click to view the user details."}>
            {userName}
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Date & Time",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      render: (dateTime, record) =>
        moment(dateTime).format("DD/MM/YYYY hh:mm A"),
    },
    {
      title: "Amount",
      dataIndex: "price",
      key: "price",
      sorter: true,
    },
    {
      title: "Package Type",
      dataIndex: "pt",
      key: "pt",
      sorter: true,
      render: (pt, record) => <div>{pt ? pt : "N/A"}</div>,
    },
  ];

  const handleChange = async (pagination, filters, sorter) => {
    if (sorter.order === "ascend") {
      setOrder(1);
      setSortBy(sorter.columnKey);
      fetchDataListing(search, offset, limit, sorter.columnKey, 1);
    } else if (sorter.order === "descend") {
      setOrder(-1);
      setSortBy(sorter.columnKey);
      fetchDataListing(search, offset, limit, sorter.columnKey, -1);
    } else {
      setOrder(null);
      setSortBy("");
      fetchDataListing(search, offset, limit, "", null);
    }
  };

  const userFields = [
    {
      label: appconstant.UserFullName,
      value: userDetails?.userName || "N/A",
    },
    {
      label: appconstant.name,
      value: userDetails?.name || "N/A",
    },
    {
      label: appconstant.Country,
      value: userDetails?.country || "N/A",
    },
    {
      label: appconstant.EmailAddress,
      value: userDetails?.email || "N/A",
    },
    {
      label: appconstant.amount,
      value: userDetails?.price || "N/A",
    },
    {
      label: appconstant.dateTime,
      value: userDetails?.createdAt
        ? moment(userDetails?.createdAt).format("DD/MM/YYYY hh:mm A")
        : "N/A",
    },
  ];
  return (
    <div>
      <BreadcrumbFn
        path={["dashboard"]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          appconstant.inAppPurchaseManagement,
        ]}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          background: "#fff",
          // padding: "10px 28px 0 25px",
          padding: "17px 28px 0px 25px",
          alignItems: "center",
        }}>
        <h6 class="text-white text-capitalize ps-3 pt-10">
          {appconstant.inAppPurchaseManagement}
        </h6>
        <input
          type="text"
          className="iocn-search  search-bar"
          placeholder="Search"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            handleSearching(e, limit, setSearch, fetchDataListing);
          }}
        />
      </div>
      <LayoutHoc
        customClass={"userManagement"}
        child2={
          <>
            <Table
              className="user-management-table in-app-purchase"
              dataSource={result}
              loading={isLoading}
              columns={columns}
              showSorterTooltip={false}
              onChange={handleChange}
              pagination={false}
            />
            <div className="table-pagination">
              <Pagination
                current={pagination?.currentPage}
                onChange={(e) => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                  handlePaginationChange(
                    e,
                    search,
                    limit,
                    sortBy,
                    order,
                    setOffset,
                    fetchDataListing
                  );
                }}
                total={pagination?.totalCount}
                showSizeChanger={false}></Pagination>
            </div>
          </>
        }>
        <div></div>
        <ModalPop
          isVisible={showModal}
          title={"User Details"}
          content={
            <div
              className="wrapper_line view_modules view"
              style={{
                width: "100%",
              }}>
              <div>
                {userFields.map((item, index) => {
                  return (
                    <InputField
                      label={item?.label}
                      style={{
                        margin: "0.5em 0 1em",
                      }}
                      value={item?.value}
                    />
                  );
                })}
              </div>
            </div>
          }
          handleCancel={() => setShowModal(false)}
        />
      </LayoutHoc>
    </div>
  );
};

export default InAppPurchase;
