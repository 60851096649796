import { takeLatest, call, put } from "redux-saga/effects";
import { apiConstants as types, appMessages } from "../../themes/apiConstants";
import axios from "../api";
import { toast } from "react-toastify";

export function* authenticationFailedSaga(result, action) {
  yield put({
    type: types.API_AUTHENTICATION_FAILED,
  });
  console.log("navigate--", action.navigate)
  toast.error(result?.error);
  if (result.status === 4) {
    console.log('action', action)
    localStorage.clear();
    action.navigate("/login");
  }
}

export function* authenticationErrorSaga(result) {
  console.log('authenticationErrorSaga', result)
  yield put({
    type: types.API_AUTHENTICATION_ERROR,
  });
  if (result.status === 2) {
    toast.error(appMessages.messageStatus500);
  } else {
    toast.error(result?.error, { toastId: result?.status || "est" });
  }
}

function* loginSaga(action) {
  const { email, password } = action;
  try {
    const result = yield call(axios.login, email, password);
    if (result.status === 1) {
      yield put({
        type: types.API_LOGIN_SUCCESS,
        result: result.result.data.data,
        message: result.result.data.success,
      });
      action.navigate("/dashboard");
    } else {
      yield call(authenticationFailedSaga, result, action);
    }
  } catch (error) {
    yield call(authenticationErrorSaga, error);
  }
}

function* forgotPasswordSaga(action) {
  const { email } = action;
  try {
    const result = yield call(axios.forgotpassword, email);
    if (result.status === 1) {
      yield put({
        type: types.API_FORGOT_PASSWORD_SUCCESS,
        result: { success: result.result.data.success },
        message: result.result.data.success,
      });
    } else {
      yield call(authenticationFailedSaga, result, action);
    }
  } catch (error) {
    yield call(authenticationErrorSaga, error);
  }
}

function* resetPasswordSaga(action) {
  const { newPassword, query, tokenType } = action;
  try {
    const result = yield call(axios.resetPassword, newPassword, query);
    if (result.status === 1) {
      yield put({
        type: types.API_RESET_PASSWORD_SUCCESS,
        result: result.result.data.success,
      });
      if (tokenType === "admin") {
        toast.success(result.result.data.message);
        action.navigate("/login");
      }
    } else {
      yield call(authenticationFailedSaga, result, action);
    }
  } catch (error) {
    yield call(authenticationErrorSaga, error);
  }
}

function* checkResetPasswordSaga(action) {
  const { id } = action;
  try {
    const result = yield call(axios.checkResetPassword, id);
    if (result.status === 1) {
      yield put({
        type: types.API_CHECK_RESET_PASSWORD_SUCCESS,
        result: result?.result?.data,
      });
    } else {
      yield put({
        type: types.API_AUTHENTICATION_FAILED,
        result: result,
      });
      // toast.error(result?.error, { toastId: result?.status || "est" })
    }
  } catch (error) {
    yield put({
      type: types.API_AUTHENTICATION_ERROR,
    });
    if (error.status === 2) {
      toast.error(appMessages.messageStatus500);
    } else {
      // toast.error(error?.error, { toastId: error?.status || "est" })
    }
  }
}

function* changePasswordSaga(action) {
  const { oldPassword, newPassword } = action;
  try {
    const result = yield call(
      axios.changePasswordApi,
      oldPassword,
      newPassword
    );
    if (result.status === 1) {
      yield put({
        type: types.API_CHANGE_PASSWORD_SUCCESS,
        result: result.result.data.data,
      });
      toast.success(result.result.data.message);
      localStorage.removeItem("token");
      action.navigate("/");
    } else {
      yield call(authenticationFailedSaga, result, action);
    }
  } catch (error) {
    yield call(authenticationErrorSaga, error);
  }
}

function* logoutSaga(action) {
  try {
    const result = yield call(axios.logoutApi);
    if (result.status === 1) {
      toast.success(result.result.data.message);
      localStorage.clear();
      action.navigate("/");
    } else {
      yield call(authenticationFailedSaga, result, action);
    }
  } catch (error) {
    yield call(authenticationErrorSaga, error);
  }
}


export default function* rootAuthenticationSaga() {
  yield takeLatest(types.API_LOGIN_LOAD, loginSaga);
  yield takeLatest(types.API_FORGOT_PASSWORD_LOAD, forgotPasswordSaga);
  yield takeLatest(types.API_RESET_PASSWORD_LOAD, resetPasswordSaga);
  yield takeLatest(types.API_CHECK_RESET_PASSWORD_LOAD, checkResetPasswordSaga);
  yield takeLatest(types.API_CHANGE_PASSWORD_LOAD, changePasswordSaga);
  yield takeLatest(types.API_LOGOUT_LOAD, logoutSaga);
}
