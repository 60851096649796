import React from "react";

const Card = (props) => {
const {
    imageSrc,
    title,
    count,
    smallImg =false
} = {...props} ;
  return (
    <div
     className="card-wrapper" 
    >
      <div
       className="card-img-wrapper"
       style={{
         display: smallImg ? 'flex' : null,
       }}
      >
        <img
          src={imageSrc}
          style={{
            width: smallImg ? '52%' : "100%" ,
            height: "100%",
          }}
        />
      </div>
      <p
       className="card-description"
        style={{
          margin: 0,
          fontSize : '16px',
          color: '#fff',
          fontWeight: 500 
        }}
      >
        {title}: {'   '}
       <span>
       {count} 
        </span>
      </p>
      {/* <p>
      {count} 
      </p> */}
    </div>
  );
};

export default Card;
