import { takeLatest, call, put } from "redux-saga/effects";
import { apiConstants as types, appMessages } from "../../themes/apiConstants";
import axios from "../api";
import { toast } from "react-toastify";
import { authenticationErrorSaga, authenticationFailedSaga } from "./authenticationSaga";
import { navTo, navigateFn } from "../../Utils/helpers";

// function* authenicationUser(result, navigate) {
//     toast.error(result?.error)
//     localStorage.clear()
//     navigate("/")
// }

function* dashboardFailedSaga(result) {
  yield put({
    type: types.API_DASHBOARD_FAILED,
  });
  localStorage.clear()
  // navTo("/")
  toast.error(result?.error, { toastId: result?.status || "est" });
}

function* dashboardErrorSaga(result) {
  yield put({
    type: types.API_DASHBOARD_ERROR,
  });
  if (result.status === 2) {
    toast.error(appMessages.messageStatus500);
  } else {
    toast.error(result?.error, { toastId: result?.status || "est" });
  }
}

function* dashboardSaga(action) {
  try {
    const result = yield call(axios.dashboard);
    if (result.status === 1) {
      yield put({
        type: types.API_DASHBOARD_SUCCESS,
        result: result.result.data.data,
        message: result.result.data.success,
      });
    } else if (result.status === 4) {
      yield call(authenticationFailedSaga, result, action);
    } else {
      yield call(authenticationErrorSaga, result, action);
    }
  } catch (error) {
    yield call(dashboardErrorSaga, error);
  }
}

function* updateReferralSaga(action) {
  try {
    const result = yield call(axios.updateReferral, action.payload)
    if (result.status === 1) {
      toast.success(result.result.data.message);
      yield put({
        type: types.API_REFERRAL_SUCCESS,
        result: result.result.data.data,
        message: result.result.data.success,
      })
    } else if (result.status === 4) {
      yield call(authenticationFailedSaga, result, action);
    } else {
      yield call(authenticationErrorSaga, result, action);
    }
  } catch (error) {
    yield call(dashboardErrorSaga, error);
  }
}

export default function* rootDashboardSaga() {
  yield takeLatest(types.API_DASHBOARD_LOAD, dashboardSaga);
  yield takeLatest(types.API_REFERRAL_LOAD, updateReferralSaga);
}
