import { takeLatest, call, put } from "redux-saga/effects";
import { apiConstants as types, appMessages } from "../../themes/apiConstants";
import axios from "../api";
import { toast } from "react-toastify";

function* authenicationUser(result, navigate) {
  toast.error(result?.error);
  localStorage.removeItem("token");
  localStorage.clear();
  navigate.navigate("/");
}

function* categoryFailedSaga(result) {
  yield put({
    type: types.API_CATEGORY_MANAGEMENT_FAILED,
  });
  toast.error(result?.error);
}

function* categoryErrorSaga(result) {
  yield put({
    type: types.API_CATEGORY_MANAGEMENT_ERROR,
  });
  if (result.status === 2) {
    toast.error(appMessages.messageStatus500);
  } else {
    toast.error(result?.error);
  }
}

function* categoryListingSaga(action) {
  try {
    const result = yield call(
      axios.categoryListing,
      action.data
    );
    if (result.status === 1) {
      yield put({
        type: types.API_CATEGORY_MANAGEMENT_LISTING_SUCCESS,
        result: result.result.data,
      });
    } else if (result.status === 4) {
      yield call(authenicationUser, result, action);
    } else {
      yield call(categoryFailedSaga, result);
    }
  } catch (error) {
    yield call(categoryErrorSaga, error);
  }
}

function* categoryListingForDropSaga(action) {
  try {
    const result = yield call(
      axios.categoryListingForDrop);
    if (result.status === 1) {
      yield put({
        type: types.API_CATEGORY_MANAGEMENT_LISTING_DROP_SUCCESS,
        result: result.result.data,
      });

    } else if (result.status === 4) {
      console.log("navigate", action)
      yield call(authenicationUser, result, action);
    } else {
      yield call(categoryFailedSaga, result);
    }
  } catch (error) {
    yield call(categoryErrorSaga, error);
  }
}

function* categoryUpdateSaga(action) {
  const { data, navigate } = action;
  try {
    const result = yield call(axios.categoryUpdate, data);
    if (result.status === 1) {
      const resultDetail = yield call(
        axios.categoryListing, {
        search: "",
        offset: 0,
        limit: 10,
        sortBy: "",
        order: ""
      });
      if (resultDetail.status === 1) {
        yield put({
          type: types.API_CATEGORY_MANAGEMENT_LISTING_SUCCESS,
          result: resultDetail.result.data,
        });
      }

      yield put({
        type: types.API_CATEGORY_MANAGEMENT_UPDATE_SUCCESS,
        result: result.result.data,
      });
      navigate('/category-management')
      toast.success(result.result.data.message);
    } else if (result.status === 4) {
      yield call(authenicationUser, result, action);
    } else {
      yield call(categoryFailedSaga, result);
    }
  } catch (error) {
    navigate('/category-management')

    yield call(categoryErrorSaga, error);
  }
}

function* categoryAddSaga(action) {
  try {
    const result = yield call(axios.categoryAdd, action.data);

    if (result.status === 1) {
      const resultDetail = yield call(
        axios.categoryListing, {
        search: "",
        offset: 0,
        limit: 10,
        sortBy: "",
        order: ""
      });
      if (resultDetail.status === 1) {
        yield put({
          type: types.API_CATEGORY_MANAGEMENT_LISTING_SUCCESS,
          result: resultDetail.result.data,
        });
      }

      yield put({
        type: types.API_CATEGORY_MANAGEMENT_ADD_SUCCESS,
        result: result.result.data,
      });
      toast.success(result.result.data.message);
      action.navigate('/category-management');
    } else if (result.status === 4) {
      yield call(authenicationUser, result, action);
    } else {
      yield call(categoryFailedSaga, result);
    }
  } catch (error) {
    yield call(categoryErrorSaga, error);
  }
}

function* categoryDetailsSaga(action) {
  const { id } = action;
  try {
    const result = yield call(axios.categoryDetails, id);
    if (result.status === 1) {
      yield put({
        type: types.API_CATEGORY_MANAGEMENT_DETAILS_SUCCESS,
        result: result.result.data,
      });
    } else if (result.status === 4) {
      yield call(authenicationUser, result, action);
    } else {
      yield call(categoryFailedSaga, result);
    }
  } catch (error) {
    yield call(categoryErrorSaga, error);
  }
}

function* categoryDeleteSaga(action) {

  try {
    const result = yield call(axios.categoryDelete, action.id, action.addToUnassigned);
    if (result.status === 1) {
      const resultStatus = yield call(
        axios.categoryListing, {
        limit: 10,
        offset: 0,
        order: null,
        search: "",
        sortBy: ""
      }
      );
      if (result.status === 1) {
        yield put({
          type: types.API_CATEGORY_MANAGEMENT_LISTING_SUCCESS,
          result: resultStatus.result.data,
        });
      }

      yield put({
        type: types.API_CATEGORY_MANAGEMENT_DELETE_SUCCESS,
        result: result.result.data,
      });
      toast.success(result.result.data.message);
    } else if (result.status === 4) {
      yield call(authenicationUser, result, action);
    } else {
      yield call(categoryFailedSaga, result);
    }
  } catch (error) {
    yield call(categoryErrorSaga, error);
  }
}

function* categoryEnableDisableSaga(action) {

  try {
    const result = yield call(axios.categoryEnableDisable, action.data);
    if (result.status === 1) {
      const resultStatus = yield call(
        axios.categoryListing, {
        limit: 10,
        offset: 0,
        order: null,
        search: "",
        sortBy: ""
      }
      );
      if (result.status === 1) {
        yield put({
          type: types.API_CATEGORY_MANAGEMENT_LISTING_SUCCESS,
          result: resultStatus.result.data,
        });
      }

      toast.success(result.result.data.message);
    } else if (result.status === 4) {
      yield call(authenicationUser, result, action);
    } else {
      yield call(categoryFailedSaga, result);
    }
  } catch (error) {
    yield call(categoryErrorSaga, error);
  }
}

function* categoryMapListSaga(action) {

  try {
    const result = yield call(axios.mapList);
    if (result.status === 1) {
      yield put({
        type: types.API_CATEGORY_MANAGEMENT_MAP_LIST_SUCCESS,
        result: result.result.data,
      });

    } else if (result.status === 4) {
      yield call(authenicationUser, result, action);
    } else {
      yield call(categoryFailedSaga, result);
    }
  } catch (error) {
    yield call(categoryErrorSaga, error);
  }
}

export default function* rootCategoryManagementSaga() {
  yield takeLatest(
    types.API_CATEGORY_MANAGEMENT_LISTING_LOAD,
    categoryListingSaga
  );
  yield takeLatest(
    types.API_CATEGORY_MANAGEMENT_UPDATE_LOAD,
    categoryUpdateSaga
  );
  yield takeLatest(
    types.API_CATEGORY_MANAGEMENT_DETAILS_LOAD,
    categoryDetailsSaga
  );
  yield takeLatest(types.API_CATEGORY_MANAGEMENT_ADD_LOAD, categoryAddSaga);
  yield takeLatest(
    types.API_CATEGORY_MANAGEMENT_DELETE_LOAD,
    categoryDeleteSaga
  );
  yield takeLatest(
    types.API_CATEGORY_MANAGEMENT_ENABLE_DISABLE_LOAD,
    categoryEnableDisableSaga
  );
  yield takeLatest(
    types.API_CATEGORY_MANAGEMENT_LISTING_DROP_LOAD,
    categoryListingForDropSaga
  );

  yield takeLatest(
    types.API_CATEGORY_MANAGEMENT_MAP_LIST_LOAD,
    categoryMapListSaga
  );
}
