import { apiConstants as types } from "../../themes/apiConstants";

// user listing Action
export const userListingAction = (
  search,
  offset,
  limit,
  sortBy,
  order,
  navigate
) => ({
  type: types.API_USER_MANAGEMENT_LISTING_LOAD,
  search,
  offset,
  limit,
  sortBy,
  order,
  navigate,
});

// user details Action
export const userDetailsAction = (id, navigate) => ({
  type: types.API_USER_MANAGEMENT_DETAILS_LOAD,
  id,
  navigate,
});

// user answer listing listing Action
export const userAnswerListingAction = (data) => (
  {
    type: types.API_USER_ANSWER_LISTING_LOAD,
    data
  });

// user update
export const userUpdateAction = (data, navigate) => ({
  type: types.API_USER_MANAGEMENT_UPDATE_LOAD,
  data,
  navigate,
});

// user block
export const userBlockAction = (data, search, navigate) => ({
  type: types.API_USER_MANAGEMENT_BLOCK_LOAD,
  data,
  search,
  navigate,
});

// user delete
export const userDeleteAction = (id, search, navigate) => ({
  type: types.API_USER_MANAGEMENT_DELETE_LOAD,
  id,
  search,
  navigate,
});

//country listing
export const countryListingAction = (navigate) => ({
  type: types.API_COUNTRY_LISTING_LOAD,
  navigate,
});

//city listing
export const cityListingAction = (id, navigate) => ({
  type: types.API_CITY_LISTING_LOAD,
  id,
  navigate,
});

//education listing
export const educationListingAction = (navigate) => ({
  type: types.API_EDUCATION_LISTING_LOAD,
  navigate,
});

//employement listing
export const employementListingAction = (navigate) => ({
  type: types.API_EMPLOYEMENT_LISTING_LOAD,
  navigate,
});

// Clear user details
export const clearUserDetails = () => {
  return {
    type: types.API_CLEAR_POLL_DETAILS_LOAD,
  };
};
