import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import appconstant from "../../themes/appconstant";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { useDispatch } from "react-redux";
import LayoutHoc from "../../common/LayoutHoc";
import "./questionsManagement.css";
import InputField from "../../common/DisableInputField";
import { questionUpdateAction } from "../../Redux/Action/questionManagementAction"
import { isInternetConnected } from "../../Utils/InternetConnection";
import { ValidationConstant } from "../../themes/validationConstant";
import * as Yup from "yup";
import { useFormik } from "formik";


const EditQuestions = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation()
    const [error, setError] = useState(null)

    const validationSchema = Yup.object().shape({
        question: Yup.string()
            .required(ValidationConstant?.question.empty).min(2, ValidationConstant?.question.min).max(150, ValidationConstant?.question.max),
    });

    const [inputVal, setInputVal] = useState(location.state?.question ? location.state?.question : '');
    useEffect(() => {
        document.title = appconstant.MANIFEST_MOTO;
    }, []);

    const userFields = [
        {
            label: appconstant.categoryName,
            value: location?.state?.category?.name,
            disable: true,
            // value: userDetails?.UserFullName ? userDetails?.UserFullName : "N/A",
        },
        {
            label: appconstant.qn,
            value: inputVal,
            placeHolder: "Question",
            // value: userDetails?.UserFullName ? userDetails?.UserFullName : "N/A",
        },
    ];

    const initialValues = {
        question: ""
    };

    const onSubmit = (values, { setSubmitting }) => {
        // setSubmitting(false);
        let data = {
            question: values.question,
        };
        isInternetConnected() && dispatch(questionUpdateAction({ questionId: location?.state?._id, question: inputVal }, navigate('/questions-management')))
    };

    const validate = (e) => {
        let error = {};
        for (let i in e) {
            if (e[i] === null) {
                error[i] = ValidationConstant.category[i]
            }
        }

        return error
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        validate,
        // onSubmit,
    });

    return (
        <div>
            <BreadcrumbFn
                path={["dashboard", "daily-question"]}
                pathName={[
                    <FontAwesomeIcon icon={faHome} />,
                    appconstant.dailyQuestion,
                    appconstant.editQn,
                ]}
            />
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "#fff",
                    padding: "17px 28px 0px 25px",
                    // padding: "10px 28px 0 25px",
                    alignItems: "center",
                }}
            >
                <h6 class="text-white text-capitalize ps-3 ">{appconstant.editQn}</h6>
            </div>
            <LayoutHoc customClass={"userManagement"}>
                <div
                    className="wrapper_line view_modules view"
                    style={{
                        width: "100%",
                    }}
                >
                    <div>
                        {userFields.map((item, index) => {
                            return (
                                <InputField
                                    {...item}
                                    inputType={"input"}
                                    label={item?.label}
                                    style={{
                                        margin: "0.5em 0 1em",
                                    }}
                                    value={item?.value}
                                    disable={item?.disable ? true : false}
                                    maxLength={160}
                                    handleOnClick={(e) => {
                                        const value = (e.target.value).trimStart()
                                        setInputVal(value);
                                        if (value.length >= 2 && value.length <= 150) setError("")
                                        else if (value?.length > 150) setError("Question should be less than 150 characters.")
                                        else if (value?.length == 0) setError("Please enter question.")
                                        else setError("Question should be at least 2 characters long.")
                                    }}
                                />
                            );
                        })}
                    </div>
                    <div style={{ "color": "red" }} >{error}</div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            margin: "30px 0 0",
                        }}
                        className="edit-update"
                    >
                        {console.log("location", location)}

                        <button
                            className="addturna submit-button"
                            style={{
                                maxWidth: "15%",
                            }}
                            onClick={() => {
                                if (inputVal) {
                                    isInternetConnected() && dispatch(questionUpdateAction({ questionId: location?.state?._id, question: inputVal, type: "daily" }, navigate, '/daily-question'))
                                }
                                else if (inputVal?.length > 150) setError("Question should be less than 150 characters.")
                                else if (inputVal?.length == 0) setError("Please enter question.")
                                else setError("Question should be at least 2 characters long.")
                            }}
                        >
                            {appconstant.update}
                        </button>
                    </div>
                </div>
            </LayoutHoc>
        </div>
    );
};

export default EditQuestions;
