import { apiConstants as types } from '../../themes/apiConstants'
const initialState = {
    isLoading: false,
    result: [],
    errorState: null,
    pagination: {},
    deleteData: false,
    categoryDetails: {},
    mapList: []
}

export const categoryManagementReducer = (state = initialState, action) => {
    switch (action.type) {

        //category listing
        case types.API_CATEGORY_MANAGEMENT_LISTING_LOAD:
            return { ...state, isLoading: true, errorState: null }
        case types.API_CATEGORY_MANAGEMENT_LISTING_SUCCESS:
            return { ...state, isLoading: false, result: action.result.data.list, pagination: action.result.data.pagination }

        //category listing for drop
        case types.API_CATEGORY_MANAGEMENT_LISTING_DROP_LOAD:
            return { ...state, isLoading: true, errorState: null }
        case types.API_CATEGORY_MANAGEMENT_LISTING_DROP_SUCCESS:
            return { ...state, isLoading: false, result: action.result.data.list }

        //category details
        case types.API_CATEGORY_MANAGEMENT_DETAILS_LOAD:
            return { ...state, isLoading: true, errorState: null }
        case types.API_CATEGORY_MANAGEMENT_DETAILS_SUCCESS:
            return { ...state, isLoading: false, categoryDetails: action.result.data }


        //category add
        case types.API_CATEGORY_MANAGEMENT_ADD_LOAD:
            return { ...state, isLoading: true, errorState: null }
        case types.API_CATEGORY_MANAGEMENT_ADD_SUCCESS:
            return { ...state, isLoading: false, }

        //category update
        case types.API_CATEGORY_MANAGEMENT_UPDATE_LOAD:
            return { ...state, isLoading: true, errorState: null }
        case types.API_CATEGORY_MANAGEMENT_UPDATE_SUCCESS:
            return { ...state, isLoading: false, }

        //category delete
        case types.API_CATEGORY_MANAGEMENT_DELETE_LOAD:
            return { ...state, isLoading: true, errorState: null }
        case types.API_CATEGORY_MANAGEMENT_DELETE_SUCCESS:
            return { ...state, isLoading: false, deleteData: !state.deleteData }

        //category delete
        case types.API_CATEGORY_MANAGEMENT_ENABLE_DISABLE_LOAD:
            return { ...state, isLoading: true, errorState: null }

        case types.API_CATEGORY_MANAGEMENT_ENABLE_DISABLE_SUCCESS:
            return { ...state, isLoading: false }

        case types.API_CATEGORY_MANAGEMENT_MAP_LIST_SUCCESS:
            return { ...state, mapList: action.result.data }

        //category Failed Case
        case types.API_CATEGORY_MANAGEMENT_FAILED:
            return { ...state, isLoading: false }
        case types.API_CATEGORY_MANAGEMENT_ERROR:
            return { ...state, isLoading: false }

        //Default case
        default:
            return { ...state }
    }
}
