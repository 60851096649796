import { apiConstants as types } from '../../themes/apiConstants'
const initialState = {
    isLoading: false,
    result: [],
    errorState: null,
    pagination: {},
    deleteData: false,
    questionDetails: {},
    exportResult: null,
    fileLoading: false
}

export const questionManagementReducer = (state = initialState, action) => {

    switch (action.type) {

        //question listing
        case types.API_QUESTION_MANAGEMENT_LISTING_LOAD:
            return { ...state, isLoading: true, errorState: null }
        case types.API_QUESTION_MANAGEMENT_LISTING_SUCCESS:
            return { ...state, isLoading: false, fileLoading: false, result: action.result.data.list, pagination: action.result.data.pagination, exportResult: null }

        //question details
        case types.API_QUESTION_MANAGEMENT_DETAILS_LOAD:
            return { ...state, isLoading: true, errorState: null }
        case types.API_QUESTION_MANAGEMENT_DETAILS_SUCCESS:
            return { ...state, isLoading: false, questionDetails: action.result.data }


        //question add
        case types.API_QUESTION_MANAGEMENT_ADD_LOAD:
            return { ...state, isLoading: true, errorState: null }
        case types.API_QUESTION_MANAGEMENT_ADD_SUCCESS:
            return { ...state, isLoading: false, }

        //question update
        case types.API_QUESTION_MANAGEMENT_UPDATE_LOAD:
            return { ...state, isLoading: true, errorState: null }
        case types.API_QUESTION_MANAGEMENT_UPDATE_SUCCESS:
            return { ...state, isLoading: false, }

        //question delete
        case types.API_QUESTION_MANAGEMENT_DELETE_LOAD:
            return { ...state, isLoading: true, errorState: null }
        case types.API_QUESTION_MANAGEMENT_DELETE_SUCCESS:
            return { ...state, isLoading: false, deleteData: !state.deleteData }

        //question Failed Case
        case types.API_QUESTION_MANAGEMENT_FAILED:
            return { ...state, isLoading: false }
        case types.API_QUESTION_MANAGEMENT_ERROR:
            return { ...state, isLoading: false }

        case types.API_QUESTION_MANAGEMENT_EXPORT_LOAD:
            return { ...state, isLoading: true }

        case types.API_QUESTION_MANAGEMENT_IMPORT_LOAD:
            return { ...state, fileLoading: true }
        case types.API_QUESTION_MANAGEMENT_EXPORT_SUCCESS:
            return { ...state, isLoading: false, exportResult: action.result.data.list }

        case types.API_QUESTION_MANAGEMENT_IMPORT_SUCCESS:
            return { ...state, fileLoading: false, exportResult: action.result.data.list }

        //Default case
        default:
            return { ...state }
    }
}
