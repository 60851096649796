import { apiConstants as types } from '../../themes/apiConstants'

// Login Action
export const dashboardAction = (navigate) => ({
    type: types.API_DASHBOARD_LOAD,
    navigate
})

export const updateReferralAcion = (payload) => ({

    type: types.API_REFERRAL_LOAD,
    payload
})