import { takeLatest, call, put } from "redux-saga/effects";
import { apiConstants as types, appMessages } from "../../themes/apiConstants";
import axios from "../api";
import { toast } from "react-toastify";
import { authenticationFailedSaga } from "./authenticationSaga";

// function* authenicationUser(result, navigate) {
//   toast.error(result?.error);
//   localStorage.removeItem("token");
//   localStorage.clear();
//   navigate.navigate("/");
// }

function* userFailedSaga(result) {
  yield put({
    type: types.API_USER_MANAGEMENT_FAILED,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

function* userErrorSaga(result) {
  yield put({
    type: types.API_USER_MANAGEMENT_ERROR,
  });
  if (result.status === 2) {
    toast.error(appMessages.messageStatus500);
  } else {
    toast.error(result?.error, { toastId: result?.status || "est" });
  }
}

function* userListingSaga(action) {
  const { search, offset, limit, sortBy, order, status } = action;
  try {
    const result = yield call(
      axios.userListing,
      search,
      offset,
      limit,
      sortBy,
      order,
      status
    );
    if (result.status === 1) {
      yield put({
        type: types.API_USER_MANAGEMENT_LISTING_SUCCESS,
        result: result.result.data,
      });
    } else if (result.status === 4) {
      yield call(authenticationFailedSaga, result, action);
    } else {
      yield call(userFailedSaga, result);
    }
  } catch (error) {
    yield call(userErrorSaga, error);
  }
}

function* userDetailsSaga(action) {
  const { id } = action;
  try {
    const result = yield call(axios.userDetails, id);
    if (result.status === 1) {
      yield put({
        type: types.API_USER_MANAGEMENT_DETAILS_SUCCESS,
        result: result.result.data,
      });
    } else if (result.status === 4) {
      yield call(authenticationFailedSaga, result, action);
    } else {
      yield call(userFailedSaga, result);
    }
  } catch (error) {
    yield call(userErrorSaga, error);
  }
}

function* userAnswerListSaga(action) {

  try {
    const result = yield call(
      axios.userAnswerList,
      action.data
    );
    if (result.status === 1) {
      yield put({
        type: types.API_USER_ANSWER_LISTING_SUCCESS,
        result: result.result.data,
      });
    } else if (result.status === 4) {
      yield call(authenticationFailedSaga, result, action);
    } else {
      toast.error(result?.error, { toastId: result?.status || "est" });
      yield put({
        type: types.API_PLAYER_ANSWER_LISTING_FAILED,
      });
    }
  } catch (error) {
    yield call(userErrorSaga, error);
  }
}

function* userUpdateSaga(action) {
  const { data } = action;
  try {
    const result = yield call(axios.userUpdate, data);
    if (result.status === 1) {
      yield put({
        type: types.API_USER_MANAGEMENT_UPDATE_SUCCESS,
        result: result.result.data,
      });
      action.navigate("/usermanagement");
      toast.success(result.result.data.message);
    } else if (result.status === 4) {
      yield call(authenticationFailedSaga, result, action);
    } else {
      yield call(userFailedSaga, result);
    }
  } catch (error) {
    yield call(userErrorSaga, error);
  }
}

function* userBlockSaga(action) {
  const { data, search } = action;
  try {
    const result = yield call(axios.userBlock, data);
    if (result.status === 1) {
      const resultStatus = yield call(
        axios.userListing,
        search,
        0,
        10,
        "",
        null
      );
      if (result.status === 1) {
        yield put({
          type: types.API_USER_MANAGEMENT_LISTING_SUCCESS,
          result: resultStatus.result.data,
        });
        yield put({
          type: types.API_REPORT_MANAGEMENT_LISTING_LOAD,
          // result: resultStatus.result.data,
        });
      }
      yield put({
        type: types.API_USER_MANAGEMENT_BLOCK_SUCCESS,
        result: result.result.data,
      });
      data.navigate('/reported-user-management')



      toast.success(result.result.data.message);
    } else if (result.status === 4) {
      yield call(authenticationFailedSaga, result, action);
    } else {
      yield call(userFailedSaga, result);
    }
  } catch (error) {
    yield call(userErrorSaga, error);
  }
}

function* userDeleteSaga(action) {
  const { id, search } = action;
  try {
    const result = yield call(axios.userDelete, id);
    if (result.status === 1) {
      const resultStatus = yield call(
        axios.userListing,
        search,
        0,
        10,
        "",
        null
      );
      if (result.status === 1) {
        yield put({
          type: types.API_USER_MANAGEMENT_LISTING_SUCCESS,
          result: resultStatus.result.data,
        });
      }
      yield put({
        type: types.API_USER_MANAGEMENT_DELETE_SUCCESS,
        result: result.result.data,
      });
      toast.success(result.result.data.message);
    } else if (result.status === 4) {
      yield call(authenticationFailedSaga, result, action);
    } else {
      yield call(userFailedSaga, result);
    }
  } catch (error) {
    yield call(userErrorSaga, error);
  }
}

// function* countryListingSaga(action) {
//   const { data } = action;
//   try {
//     const result = yield call(axios.countryListing, data);
//     if (result.status === 1) {
//       yield put({
//         type: types.API_COUNTRY_LISTING_SUCCESS,
//         result: result.result.data,
//       });
//     } else if (result.status === 4) {
//       yield call(authenicationUser, result, action.navigate);
//     } else {
//       yield call(userFailedSaga, result);
//     }
//   } catch (error) {
//     yield call(userErrorSaga, error);
//   }
// }

// function* cityListingSaga(action) {
//   const { id } = action;
//   try {
//     const result = yield call(axios.cityListing, id);
//     if (result.status === 1) {
//       yield put({
//         type: types.API_CITY_LISTING_SUCCESS,
//         result: result.result.data,
//       });
//     } else if (result.status === 4) {
//       yield call(authenicationUser, result, action.navigate);
//     } else {
//       yield call(userFailedSaga, result);
//     }
//   } catch (error) {
//     yield call(userErrorSaga, error);
//   }
// }

// function* employementListingSaga(action) {
//   try {
//     const result = yield call(axios.employementListing);
//     if (result.status === 1) {
//       yield put({
//         type: types.API_EMPLOYEMENT_LISTING_SUCCESS,
//         result: result.result.data,
//       });
//     } else if (result.status === 4) {
//       yield call(authenicationUser, result, action.navigate);
//     } else {
//       yield call(userFailedSaga, result);
//     }
//   } catch (error) {
//     yield call(userErrorSaga, error);
//   }
// }

// function* educationListingSaga(action) {
//   try {
//     const result = yield call(axios.educationListing);
//     if (result.status === 1) {
//       yield put({
//         type: types.API_EDUCATION_LISTING_SUCCESS,
//         result: result.result.data,
//       });
//     } else if (result.status === 4) {
//       yield call(authenicationUser, result, action.navigate);
//     } else {
//       yield call(userFailedSaga, result);
//     }
//   } catch (error) {
//     yield call(userErrorSaga, error);
//   }
// }

export default function* rootUserManagementSaga() {
  yield takeLatest(types.API_USER_MANAGEMENT_LISTING_LOAD, userListingSaga);
  yield takeLatest(types.API_USER_MANAGEMENT_DETAILS_LOAD, userDetailsSaga);
  yield takeLatest(types.API_USER_MANAGEMENT_UPDATE_LOAD, userUpdateSaga);
  yield takeLatest(types.API_USER_MANAGEMENT_BLOCK_LOAD, userBlockSaga);
  yield takeLatest(types.API_USER_MANAGEMENT_DELETE_LOAD, userDeleteSaga);
  yield takeLatest(types.API_USER_ANSWER_LISTING_LOAD, userAnswerListSaga);
  // yield takeLatest(types.API_COUNTRY_LISTING_LOAD, countryListingSaga);
  // yield takeLatest(types.API_CITY_LISTING_LOAD, cityListingSaga);
  // yield takeLatest(types.API_EMPLOYEMENT_LISTING_LOAD, employementListingSaga);
  // yield takeLatest(types.API_EDUCATION_LISTING_LOAD, educationListingSaga);
}
