import { apiConstants as types } from "../../themes/apiConstants";

// question listing Action
export const questionListingAction = (data, navigate) => (

  {
    type: types.API_QUESTION_MANAGEMENT_LISTING_LOAD,
    data,
    navigate
  });

// player listing listing Action
export const playerListingAction = (data) => (
  {
    type: types.API_PLAYER_ANSWER_LISTING_LOAD,
    data
  });

// question details
export const questionDetailsAction = (id, navigate) => ({
  type: types.API_QUESTION_MANAGEMENT_DETAILS_LOAD,
  id,
  navigate,
});

// question add
export const questionAddAction = (data, navigate, path) => (
  {
    type: types.API_QUESTION_MANAGEMENT_ADD_LOAD,
    data,
    navigate,
    path
  });

// question update
export const questionUpdateAction = (data, navigate, path) => (
  {
    type: types.API_QUESTION_MANAGEMENT_UPDATE_LOAD,
    data,
    navigate,
    path
  });

// question delete
export const questionDeleteAction = (ids, questionType, addToUnassigned, search, limit, navigate) => (
  {
    type: types.API_QUESTION_MANAGEMENT_DELETE_LOAD,
    ids,
    addToUnassigned,
    search,
    limit,
    questionType,
    navigate
  });

export const questionImportAction = (payload, questionType, isUnassigned) => ({
  type: types.API_QUESTION_MANAGEMENT_IMPORT_LOAD,
  payload,
  questionType,
  isUnassigned
})


export const questionExportAction = (payload) => ({
  type: types.API_QUESTION_MANAGEMENT_EXPORT_LOAD,
  payload
})