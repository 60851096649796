import { apiConstants as types } from "../../themes/apiConstants";

// category listing Action
export const suggestedQuestionListingAction = (data, navigate) => ({
  type: types.API_SUGGESTED_QUESTION_MANAGEMENT_LISTING_LOAD,
  data,
  navigate
});

// category delete
export const suggestedQuestionAcceptRejectAction = (data, navigate) => ({
  type: types.API_SUGGESTED_QUESTION_MANAGEMENT_ACCEPT_LOAD,
  data,
  navigate
});
// category delete
export const suggestedQuestionArchiveAction = (data, navigate) => ({
  type: types.API_SUGGESTED_QUESTION_MANAGEMENT_ARCHIVE_LOAD,
  data,
  navigate
});