import React, { useState, useEffect, useRef } from "react";
import { Button, Dropdown, Menu, Pagination, Spin, Tabs } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Table, Modal } from "antd";
import appconstant from "../../themes/appconstant";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { useSelector, useDispatch } from "react-redux";
import { questionDeleteAction, questionExportAction, questionImportAction } from "../../Redux/Action/questionManagementAction";
import { isInternetConnected } from "../../Utils/InternetConnection";
import { capitalFirstLetter, tableInitialData } from "../../Utils/helpers";
import LayoutHoc from "../../common/LayoutHoc";
import "./questionsManagement.css";
import { questionListingAction } from "../../Redux/Action/questionManagementAction";
import { ExclamationCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { CSVLink, CSVDownload } from "react-csv";
import { UploadFileOutlined } from "@mui/icons-material";
import images from "../../themes/appImage";

const DailyQuestion = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const questionListingData = useSelector((state) => state.questionManagementReducer);
  let { result, pagination, isLoading, exportResult, fileLoading } = questionListingData;
  const [payloadData, setPayload] = useState({ ...tableInitialData, isUnassigned: false })
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [currentDeleteRecord, setCurrentDeleteRecord] = useState(null)
  const [addToUnassigned, setAddToUnassigned] = useState(false)
  const [csvLabel, setCsvLabel] = useState(null)
  const [csvFile, setCsvFile] = useState(null)
  const [csvError, setCsvError] = useState("")
  const [disableDeleteBtn, setdisableDeleteBtn] = useState(false)
  const [showImportModal, setShowImportModal] = useState(false)


  // const [activeTab, setActiveTab] = useState(localStorage.isUnassigned == "true" ? "unassigend" : "assigend")
  const csvButtonRef = useRef()
  const fetchDataListing = () => {
    dispatch(questionListingAction({ ...payloadData, isUnassigned: false, type: "daily" }, navigate));
    exportResult = null
  };


  useEffect(() => {
    isInternetConnected()
      &&
      fetchDataListing()
  }, [payloadData]);

  useEffect(() => {
    window.scrollTo(0, 0)
    setShowImportModal(false)
    setCsvError("")
    setCsvFile('')
    setCsvLabel("")
  }, [result])

  // exportResult = null

  useEffect(() => {
    if (exportResult) {

      csvButtonRef.current.link.click()
    }
  }, [exportResult])



  const itemsT = [
    {
      key: '5',
      label: (
        "5"
      ),
    },
    {
      key: '10',
      label: (
        "10"
      ),
    },
    {
      key: '20',
      label: (
        "20"
      ),
    },
    {
      key: '50',
      label: (
        "50"
      ),
    },
    {
      key: '100',
      label: (
        "100"
      ),
    },
    {
      key: '1000',
      label: (
        "1000"
      ),
    },
  ];



  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",
      sorter: false,
      render: (text, record, index) => <div>{
        payloadData.offset + (index + 1)
      }</div>
      // index + 1 + (pagination?.nextPage - limit),
    },
    {
      title: "Category Image",
      dataIndex: "categoryImage",
      key: "categoryImage",
      sorter: false,
      render: (category, record) => (
        <div>
          {record?.category?.icon ? <img src={appconstant.imageUrl + record?.category?.icon} ></img> : 'N/A'}

        </div>
      ),
    },
    {
      title: "Category Name",
      dataIndex: "categoryName",
      key: "category.name",
      sorter: true,
      render: (categoryName, record) => (
        <div>
          {record?.category?.name || "N/A"}
        </div>
      ),
    },
    {
      title: "Questions",
      dataIndex: "qns",
      key: "question",
      sorter: true,
      render: (qns, record) => <div className="question-table-question-col">{record?.question ? record?.question : "N/A"}</div>,
    },
    {
      title: "Repetition Count",
      dataIndex: "repetition",
      key: "repetition_count",
      sorter: true,
      render: (repetition, record) => <div className="question-table-question-col">{record?.repetition_count > 0 ? record?.repetition_count : "0"}</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      className: "min-width-playTime",

      key: "status",
      sorter: false,
      render: (status, record) => <div className="question-table-question-col">{record?.activityStatus ? capitalFirstLetter(record?.activityStatus) : "N/A"}</div>,
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      align: 'center',
      render: (text, record) => {
        return (
          <div className="action-icon"
          >
            {/* view button */}
            <button

              onClick={() =>
                navigate("/edit-daily-questions", { state: record, navigate })
              }
            >
              {appconstant.edit}
            </button>
            {/* delete button */}
            <button
              onClick={() => {
                setCurrentDeleteRecord(record);
              }}
            // disabled={record?.questionCount <= 6}
            // style={{
            //   cursor: record.questionCount <= 6 ? 'not-allowed' : 'pointer',
            //   opacity: record.questionCount <= 6 ? 0.9 : 1,
            // }}
            >
              {appconstant.delete}
            </button>
          </div>
        );
      },
    },
  ];

  const onPaginationChange = (e) => {
    setPayload((state) => ({ ...state, offset: (e - 1) * payloadData.limit }))
  }

  const handleChange = async (pagination, filters, sorter) => {
    setPayload((state) => ({
      ...state,
      order: sorter.order === "ascend"
        ? 1
        : sorter.order === "descend"
          ? -1 : null,
      sortBy: sorter.order ? sorter.columnKey : ""
    }))
  };

  const confirmDelete = () => {

    isInternetConnected() && dispatch(questionDeleteAction(!Array.isArray(currentDeleteRecord) ? [currentDeleteRecord?._id] : [...currentDeleteRecord], 'daily', addToUnassigned, search, payloadData.limit, navigate));
    setCurrentDeleteRecord(null)
    setAddToUnassigned(false)
    setSelectedRowKeys([])
  }

  const uploadCsvFile = () => {
    const formData = new FormData()
    if (!csvFile) return setCsvError("Please select csv file to upload.")
    formData.append("csvFile", csvFile)
    formData.append("type", "daily")
    dispatch(questionImportAction(formData, 'daily', false))
  }


  return (
    <div>
      <BreadcrumbFn
        path={["dashboard"]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          appconstant.dailyQuestion,
        ]}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          background: "#fff",
          // padding: "10px 28px 0 25px",
          padding: "17px 28px 0px 25px",
          alignItems: "center",
        }}
      >
        <h6 class="text-white text-capitalize ps-3 pt-10" >
          {appconstant.dailyQuestion}
        </h6>
        <input
          type="text"
          className="iocn-search  search-bar"
          placeholder="Search"
          value={payloadData.search}
          onChange={(e) => {
            if (e.target.value.charAt() === " ") {
              return;
            }
            setPayload((state) => ({ ...state, search: e.target.value.trimStart() }));
          }
          }
        />
      </div>
      <LayoutHoc
        customClass={"userManagement"}
        child2={
          <>
            <Tabs className="table-tabs" onChange={(e) => {
              localStorage.setItem("isUnassigned", e === "unassigend");
              setPayload(prev => ({ ...prev, isUnassigned: e === "unassigend", offset: 0 }))
              setSelectedRowKeys([]);
            }}
            >

            </Tabs>
            <Table
              className="user-management-table"
              dataSource={result?.map(e => ({ ...e, key: e._id }))}
              loading={isLoading}
              columns={columns}
              showSorterTooltip={false}
              onChange={handleChange}
              pagination={false}
              rowSelection={{
                selectedRowKeys,
                onChange: (selectedRows, data) => {
                  // const aa = []
                  // selectedRows?.map(e => {
                  //   let bb = result.find(x => x._id === e && x.questionCount > 6)
                  //   bb && aa.push(bb)
                  // })
                  // aa.length === selectedRows.length ? setdisableDeleteBtn(false) : setdisableDeleteBtn(true)


                  setSelectedRowKeys(selectedRows);
                },
                preserveSelectedRowKeys: true,
              }}

            />
            <div className="table-pagination table-pagination-with-per-page">
              <Pagination
                current={pagination?.currentPage}
                onChange={onPaginationChange}
                total={pagination?.totalCount}
                showSizeChanger={false}
                pageSize={payloadData.limit}
              ></Pagination>


              <Dropdown overlay={<Menu items={itemsT} selectable={true} selectedKeys={[payloadData.limit?.toString() ?? ""]} onSelect={(e) => {
                setPayload(prev => ({ ...prev, limit: +e.key }))
              }} />}>
                <div className="button-para">
                  <div className="text-wrapper">Show result</div><Button className="pagination-limit-btn">{payloadData.limit}</Button>
                </div>
              </Dropdown>


            </div>
          </>
        }
      >
        <div className="flex">
          <Link to="/add-daily-questions">
            <button type="submit" className="addturna add-button">
              {appconstant.add}
            </button>
          </Link>
          <button type="submit" className="addturna add-button" onClick={() => {
            dispatch(questionExportAction({ limit: -1, isUnassigned: false, type: 'daily' }))
          }}>   {appconstant.export}
          </button>
          <button type="submit" onClick={() => {
            setShowImportModal(true)
          }} className="addturna add-button">
            {appconstant.import}
          </button>
          <CSVLink
            ref={csvButtonRef}
            data={
              [
                ["Category Name", "Questions"],
                ...exportResult?.map(e => [e.category?.name, e?.question]) || []
              ]
            }>
          </CSVLink>


          {!!selectedRowKeys.length &&
            <>

              <button type="submit" onClick={() => setCurrentDeleteRecord(selectedRowKeys)} disabled={disableDeleteBtn} style={disableDeleteBtn ? { cursor: 'not-allowed', opacity: 0.9 } : {}} className="addturna add-button delete-all-button">
                {appconstant.delete} ({selectedRowKeys.length})
              </button>
            </>
          }

        </div>
      </LayoutHoc >
      {
        showImportModal && <Modal wrapClassName="category-delete-modal" visible={true} closable={false}
          maskClosable={false}
          title={<span></span>}
          centered={true}
          cancelText="Close"
          okText={false}
          okButtonProps={{ style: { display: "none" } }}
          onOk={() => { alert("test") }}
          className="new-button"
          onCancel={() => {
            setShowImportModal(false)
            setCsvError('')
            setCsvLabel(null)
            setCsvFile(null)
          }}
        >
          <div className="category-delete-confirmation-container">
            <div
              style={{
                border: "1px solid #1b5449",
                width: "100%",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                padding: "8px 21px",
              }}
            >
              <label
                htmlFor="upload-audio"
                style={{
                  position: "relative",
                  maxWidth: "max-content",
                }}
              >
                <div
                  className="button-list addturna "
                  style={{
                    width: "100%",
                    padding: 0,
                    fontWeight: "600",
                    maxWidth: "min-content",
                    border: "0",
                    margin: "3px",
                    marginLeft: "0px",
                    color: "#fff",
                    padding: "10px 10px",
                    cursor: "pointer",
                    borderStyle: "solid",
                    backgroundColor: "#1b5449",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img src={images?.uploadImg} width={"20px"} />
                </div>
              </label>
              <input
                id="upload-audio"
                type={"file"}
                accept=".csv"
                style={{
                  width: "8%",
                  fontSize: "16px",
                  cursor: "pointer",
                  display: "none",
                }}
                onChange={(e) => {
                  console.log("files", e)
                  const files = e.target.files;
                  setCsvError("")
                  setCsvFile(files[0])
                  setCsvLabel(e.target.files[0].name)
                }}
              />
              {(
                <label
                  style={{
                    fontSize: "16px",
                    marginLeft: "8px",
                    maxWidth: "unset",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden"
                  }}
                >
                  {csvLabel ? csvLabel : 'Please upload the CSV.'}
                </label>
              )}
            </div>
            {csvError && <div className="error-category-um">{csvError}</div>}
            <div class="flex-center">
              <button className="addturna add-button" onClick={() => {
                uploadCsvFile()
              }}>
                {fileLoading ? <Spin style={{ display: "flex" }} /> : appconstant.upload}
              </button>
            </div>
          </div>
        </Modal >
      }
      <Modal wrapClassName="category-delete-modal" visible={!!currentDeleteRecord} closable={false}
        maskClosable={false}
        title={
          <div>
            <ExclamationCircleOutlined />
            {appconstant.delete}
          </div>
        }
        okText="Yes" centered={true} cancelText="No" onOk={confirmDelete} className="new-button"
        onCancel={() => {
          setCurrentDeleteRecord(null)
          setAddToUnassigned(false)
        }}
      >
        <div className="category-delete-confirmation-container">
          <p>{Array.isArray(currentDeleteRecord) ? appconstant.questionMultipleDeleteConfirmation : appconstant.questionDeleteConfirmation}</p>
          {!payloadData.isUnassigned}

        </div>
      </Modal>


    </div >
  );
};

export default DailyQuestion;
