import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from "antd";
import images from "../../themes/appImage";
import { Link, useNavigate } from "react-router-dom";
import appconstant from "../../themes/appconstant";
import { Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { dashboardAction, updateReferralAcion } from "../../Redux/Action/dashboardAction";
import { isInternetConnected } from "../../Utils/InternetConnection";
import TabContent from "./tabs";
import "./dashboard.css";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { useFormik } from "formik";
import * as Yup from "yup";

const { TabPane } = Tabs;

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [tabValue, setTabValue] = useState("1");
  localStorage.setItem("isUnassigned", false);


  const onChange = (key) => {
    setTabValue(key);
  };

  const referralValidationSchema = Yup.object().shape({
    referralAmount: Yup.string()
      .required('Please enter referral amount.')
      .test('not-starts-with-zero', 'Referral amount cannot start with zero.', value => {
        return !/^0/.test(value);
      })

  });

  const dashboardData = useSelector((state) => state.dashboardReducer.result);
  const { user, gamesPlayed, earning, referralAmount, onlinePlayers, onlineMatch } = dashboardData;

  useEffect(() => {
    isInternetConnected() && dispatch(dashboardAction(navigate));
  }, []);

  const removeSpace = (e) => e.key === ' ' ? e.preventDefault() : null

  const formik = useFormik({
    initialValues: { referralAmount },
    validationSchema: referralValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(updateReferralAcion(values))
    },
  });


  const userObj = [
    {
      imageSrc: images?.userTotal,
      title: "Online user count",
      count: onlinePlayers,
    },
    {
      imageSrc: images?.userTotal,
      title: "Total registered user count",
      count: user?.total,
    },
    {
      imageSrc: images?.userToday,
      title: "Total registered user count today",
      count: user?.today,
    },
    {
      imageSrc: images?.userWeek,
      title: "Total registered user count this week",
      count: user?.thisWeek,
    },
    {
      imageSrc: images?.userMonth,
      title: "Total registered user count this month",
      count: user?.thisMonth,
    },
    {
      imageSrc: images?.userQrter,
      title: "Total registered user count this quarter",
      count: user?.thisMonth,
    },
    {
      imageSrc: images?.userYear,
      title: "Total registered user count this year",
      count: user?.thisYear,
    },
  ];
  const gamesObj = [
    {
      imageSrc: images?.gamePlayed,
      title: "Current active games",
      count: onlineMatch,
    },
    {
      imageSrc: images?.gamePlayed,
      title: "Total games played",
      count: gamesPlayed?.total,
    },
    {
      imageSrc: images?.gameToday,
      title: "Total games played today",
      count: gamesPlayed?.today,
    },
    {
      imageSrc: images?.gameWeek,
      title: "Total games played this week",
      count: gamesPlayed?.thisWeek,
    },
    {
      imageSrc: images?.gameMonth,
      title: "Total games played this month",
      count: gamesPlayed?.thisMonth,
    },
    {
      imageSrc: images?.gameQrter,
      title: "Total games played this quarter",
      count: gamesPlayed?.thisMonth,
    },
    {
      imageSrc: images?.gameYear,
      title: "Total games played this year",
      count: gamesPlayed?.thisYear,
    },
  ];
  const earningObj = [
    {
      imageSrc: images?.earningTotal,
      title: "Total earning",
      count: earning?.total,
    },
    {
      imageSrc: images?.earningToday,
      title: "Total earning made today",
      count: earning?.today,
    },
    {
      imageSrc: images?.earningWeek,
      title: "Total earning made this week",
      count: earning?.thisWeek,
    },
    {
      imageSrc: images?.earningMonth,
      title: "Total earning made this month",
      count: earning?.thisMonth,
    },
    {
      imageSrc: images?.earningQrter,
      title: "Total earning made this quarter",
      count: earning?.thisMonth,
    },
    {
      imageSrc: images?.earningYear,
      title: "Total earning made this year",
      count: earning?.thisYear,
    },
  ];

  return (
    <div>
      <BreadcrumbFn
        path={[
          tabValue === "1" ? "Users" : tabValue === "2" ? "Games Played" : "Earning",
        ]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          tabValue === "1" ? "Users" : tabValue === "2" ? "Games Played" : "Earning",
        ]}
      />

      <Tabs defaultActiveKey="1" onChange={onChange}>

        <TabPane tab="Users" key="1" disabled={tabValue === "1"}>

          <div className="referral-div">
            <form className="referral-form" onSubmit={formik.handleSubmit}>
              <h3 className="referral-heading">{appconstant.referralAmount}</h3>

              <div className="input-referral">
                <input
                  onKeyDown={removeSpace}
                  className="referral-input"
                  name="referralAmount"
                  type="text"
                  placeholder="Referral Amount"
                  maxLength={4}
                  onChange={(e) => {
                    if (e.target.value.startsWith(' ') || ! /^[0-9]*$/.test(e.target.value)) e.preventDefault()
                    else formik.handleChange(e)
                  }}
                  value={formik.values.referralAmount}
                />
                <div className=" referral-error">{formik.errors.referralAmount || ""}</div>
              </div>
              <button className="referral-submit">Update</button>
            </form>
          </div>
          <TabContent
            mapItems={userObj}
            customClass={"user-tab"}
            smallImg={true}
            tabContentTitle={appconstant.users}
          />

        </TabPane >

        <TabPane tab="Games Played" key="2" disabled={tabValue === "2"}>
          <TabContent
            mapItems={gamesObj}
            customClass={"user-tab"}
            tabContentTitle={appconstant.gamesPlayed}
          />

        </TabPane>
        {/* <TabPane tab="Earning" key="3" disabled={tabValue === "3"}>
          <TabContent
            mapItems={earningObj}
            customClass={"user-tab"}
            tabContentTitle={appconstant.earning}
          />
        </TabPane> */}
      </Tabs >

    </div >
  );
};

export default Dashboard;
