import { takeLatest, call, put } from "redux-saga/effects";
import { apiConstants as types, appMessages } from "../../themes/apiConstants";
import axios from "../api";
import { toast } from "react-toastify";
import {
  authenticationErrorSaga,
  authenticationFailedSaga,
} from "./authenticationSaga";

function* reportFailedSaga(result) {
  console.log('reportFailedSaga')
  yield put({
    type: types.API_REPORT_MANAGEMENT_FAILED,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

function* reportErrorSaga(result) {
  console.log('reportErrorSaga')
  yield put({
    type: types.API_REPORT_MANAGEMENT_ERROR,
  });
  if (result.status === 2) {
    toast.error(appMessages.messageStatus500);
  } else {
    toast.error(result?.error, { toastId: result?.status || "est" });
  }
}

function* reportListingSaga(action) {
  try {
    const result = yield call(
      axios.reportListing,
      action.data
    );
    if (result.status === 1) {
      yield put({
        type: types.API_REPORT_MANAGEMENT_LISTING_SUCCESS,
        result: result.result.data,
      });
    } else if (result.status === 4) {
      yield call(authenticationFailedSaga, result, action);
    } else {
      yield call(reportFailedSaga, result);
    }
  } catch (error) {
    yield call(reportErrorSaga, error);
  }
}

function* reportAppealSaga(action) {
  try {
    const result = yield call(
      axios.reportAppeal,
      action.id
    );
    if (result.status === 1) {
      yield put({
        type: types.API_REPORT_MANAGEMENT_DETAILS_SUCCESS,
        result: result.result.data,
      });
    } else if (result.status === 4) {
      yield call(authenticationFailedSaga, result, action);
    } else {
      yield call(reportFailedSaga, result);
    }
  } catch (error) {
    yield call(reportErrorSaga, error);
  }
}

export default function* rootReportManagementSaga() {
  yield takeLatest(types.API_REPORT_MANAGEMENT_LISTING_LOAD, reportListingSaga);
  yield takeLatest(types.API_REPORT_MANAGEMENT_DETAILS_LOAD, reportAppealSaga);
}
