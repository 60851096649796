import { apiConstants as types } from "../../themes/apiConstants";
const initialState = {
  isLoading: false,
  result: [],
  errorState: null,
  pagination: {},
  userDetails: {},
  deleteData: false,
  countriesArray: [],
  citiesArray: [],
  educationArray: [],
  employementArray: [],
  citiesFirstIndex: null,
};

export const userManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    //user listing
    case types.API_USER_MANAGEMENT_LISTING_LOAD:
      return { ...state, isLoading: true, errorState: null };
    case types.API_USER_MANAGEMENT_LISTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        result: action.result.data.list,
        pagination: action.result.data.pagination,
      };

    //user details
    case types.API_USER_MANAGEMENT_DETAILS_LOAD:
      return { ...state, isLoading: true, errorState: null };
    case types.API_USER_MANAGEMENT_DETAILS_SUCCESS:
      return { ...state, isLoading: false, userDetails: action.result.data };

    //user update
    case types.API_USER_MANAGEMENT_UPDATE_LOAD:
      return { ...state, isLoading: true, errorState: null };
    case types.API_USER_MANAGEMENT_UPDATE_SUCCESS:
      return { ...state, isLoading: false };

    //user block
    case types.API_USER_MANAGEMENT_BLOCK_LOAD:
      return { ...state, isLoading: true, errorState: null };
    case types.API_USER_MANAGEMENT_BLOCK_SUCCESS:
      return { ...state, isLoading: false };

    //user delete
    case types.API_USER_MANAGEMENT_DELETE_LOAD:
      return { ...state, isLoading: true, errorState: null };
    case types.API_USER_MANAGEMENT_DELETE_SUCCESS:
      return { ...state, isLoading: false };

    //COUNTRY LISTING
    case types.API_COUNTRY_LISTING_LOAD:
        return { ...state, isLoading: true, errorState: null }
    case types.API_COUNTRY_LISTING_SUCCESS:
        return { ...state, isLoading: false, countriesArray: action.result.data }

    //city LISTING
    case types.API_CITY_LISTING_LOAD:
        return { ...state, isLoading: true, errorState: null }
    case types.API_CITY_LISTING_SUCCESS:
        return {
            ...state, isLoading: false, citiesArray: action.result.data,
            citiesFirstIndex: action.result.data[0]._id
        }

    //education LISTING
    case types.API_EDUCATION_LISTING_LOAD:
        return { ...state, isLoading: true, errorState: null }
    case types.API_EDUCATION_LISTING_SUCCESS:
        return { ...state, isLoading: false, educationArray: action.result.data }

    //employement LISTING
    case types.API_EMPLOYEMENT_LISTING_LOAD:
        return { ...state, isLoading: true, errorState: null }
    case types.API_EMPLOYEMENT_LISTING_SUCCESS:
        return { ...state, isLoading: false, employementArray: action.result.data }

    case types.API_CLEAR_DETAILS_LOAD:
        return { ...state, userDetails: {} }

    //user Failed Case
    case types.API_USER_MANAGEMENT_FAILED:
      return { ...state, isLoading: false };
    case types.API_USER_MANAGEMENT_ERROR:
      return { ...state, isLoading: false };

    //Default case
    default:
      return { ...state };
  }
};
