import React, { useState, useEffect } from "react";
import { Pagination, Row, Tabs } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Table, Modal } from "antd";
import appconstant from "../../themes/appconstant";
import images from "../../themes/appImage";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { useSelector, useDispatch } from "react-redux";
import { isInternetConnected } from "../../Utils/InternetConnection";
import { tableInitialData } from "../../Utils/helpers";
import LayoutHoc from "../../common/LayoutHoc";
import "./categoryManagement.css";
import {
  suggestedCategoryListingAction,
  suggestedCategoryAcceptRejectAction,
  suggestedCategoryArchiveAction,
} from "../../Redux/Action/suggestedCategoryManagementAction ";
import { confirmationModalAcceptReject } from "../../common/modal";

const SuggestedCategoryManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [payloadData, setPayload] = useState(tableInitialData);
  const userListingData = useSelector(
    (state) => state.suggestedCategoryManagementReducer
  );
  const { result, pagination, isLoading } = userListingData;

  const fetchDataListing = () => {
    dispatch(suggestedCategoryListingAction(payloadData, navigate));
  };

  localStorage.setItem("isUnassigned", false);


  useEffect(() => {
    isInternetConnected() && fetchDataListing();
  }, [payloadData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [result]);


  const archiveConfirmModal = (id) => {
    Modal.confirm({
      title: appconstant.archive,
      content: appconstant.categoryArchiveConfirmation,
      okText: "Yes",
      centered: true,
      cancelText: "No",
      onOk() {
        isInternetConnected() && dispatch(suggestedCategoryArchiveAction({ id, type: 'category' }, navigate))
      },
      onCancel() { },
      className: "new-button",
    });
  }


  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",
      sorter: false,
      render: (text, record, index) =>
        index + 1 + (pagination?.nextPage - payloadData.limit),
    },
    {
      title: "Category Name",
      dataIndex: "category",
      key: "category",
      sorter: true,
      render: (category, record) => <div>{category ? category : "N/A"}</div>,
    },
    {
      title: "Suggested By",
      dataIndex: "suggestedBy",
      key: "user.userName",
      sorter: true,
      render: (dsa, record) => (
        <div>{record?.user?.userName ? record?.user?.userName : "N/A"}</div>
      ),
    },
    {
      title: "Upvotes",
      dataIndex: "upVotes",
      key: "upVotes",
      sorter: true,
      render: (dsa, record) => (
        <div>
          {record?.upVotes}
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "action",
      align: 'center',
      key: "action",
      render: (text, record) => {
        return (
          <div className="action-icon">
            {record.isAccepted ? (
              <button
                onClick={() => {
                  archiveConfirmModal(record?._id);
                }}
              >
                {appconstant.archive}
              </button>
            ) : (
              <>
                <button
                  onClick={() => {
                    confirmationModalAcceptReject(
                      () =>
                        isInternetConnected() &&
                        dispatch(
                          suggestedCategoryAcceptRejectAction(
                            { categoryId: record?._id, status: "accepted" },
                            navigate("/suggested-category-management")
                          )
                        ),
                      true,
                      "category"
                    );
                  }}
                >
                  {appconstant.accept}
                </button>
                <button
                  onClick={() => {
                    confirmationModalAcceptReject(
                      () =>
                        isInternetConnected() &&
                        dispatch(
                          suggestedCategoryAcceptRejectAction(
                            { categoryId: record?._id, status: "rejected" },
                            navigate("/suggested-category-management")
                          )
                        ),
                      false,
                      "category"
                    );
                  }}
                >
                  {appconstant.reject}
                </button>
              </>
            )}
          </div>
        );
      },
    },
  ];

  const onPaginationChange = (e) => {
    setPayload((state) => ({ ...state, offset: (e - 1) * 10 }));
  };

  const handleChange = async (pagination, filters, sorter) => {
    setPayload((state) => ({
      ...state,
      order:
        sorter.order === "ascend" ? 1 : sorter.order === "descend" ? -1 : null,
      sortBy: sorter.order ? sorter.columnKey : "",
    }));
  };

  return (
    <div>
      <BreadcrumbFn
        path={["dashboard"]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          appconstant.suggestedCategoryMngmnt,
        ]}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          background: "#fff",
          padding: "17px 28px 0px 25px",
          alignItems: "center",
        }}
      >
        <h6 class="text-white text-capitalize ps-3 pt-10">
          {appconstant.suggestedCategoryMngmnt}
        </h6>
        <input
          type="text"
          className="iocn-search  search-bar"
          placeholder="Search"
          value={payloadData.search}
          onChange={(e) => {
            if (e.target.value.charAt() === " ") {
              return;
            }
            setPayload((state) => ({
              ...state,
              search: e.target.value.trimStart(),
            }));
          }}
        />
      </div>
      <LayoutHoc
        customClass={"userManagement"}
        child2={
          <>
            <Tabs
              className="table-tabs"
              onChange={(e) => {
                setPayload((prev) => ({ ...prev, archived: e === "archived", offset: 0 }));
              }}
            >
              <Tabs.TabPane tab="All" key="all"></Tabs.TabPane>
              <Tabs.TabPane tab="Archived" key="archived"></Tabs.TabPane>
            </Tabs>
            <Table
              className="user-management-table"
              dataSource={result}
              loading={isLoading}
              columns={columns}
              showSorterTooltip={false}
              onChange={handleChange}
              pagination={false}
            />
            <div className="table-pagination">
              <Pagination
                current={pagination?.currentPage}
                onChange={onPaginationChange}
                total={pagination?.totalCount}
                showSizeChanger={false}
              ></Pagination>
            </div>
          </>
        }
      ></LayoutHoc>
    </div>
  );
};

export default SuggestedCategoryManagement;
