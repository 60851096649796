import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useLocation } from "react-router-dom";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import appconstant from "../../themes/appconstant";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import InputField from "../../common/DisableInputField";
import { useSelector, useDispatch } from "react-redux";
import images from "../../themes/appImage";
import { Button, Dropdown, Image, Menu, Pagination, Spin, Table } from "antd";
import LayoutHoc from "../../common/LayoutHoc";
import "./user.css";
import { userAnswerListingAction, userDetailsAction } from "../../Redux/Action/userManagementAction";
import { isInternetConnected } from "../../Utils/InternetConnection";
import { Img } from "../../common/Img";
import { capitalFirstLetter, convertSecondsToHoursMinutesSeconds, tableInitialData } from "../../Utils/helpers";
import { Tooltip } from "antd";

const UserDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const userDetailsData = useSelector((state) => state.userManagementReducer);
  const { userDetails, isLoading } = userDetailsData;

  const currentVideoRef = useRef(null);
  const currentAudioRef = useRef(null);

  const handleVideoPlay = (event) => {
    if (currentVideoRef.current && currentVideoRef.current !== event.target) {
      currentVideoRef.current.pause();
    }
    currentVideoRef.current = event.target;
  };

  const handleAudioPlay = (event) => {
    if (currentAudioRef.current && currentAudioRef.current !== event.target) {
      currentAudioRef.current.pause();
    }
    currentAudioRef.current = event.target;
  };

  useEffect(() => {
    if (state?.id?._id) {
      isInternetConnected() &&
        dispatch(userDetailsAction(state?.id?._id, navigate));
    } else {
      navigate("/usermanagement");
    }
  }, []);

  const playerAnswerList = useSelector((state) => state.playerAnswerReducer);
  let { result, pagination, fileLoading } = playerAnswerList;
  const [payloadData, setPayload] = useState({ ...tableInitialData })

  const fetchDataListing = () => {
    dispatch(userAnswerListingAction({ _id: state?.id?._id, ...payloadData }))
  };


  useEffect(() => {
    isInternetConnected()
      &&
      fetchDataListing()
  }, [payloadData]);


  const itemsT = [
    {
      key: '5',
      label: (
        "5"
      ),
    },
    {
      key: '10',
      label: (
        "10"
      ),
    },
    {
      key: '20',
      label: (
        "20"
      ),
    },
    {
      key: '50',
      label: (
        "50"
      ),
    },
  ];

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",
      sorter: false,
      render: (text, record, index) => <div>{
        payloadData.offset + (index + 1)
      }</div>
    },
    {
      title: "Category Image",
      dataIndex: "categoryImage",
      key: "categoryImage",
      sorter: false,
      render: (category, record) => (
        < div >
          {record?.questions?.questionCategory?.icon ? <img src={appconstant.imageUrl + record?.questions?.questionCategory?.icon} ></img> : 'N/A'}

        </div >
      ),
    },
    {
      title: "Category Name",
      dataIndex: "categoryName",
      key: "name",
      sorter: true,
      render: (categoryName, record) => (
        <div>
          {record?.questions?.questionCategory?.name || "N/A"}
        </div>
      ),
    },
    {
      title: "Questions",
      dataIndex: "qns",
      key: "question",
      sorter: true,
      render: (qns, record) => <div className="question-table-question-col">{record?.questions?.question ? record?.questions?.question : "N/A"}</div>,
    },
    {
      title: "Player",
      dataIndex: "player",
      key: "userName",
      sorter: false,
      render: (player, record) => <div className="hover-div question-table-question-col ">
        {record?.userPlayWith ? (
          <Tooltip title={record?.userPlayWith?.playWith.map(x => x.userName).join(', ')}>
            {record?.userPlayWith?.playWith.map(x => x.userName).join(', ')}
          </Tooltip>
        ) : (
          "N/A"
        )}
      </div>,
    },
    {
      title: "Answer Type",
      dataIndex: "ansType",
      key: "ansType",
      sorter: false,
      render: (ansType, record) => <div className="question-table-question-col">{record?.type ? capitalFirstLetter(record?.type) : "N/A"}</div>,
    },
    {
      title: "Answer",
      dataIndex: "answer",
      key: "answer",
      sorter: false,
      render: (answer, record) => (
        <div className="question-table-question-col">
          {record?.file ? (
            record?.type === 'audio' ? (
              <audio controls onPlay={handleAudioPlay}>
                <source src={appconstant.imageUrl + record.file} type="audio/mp3" />
                Your browser does not support the audio tag.
              </audio>
            ) : (
              <video width="200" height="150" controls onPlay={handleVideoPlay}>
                <source src={appconstant.imageUrl + record.file} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )
          ) : (
            'N/A'
          )}

        </div>
      )
    },
  ];

  const onPaginationChange = (e) => {
    setPayload((state) => ({ ...state, offset: (e - 1) * payloadData.limit }))
  }

  const handleChange = async (pagination, filters, sorter) => {
    setPayload((state) => ({
      ...state,
      order: sorter.order === "ascend"
        ? 1
        : sorter.order === "descend"
          ? -1 : null,
      sortBy: sorter.order ? sorter.columnKey : ""
    }))
  };

  const userFields = [
    {
      label: appconstant.UserFullName,
      value: userDetails?.userName ?? "N/A",
    },
    {
      label: appconstant.name,
      value: userDetails?.userName ?? "N/A",
    },
    {
      label: appconstant.Country,
      value: userDetails?.country ?? "N/A",
    },
    {
      label: appconstant.EmailAddress,
      value: userDetails?.email ?? "N/A",
    },
    {
      label: appconstant.playTime,
      value: userDetails?.playTime >= 0 ?
        `${convertSecondsToHoursMinutesSeconds(userDetails.playTime).hours} h , 
      ${convertSecondsToHoursMinutesSeconds(userDetails.playTime).minutes} m , 
      ${convertSecondsToHoursMinutesSeconds(userDetails.playTime).seconds} s`
        : "N/A",
    }, {
      label: appconstant.signUpDate,
      value: userDetails?.createdAt ? new Date(userDetails.createdAt).toLocaleDateString('en-AU', {
        day: '2-digit',
        month: 'long',
        year: 'numeric'
      }) : "N/A",
    }
  ];
  return (
    <Spin spinning={isLoading}>
      <div>
        <BreadcrumbFn
          path={["dashboard", "usermanagement"]}
          pathName={[
            <FontAwesomeIcon icon={faHome} />,
            appconstant.UserManagement,
            appconstant.userDetails,
          ]}
        />
        <div
          class="bg-gradient-primary shadow-primary border-radius-lg pt-4 "
          style={{
            display: "flex",
            justifyContent: "space-between",
            background: "#fff",
            padding: "17px 28px 0px 25px",
            // padding: "10px 28px 0 25px",
            alignItems: "center",
          }}>
          <h6 class="text-white text-capitalize ps-3">{"User Details"}</h6>
        </div>
        <LayoutHoc customClass={"no-padding-margin"}>
          <div
            className="wrapper_line view_modules view"
            style={{
              width: "100%",
            }}>
            <div className="userimgWrapper">
              <Img url={
                userDetails?.profilePicture && userDetails.profilePicture.includes('http')
                  ? userDetails?.profilePicture
                  : userDetails?.profilePicture
                    ? appconstant.imageUrl + userDetails?.profilePicture
                    : images.blankImage
              } className="Img-custom" />
            </div>
            <div>
              {userFields.map((item, index) => {
                return (
                  <InputField
                    label={item?.label}
                    style={{
                      margin: "0.5em 0 1em",
                    }}
                    value={item?.value}
                  />
                );
              })}
            </div>
          </div>
        </LayoutHoc>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          background: "#fff",
          // padding: "10px 28px 0 25px",
          padding: "17px 28px 0px 25px",
          alignItems: "center",
        }}
      >
        <h6 class="text-white text-capitalize ps-3 pt-10" >
          {appconstant.userAnswer}
        </h6>
        <input
          type="text"
          className="iocn-search  search-bar"
          placeholder="Search"
          value={payloadData.search}
          onChange={(e) => {
            if (e.target.value.charAt() === " ") {
              return;
            }
            setPayload((state) => ({ ...state, search: e.target.value.trimStart() }));
          }
          }
        />
      </div>
      <LayoutHoc
        customClass={"userManagement"}
        child2={
          <>
            <Table
              className="user-management-table for-player-answer"
              dataSource={result?.map(e => ({ ...e, key: e._id }))}
              loading={playerAnswerList?.isLoading}
              columns={columns}
              showSorterTooltip={false}
              onChange={handleChange}
              pagination={false}


            />
            <div className="table-pagination ">
              <Pagination
                current={pagination?.currentPage}
                onChange={onPaginationChange}
                total={pagination?.totalCount}
                showSizeChanger={false}
                pageSize={payloadData.limit}
              ></Pagination>

            </div>
          </>
        }
      >
      </LayoutHoc >
    </Spin>




  );
};

export default UserDetails;
