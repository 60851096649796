import React, { useState, useEffect } from "react";
import { Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Table, Modal } from "antd";
import appconstant from "../../themes/appconstant";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { useSelector, useDispatch } from "react-redux";
import {
  userListingAction,
} from "../../Redux/Action/userManagementAction";
import { handlePaginationChange, handleSearching, tableInitialData } from "../../Utils/helpers";
import LayoutHoc from "../../common/LayoutHoc";
import "./user.css";

const PurchaseHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [payloadData, setPayload] = useState(tableInitialData)
  const userListingData = useSelector((state) => state.userManagementReducer);
  const { result, pagination, isLoading } = userListingData;

  const fetchDataListing = (search, offset, limit, sortBy, order) => {
    // dispatch(userListingAction(search, offset, limit, sortBy, order, navigate));
  };

  useEffect(() => {
    // isInternetConnected() &&
    //   fetchDataListing(search, offset, limit, sortBy, order, navigate);
    // dispatch(clearUserDetails());
  }, [payloadData]);

  const dataForTable = [
    {
      dateTime: "13/04/2000 12:30 AM",
      amount: "50",
      pt: "Annual"
    },
    {
      dateTime: "14/04/2000 12:30 AM",
      amount: "52",
      pt: "Monthly"
    },
  ];
  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",
      sorter: false,
      render: (text, record, index) => index + 1,
      // index + 1 + (pagination?.nextPage - limit),
    },
    {
      title: "Package Type",
      dataIndex: "pt",
      key: "pt",
      sorter: true,
      render: (pt, record) => pt
      ,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      sorter: true,
      render: (amount, record) => (
        <div>
          {amount}
        </div>
      ),
    },
    {
      title: "Date & Time",
      dataIndex: "dateTime",
      key: "dateTime",
      sorter: true,
      render: (dateTime, record) => <div>{dateTime ? dateTime : "N/A"}</div>,
    },
  ];

  const handleChange = async (pagination, filters, sorter) => {
    setPayload((state) => ({
      ...state,
      order: sorter.order === "ascend"
        ? 1
        : sorter.order === "descend"
          ? -1 : null,
      sortBy: sorter.order ? sorter.columnKey : ""
    }))
  };

  const onPaginationChange = (e) => {
    setPayload((state) => ({ ...state, offset: (e - 1) * 10 }))
  }

  return (
    <div>
      <BreadcrumbFn
        path={["dashboard", "usermanagement", "userdetails"]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          appconstant.UsersManagement,
          appconstant.userDetails,
          appconstant.purchaseHist,
        ]}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          background: "#fff",
          padding: "17px 28px 0px 25px",
          alignItems: "center",
        }}
      >
        <h6 class="text-white text-capitalize ps-3 pt-10">
          {appconstant.purchaseHist}
        </h6>
        <input
          type="text"
          className="iocn-search  search-bar"
          placeholder="Search"
          value={payloadData.search}
          onChange={(e) => {
            setPayload((state) => ({ ...state, search: e.target.value.trimStart() }));
          }
          }
        />
      </div>
      <LayoutHoc
        customClass={"userManagement"}
        child2={
          <>
            <Table
              className="user-management-table purchase-hist-table"
              // dataSource={result}
              dataSource={dataForTable}
              loading={isLoading}
              columns={columns}
              showSorterTooltip={false}
              onChange={handleChange}
              pagination={false}
            />
            <div className="table-pagination">
              <Pagination
                current={pagination?.currentPage}
                onChange={onPaginationChange}
                total={pagination?.totalCount}
                showSizeChanger={false}
              ></Pagination>
            </div>
          </>
        }
      >
        <div></div>

      </LayoutHoc>
    </div>
  );
};

export default PurchaseHistory;
