import { apiConstants as types } from '../../themes/apiConstants'
const initialState = {
    isLoading: false,
    result: [],
    errorState: null,
    pagination: {},
    deleteData: false,
    reportDetails: {}
}

export const reportManagementReducer = (state = initialState, action) => {
    switch (action.type) {

        //report listing
        case types.API_REPORT_MANAGEMENT_LISTING_LOAD:
            return { ...state, isLoading: true, errorState: null }
        case types.API_REPORT_MANAGEMENT_LISTING_SUCCESS:
            return { ...state, isLoading: false, result: action.result.data.list, pagination: action.result.data.pagination }

        //report details
        case types.API_REPORT_MANAGEMENT_DETAILS_LOAD:
            return { ...state, isLoading: true, errorState: null }
        case types.API_REPORT_MANAGEMENT_DETAILS_SUCCESS:
            return { ...state, isLoading: false, reportDetails: action.result.data }

        //user report  block
        case types.API_USER_REPORT_MANAGEMENT_BLOCK_LOAD:
            return { ...state, isLoading: true, errorState: null }
        case types.API_USER_REPORT_MANAGEMENT_BLOCK_SUCCESS:
            return { ...state, isLoading: false, }

        //poll block
        case types.API_POLL_REPORT_MANAGEMENT_BLOCK_LOAD:
            return { ...state, isLoading: true, errorState: null }
        case types.API_POLL_REPORT_MANAGEMENT_BLOCK_SUCCESS:
            return { ...state, isLoading: false, }

        //poll option delete
        case types.API_POLL_REPORT_MANAGEMENT_DELETE_LOAD:
            return { ...state, isLoading: true, errorState: null }
        case types.API_POLL_REPORT_MANAGEMENT_DELETE_SUCCESS:
            return { ...state, isLoading: false, }

        //report Failed Case
        case types.API_REPORT_MANAGEMENT_FAILED:
            return { ...state, isLoading: false }
        case types.API_REPORT_MANAGEMENT_ERROR:
            return { ...state, isLoading: false }

        //Default case
        default:
            return { ...state }
    }
}