import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import appconstant from "../../themes/appconstant";
import { Select, Spin } from "antd";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import Textfiled1 from "../../common/Textfiled";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { ValidationConstant } from "../../themes/validationConstant";
import { isInternetConnected } from "../../Utils/InternetConnection";
import ImageUpload from "../../common/imageUpload";
import {
  cityListingAction,
  countryListingAction,
  educationListingAction,
  employementListingAction,
  userDetailsAction,
  userUpdateAction,
} from "../../Redux/Action/userManagementAction";
import { handleImageChange } from "../../Utils/helpers";

const { Option } = Select;

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .test(
      "trim",
      ValidationConstant.firstName.empty,
      (value) => value?.trim()?.length > 0
    )
    .test(
      "trim",
      ValidationConstant.firstName.min,
      (value) => value?.trim()?.length > 1
    )
    .matches(/^[a-zA-Z ]*$/, ValidationConstant.firstName.valid)
    .min(2, ValidationConstant.firstName.min)
    .required(ValidationConstant.firstName.empty)
    .nullable(),
  lastName: Yup.string()
    .test(
      "trim",
      ValidationConstant.lastName.empty,
      (value) => value?.trim()?.length > 0
    )
    .test(
      "trim",
      ValidationConstant.lastName.min,
      (value) => value?.trim()?.length > 1
    )
    .matches(/^[a-zA-Z ]*$/, ValidationConstant.lastName.valid)
    .min(2, ValidationConstant.lastName.min)
    .required(ValidationConstant.lastName.empty)
    .nullable(),
});

const EditUserDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const userDetailsData = useSelector((state) => state.userManagementReducer);
  const {
    userDetails,
    isLoading,
    citiesArray,
    countriesArray,
    educationArray,
    employementArray,
  } = userDetailsData;
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [bio, setBio] = useState("");
  const [gender, setGender] = useState("");
  const [education, setEducation] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [employed, setEmployed] = useState("");

  const initialValues = {
    firstName,
    lastName,
  };

  useEffect(() => {
    let url = appconstant.imageUrl + userDetails?.profile;
    setImage(url);
    setImageUrl(userDetails?.profile);
    if (userDetails?.education) {
      setEducation(userDetails?.education?._id);
    }
    setCountry(userDetails?.country?._id);
    setGender(userDetails?.gender);
    if (userDetails?.employed) {
      setEmployed(userDetails?.employed?._id);
    }
    if (userDetails?.city) {
      setCity(userDetails?.city?._id);
    }
    if (userDetails?.bio) {
      setBio(userDetails?.bio);
    }
    formik.setFieldValue("firstName", userDetails?.firstName, false);
    formik.setFieldValue("lastName", userDetails?.lastName, false);
    // formik.setFieldValue("bio", userDetails?.bio, false)
    if (userDetails?.country?._id) {
      // dispatch(cityListingAction(userDetails?.country?._id, navigate))
    }
  }, [userDetails]);

  useEffect(() => {
    document.title = appconstant.MANIFEST_MOTO;
    window.scrollTo(0, 0);
    if (state?.id?._id) {
      isInternetConnected() &&
        dispatch(userDetailsAction(state?.id?._id, navigate));
    } else {
      navigate("/usermanagement");
    }
    if (isInternetConnected()) {
      dispatch(countryListingAction(navigate));
      dispatch(educationListingAction(navigate));
      dispatch(employementListingAction(navigate));
    }
  }, []);

  const onSubmit = (values, { setSubmitting }) => {
    let data = {
      id: state?.id?._id,
      firstName: values.firstName,
      lastName: values.lastName,
      gender: gender,
      education: education,
      country: country,
      city: city,
      employed: employed,
      bio: bio,
      image: imageUrl,
    };
    setSubmitting(false);
    isInternetConnected() && dispatch(userUpdateAction(data, navigate));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <Spin spinning={isLoading}>
      <div>
        <BreadcrumbFn
          path={["dashboard", "usermanagement"]}
          pathName={[
            <FontAwesomeIcon icon={faHome} />,
            appconstant.UserManagement,
            appconstant.edituserDetails,
          ]}
        />
        <div
          className="site-layout-background"
          style={{ padding: 24, minHeight: 360 }}>
          <div className="content-e">
            <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
              <h6 class="text-white text-capitalize ps-3">
                {appconstant.edituserDetails}
              </h6>
            </div>
            <form noValidate onSubmit={formik.handleSubmit}>
              <ImageUpload
                image={image}
                onChange={(e) =>
                  handleImageChange(e, setImage, setImageUrl)
                }></ImageUpload>
              <div className="wrapper_line view_modules view ">
                <Textfiled1
                  label={appconstant.firstName}
                  placeholder="First Name"
                  id="firstName"
                  name="firstName"
                  value={formik.values.firstName?.trimStart()}
                  onChange={formik.handleChange}
                  maxLength={18}
                />
                {formik.errors.firstName ? (
                  <div className="error1">{formik.errors.firstName}</div>
                ) : null}
                <Textfiled1
                  label={appconstant.LastName}
                  placeholder="Last Name "
                  id="lastName"
                  name="lastName"
                  value={formik.values.lastName?.trimStart()}
                  onChange={formik.handleChange}
                  maxLength={18}
                />
                {formik.errors.lastName ? (
                  <div className="error1">{formik.errors.lastName}</div>
                ) : null}
                <Textfiled1
                  label={appconstant.EmailAddress}
                  value={userDetails?.email}
                  disabled={true}
                  placeholder="Email Address"
                />
                <Textfiled1
                  label={appconstant.MobileNumber}
                  value={userDetails?.countryCode + userDetails?.phone}
                  disabled={true}
                  placeholder={appconstant.MobileNumber}
                />
                <div className="form-group">
                  <label>{appconstant.Gender}</label>
                  <Select value={gender} onChange={(e) => setGender(e)}>
                    <Option value="Male">{appconstant.male}</Option>
                    <Option value="Female">{appconstant.Female}</Option>
                    <Option value="LGBTQ+">{appconstant.LGBTQ}</Option>
                  </Select>
                </div>
                <div className="form-group">
                  <label>{appconstant.Education}</label>
                  <Select value={education} onChange={(e) => setEducation(e)}>
                    {education?.length <= 0 && (
                      <Option value={""}>{appconstant.SelectEducation}</Option>
                    )}
                    {educationArray?.map((item, index) => {
                      return <Option value={item?._id}>{item?.name}</Option>;
                    })}
                  </Select>
                </div>
                <div className="form-group">
                  <label>{appconstant.Country}</label>
                  <Select
                    value={country}
                    onChange={(e, j) => {
                      // dispatch(cityListingAction(j?.key, navigate))
                      setCountry(e);
                    }}>
                    {countriesArray?.map((item, index) => {
                      return (
                        <Option key={item?._id} value={item?._id}>
                          {item?.name}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
                <div className="form-group">
                  <label>{appconstant.City}</label>
                  <Select value={city} onChange={(e) => setCity(e)}>
                    {city?.length <= 0 && (
                      <Option value={""}>{appconstant.selectCity}</Option>
                    )}
                    {citiesArray?.map((item, index) => {
                      return <Option value={item?._id}>{item?.name}</Option>;
                    })}
                  </Select>
                </div>
                <div className="form-group">
                  <label>{appconstant.Employed}</label>
                  <Select value={employed} onChange={(e) => setEmployed(e)}>
                    {employed?.length <= 0 && (
                      <Option value={""}>{appconstant.SelectEmployment}</Option>
                    )}
                    {employementArray?.map((item, index) => {
                      return <Option value={item?._id}>{item?.name}</Option>;
                    })}
                  </Select>
                </div>
                <Textfiled1
                  label={appconstant.Following}
                  value={
                    userDetails?.following ? userDetails?.following : "N/A"
                  }
                  placeholder="Following"
                  disabled={true}
                />
                <Textfiled1
                  label={appconstant.Followers}
                  value={
                    userDetails?.followers ? userDetails?.followers : "N/A"
                  }
                  placeholder="Followers"
                  disabled={true}
                />
                <div className="text-area-bio">
                  <h1>{appconstant.Bio}</h1>
                  <textarea
                    className="bio_textarea"
                    id="bio"
                    name="bio"
                    rows="4"
                    cols="50"
                    maxLength={500}
                    placeholder="Bio"
                    value={bio}
                    onChange={(e) => setBio(e.target.value)}
                  />
                </div>
                <div className="max-count">
                  <label>{`${bio ? bio?.length : "0"}/500`}</label>
                </div>
                <div className="up-btn">
                  <button type="submit" className="button-list">
                    {appconstant.buttonupate}{" "}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default EditUserDetails;
