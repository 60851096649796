import images from "../themes/appImage";

export const Img = ({ url, className }) => {
  return (
    <img
      className={className}
      src={url || images.blankImage}
      alt={images.blankImage}
      width={"100px"}
    />
  );
};
