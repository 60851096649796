import { apiConstants as types } from '../../themes/apiConstants'

const initialState = {
    isLoading: false,
    result: [],
    errorState: null,
    pagination: {},
    playerAnswerDetails: {},
}

export const playerAnswerReducer = (state = initialState, action) => {

    switch (action.type) {

        //for all player answer
        case types.API_PLAYER_ANSWER_LISTING_LOAD:
            return { ...state, isLoading: true, errorState: null }
        case types.API_PLAYER_ANSWER_LISTING_SUCCESS:
            return { ...state, isLoading: false, result: action.result.data.list, pagination: action.result.data.pagination }

        //for individual player answer
        case types.API_USER_ANSWER_LISTING_LOAD:
            return { ...state, isLoading: true, errorState: null }
        case types.API_USER_ANSWER_LISTING_SUCCESS:
            return { ...state, isLoading: false, result: action.result.data.list, pagination: action.result.data.pagination }

        //question Failed Case
        case types.API_PLAYER_ANSWER_LISTING_FAILED:
            return { ...state, isLoading: false }

        default:
            return { ...state }
    }
}