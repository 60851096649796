import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Changepassword from "../components/changepassword";
import Dashboard from "../components/dashboard";
import ForgotPassword from "../components/forgotpassword";
import Login from "../components/login";
import UserManagement from "../components/user management";
import EditUserDetails from "../components/user management/edtiuser";
import UserDetails from "../components/user management/userDetail";
import AppealDetail from "../components/reportedUser/appealDetail";
import MainLayout from "../layout/mainlayout";
import ResetPassword from "../components/reset password";
import PrivateRoute, { PublicRoute } from "./privateRoute";
import ReportedUser from "../components/reportedUser";
import InAppPurchase from "../components/inappPurchase";
import CategoryManagement from "../components/categoryManagement";
import QuestionsManagement from "../components/questionsManagement";
import PlayerAnswerManagement from "../components/playerAnswerManagement";
import DailyQuestion from "../components/addDailyQuestion";
import EditDailyQuestions from "../components/addDailyQuestion/editQuestions";
import AddDailyQuestions from "../components/addDailyQuestion/addQuestions";
import AddQuestions from "../components/questionsManagement/addQuestions";
import EditQuestions from "../components/questionsManagement/editQuestions";
import AddCategory from "../components/categoryManagement/addCategory";
import EditCategory from "../components/categoryManagement/editCategory";
import PurchaseHistory from "../components/user management/purchaseHistory";
import SuggestedCategoryManagement from "../components/suggestCategoryManagement/index"
import SuggestedQuestionManagement from "../components/suggestQuestionManagement/index"
import SubAdminManagement from "../components/superAdminManagement/index"
import AddSubAdmin from "../components/superAdminManagement/addAdmin"
import ViewSubAdmin from "../components/superAdminManagement/viewAdmin"
import { navigateFn } from "../Utils/helpers";

const RoutesNew = () => {


  return (
    <div>
      <Router>
        <Routes>
          <Route path={"/"} element={<Navigate to={"/dashboard"} />} />
          <Route path={"*"} element={<Navigate to={"/dashboard"} />} />
          <Route
            path={"/login"}
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path={"/forgotpassword"}
            element={
              <PublicRoute>
                <ForgotPassword />
              </PublicRoute>
            }
          />
          <Route path={"/resetpassword"} element={<ResetPassword />} />
          <Route
            path={"/"}
            element={
              <PrivateRoute>
                <MainLayout />
              </PrivateRoute>
            }
          >

            <Route
              path={"/reported-user-management"}
              element={
                <PrivateRoute>
                  <ReportedUser />
                </PrivateRoute>
              }
            />
            <Route
              path={"/inapp-purchase-management"}
              element={
                <PrivateRoute>
                  <InAppPurchase />
                </PrivateRoute>
              }
            />
            <Route
              path={"/view-appeal"}
              element={
                <PrivateRoute>
                  <AppealDetail />
                </PrivateRoute>
              }
            />
            <Route
              path={"/category-management"}
              element={
                <PrivateRoute>
                  <CategoryManagement />
                </PrivateRoute>
              }
            />
            <Route
              path={"/suggested-category-management"}
              element={
                <PrivateRoute>
                  <SuggestedCategoryManagement />
                </PrivateRoute>
              }
            />

            <Route
              path={"/suggested-questions-management"}
              element={
                <PrivateRoute>
                  <SuggestedQuestionManagement />
                </PrivateRoute>
              }
            />

            <Route
              path={"/edit-category"}
              element={
                <PrivateRoute>
                  <EditCategory />
                </PrivateRoute>
              }
            />
            <Route
              path={"/purchase-history"}
              element={
                <PrivateRoute>
                  <PurchaseHistory />
                </PrivateRoute>
              }
            />

            <Route
              path={"/questions-management"}
              element={
                <PrivateRoute>
                  <QuestionsManagement />
                </PrivateRoute>
              }
            />

            <Route
              path={"/player-answer-management"}
              element={
                <PrivateRoute>
                  <PlayerAnswerManagement />
                </PrivateRoute>
              }
            />

            <Route
              path={"/daily-question"}
              element={
                <PrivateRoute>
                  <DailyQuestion />
                </PrivateRoute>
              }
            />

            <Route
              path={"/add-questions"}
              element={
                <PrivateRoute>
                  <AddQuestions />
                </PrivateRoute>
              }
            />
            <Route
              path={"/edit-questions"}
              element={
                <PrivateRoute>
                  <EditQuestions />
                </PrivateRoute>
              }
            />

            <Route
              path={"/edit-daily-questions"}
              element={
                <PrivateRoute>
                  <EditDailyQuestions />
                </PrivateRoute>
              }
            />

            <Route
              path={"/add-daily-questions"}
              element={
                <PrivateRoute>
                  <AddDailyQuestions />
                </PrivateRoute>
              }
            />


            <Route
              path={"/sub-admin"}
              element={
                <PrivateRoute>
                  <SubAdminManagement />
                </PrivateRoute>
              }
            />

            <Route
              path={"/view-sub-admin"}
              element={
                <PrivateRoute>
                  <ViewSubAdmin />
                </PrivateRoute>
              }
            />
            <Route
              path={"/add-sub-admin"}
              element={
                <PrivateRoute>
                  <AddSubAdmin />
                </PrivateRoute>
              }
            />

            <Route
              path={"/add-category"}
              element={
                <PrivateRoute>
                  <AddCategory />
                </PrivateRoute>
              }
            />

            <Route
              path={"/dashboard"}
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/changepassword"
              element={
                <PrivateRoute>
                  <Changepassword />
                </PrivateRoute>
              }
            />
            <Route
              path="/usermanagement"
              element={
                <PrivateRoute>
                  <UserManagement />
                </PrivateRoute>
              }
            />
            <Route
              path="/userdetails"
              element={
                <PrivateRoute>
                  <UserDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/edituserdetails"
              element={
                <PrivateRoute>
                  <EditUserDetails />
                </PrivateRoute>
              }
            />
          </Route>
        </Routes>
      </Router>
    </div>
  );
};
export default RoutesNew;
