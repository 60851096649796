import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import appconstant from "../../themes/appconstant";
import appImage from "../../themes/appImage";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { useDispatch } from "react-redux";
import LayoutHoc from "../../common/LayoutHoc";
import "./categoryManagement.css";
import * as Yup from "yup";
import { Select, Spin, Tooltip } from "antd";
import { useFormik } from "formik";
import { ValidationConstant } from "../../themes/validationConstant";
import { isInternetConnected } from "../../Utils/InternetConnection";
import { categoryAddAction, categoryMapListAction } from "../../Redux/Action/categoryManagementAction";
import { useSelector } from "react-redux";
import { capitalFirstLetter } from "../../Utils/helpers";

const AddCategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [animationLabel, setAnimationLable] = useState('')
  const mapList = useSelector(state => state.categoryManagementReducer.mapList)
  const [animation, setAnimation] = useState('')
  const [categoryBackground, setCategoryBackground] = useState('')
  const [questionBackground, setQuestionBackground] = useState('')
  const [music, setMusic] = useState('')
  const [icon, setIcon] = useState('')

  const categoryLoading = useSelector(x => x.categoryManagementReducer.isLoading)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [categoryLoading])

  const selectOptions = [
    {
      value: 'Paid',
      name: 'Paid'
    },
    {
      value: 'Unpaid',
      name: 'Unpaid'
    }
  ]

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(ValidationConstant.category.empty).min(2, ValidationConstant.category.min).max(22, ValidationConstant.category.max),
    type: Yup.string()
      .required(ValidationConstant.category.categoryAccessibility),
    description: Yup.string().when('type', {
      is: 'Paid',
      then: Yup.string().required(ValidationConstant.category.descriptionRequire).min(2, ValidationConstant.category.descriptionMin).max(150, ValidationConstant.category.descriptionMax),
    }),
    stars: Yup.string().when('type', {
      is: 'Paid',
      then: Yup.string()
        .required(ValidationConstant.category.starRequire).test('not-starts-with-zero', 'Stars can not be zero.', value => {
          return !/^0/.test(value);
        })
        .min(2, ValidationConstant.category.starMin)
        .max(10, ValidationConstant.category.starMax)
    }),
    mapType: Yup.array()
      .min(1, ValidationConstant.category.mapType)
      .required(ValidationConstant.category.mapType),
    animation: Yup.mixed()
      .required('Please select a animation.')
      .test('fileType', 'Only JSON files are allowed', (value) => {
        return value && value.type === 'application/json';
      }),
    music: Yup.mixed()
      .required('Please select an MP3 file.')
      .test('fileType', 'Only MP3 files are allowed', (value) => {
        if (value instanceof File) return value && ['audio/mpeg'].includes(value.type);
        return true;
      })
      .test("fileSize", "Max allowed size is 1000KB",
        value => {
          if (!(value instanceof File)) return true
          return value && value.size <= 10485760
        }),
    icon: Yup.mixed()
      .required('Please select a icon.')
      .test('fileType', 'Only JPG, PNG, and SVG files are allowed', (value) => {
        if (!value) return false;

        const allowedExtensions = ['image/jpeg', 'image/png', 'image/svg+xml'];
        return allowedExtensions.includes(value.type);
      })
      .test("fileSize", "Max allowed size is 100KB",
        value => value && value.size <= 102400),
    questionBackground: Yup.mixed()
      .required('Please select a image.')
      .test('fileType', 'Only JPG, PNG, and SVG files are allowed', (value) => {
        if (!value) return false;

        const allowedExtensions = ['image/jpeg', 'image/png', 'image/svg+xml'];
        return allowedExtensions.includes(value.type);
      })
      .test("fileSize", "Max allowed size is 1000KB",
        value => value && value.size <= 1000000),
    categoryBackground: Yup.mixed()
      .required('Please select a image.')
      .test('fileType', 'Only JPG, PNG, and SVG files are allowed', (value) => {
        if (!value) return false;

        const allowedExtensions = ['image/jpeg', 'image/png', 'image/svg+xml'];
        return allowedExtensions.includes(value.type);
      })
      .test("fileSize", "Max allowed size is 1000KB",
        value => value && value.size <= 1000000),

  });

  const initialValues = {
    name: "",
    type: "",
    animation: '',
    categoryBackground: '',
    questionBackground: '',
    icon: '',
    mapType: [],
    music: '',
    description: '',
    stars: ''
  };

  const onSubmit = (values) => {
    let form = new FormData()
    form.append("type", values.type)
    form.append("mapType", JSON.stringify(values.mapType))
    form.append("name", values.name)
    form.append("icon", icon)
    form.append("animation", animation)
    form.append("categoryBackground", categoryBackground)
    form.append("questionBackground", questionBackground)
    form.append("music", music)
    form.append("description", values?.description)
    form.append("stars", values?.stars)

    isInternetConnected() && dispatch(categoryAddAction(form, navigate));
  };
  const validate = (e) => {
    let error = {};
    for (let i in e) {
      if (e[i] === null) {
        error[i] = ValidationConstant.category[i]
      }
    }

    return error
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validate,
    onSubmit,
  });


  useEffect(() => {
    dispatch(categoryMapListAction())
  }, [])

  return (
    <Spin style={{ display: "flex" }} spinning={categoryLoading}>

      <div>
        <BreadcrumbFn
          path={["dashboard", "category-management"]}
          pathName={[
            <FontAwesomeIcon icon={faHome} />,
            appconstant.categoryMngmnt,
            appconstant.addCategory,
          ]}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            background: "#fff",
            padding: "17px 28px 0px 25px",
            alignItems: "center",
          }}
        >
          <h6 class="text-white text-capitalize ps-3">
            {appconstant.addCategory}
          </h6>
        </div>

        <LayoutHoc customClass={"userManagement"}>
          <div
            className="wrapper_line view_modules view"
            style={{
              width: "100%",
            }}
          >
            {/* category name */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                margin: "20px 0px",
                borderRadius: "5px",
              }}
            >
              <div
                style={{
                  alignItems: "flex-start",
                  flex: 1,
                  marginBottom: "13px",
                }}
              >
                <label
                  style={{
                    //maxWidth: "22%",
                    fontSize: "16px",
                  }}
                >
                  {"Category Name"}
                </label>
              </div>
              <div
                style={{
                  alignItems: "flex-start",
                  flex: 1,
                }}
              >
                <input
                  value={formik.values.name}
                  style={{
                    width: "100%",
                    margin: 0,
                    fontSize: "16px",
                  }}
                  name="name"
                  maxLength={22}
                  placeholder="Category Name"
                  onChange={(e) => formik.setFieldValue("name", e.target.value.trimStart())}
                />
              </div>
              {formik.errors.name ? (
                <div className="error-category-um">{formik.errors.name}</div>
              ) : null}
            </div>

            {/* category accessability */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                margin: "20px 0px",
                borderRadius: "5px",
              }}
            >
              <div
                style={{
                  alignItems: "flex-start",
                  flex: 1,
                  marginBottom: "13px",
                }}
              >
                <label
                  style={{
                    //maxWidth: "22%",
                    fontSize: "16px",
                  }}
                >
                  {"Category Accessibility"}
                </label>
              </div>
              <div
                style={{
                  alignItems: "flex-start",
                  flex: 1,
                }}
              >
                <Select
                  id={"id"}
                  size="large"
                  value={formik.values.type || null}
                  className="category-accessability"
                  style={{ width: "100%" }}
                  name="type"
                  onChange={(e) => formik.setFieldValue("type", e)}
                  placeholder="Select Category Accessibility"
                  options={selectOptions}
                />
              </div>
              {formik.errors.type ? (
                <div className="error-category-um">{formik.errors.type}</div>
              ) : null}
            </div>
            {/* CATEGORY DESCRIPTION */}
            {formik.values.type === 'Unpaid' || formik.values.type === '' ? null : (<div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                margin: "20px 0px",
                borderRadius: "5px",
              }}
            >
              <div
                style={{
                  alignItems: "flex-start",
                  flex: 1,
                  marginBottom: "13px",
                }}
              >
                <label
                  style={{
                    //maxWidth: "22%",
                    fontSize: "16px",
                  }}
                >
                  {"Category Description"}
                </label>
              </div>
              <div
                style={{
                  alignItems: "flex-start",
                  flex: 1,
                }}
              >
                <input
                  value={formik.values.description}
                  style={{
                    width: "100%",
                    margin: 0,
                    fontSize: "16px",
                  }}
                  name="name"
                  maxLength={160}
                  placeholder="Category Description"
                  onChange={(e) => formik.setFieldValue("description", e.target.value.trimStart())}
                  disabled={formik.values.type === 'Unpaid'}
                />
              </div>
              {formik.errors.description ? (
                <div className="error-category-um">{formik.errors.description}</div>
              ) : null}
            </div>)}

            {/* STARS */}
            {formik.values.type === 'Unpaid' || formik.values.type === '' ? null : (<div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                margin: "20px 0px",
                borderRadius: "5px",
              }}
            >
              <div
                style={{
                  alignItems: "flex-start",
                  flex: 1,
                  marginBottom: "13px",
                }}
              >
                <label
                  style={{
                    //maxWidth: "22%",
                    fontSize: "16px",
                  }}
                >
                  {"Category Stars"}
                </label>
              </div>
              <div
                style={{
                  alignItems: "flex-start",
                  flex: 1,
                }}
              >
                <input
                  value={formik.values.stars}
                  style={{
                    width: "100%",
                    margin: 0,
                    fontSize: "16px",
                  }}
                  name="name"
                  maxLength={4}
                  placeholder="Category Stars"
                  onChange={
                    (e) => {
                      if (e.target.value.startsWith(' ') || ! /^[0-9]*$/.test(e.target.value)) e.preventDefault()
                      else formik.setFieldValue("stars", e.target.value.trimStart())
                    }}
                  disabled={formik.values.type === 'Unpaid'}
                />
              </div>
              {formik.errors.stars ? (
                <div className="error-category-um">{formik.errors.stars}</div>
              ) : null}
            </div>)}

            {/* Category map type */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                margin: "20px 0px",
                borderRadius: "5px",
              }}
            >
              <div
                style={{
                  alignItems: "flex-start",
                  flex: 1,
                  marginBottom: "13px",
                }}
              >
                <label
                  style={{
                    //maxWidth: "22%",
                    fontSize: "16px",
                  }}
                >
                  {"Category Map Type"}
                </label>
              </div>
              <div
                style={{
                  alignItems: "flex-start",
                  flex: 1,
                }}
              >

                <Select
                  id={"id"}
                  mode="multiple"
                  // size="large"
                  showArrow
                  value={formik.values.mapType || []}
                  // className="category-accessability"
                  style={{ width: "100%" }}
                  name="mapType"
                  onChange={(e) => { formik.setFieldValue("mapType", e) }}
                  showSearch={false}
                  placeholder="Select Category Map Type"
                  options={mapList.map(e => ({ label: capitalFirstLetter(e.name), value: e._id }))}
                />
              </div>
              {formik.errors.mapType ? (
                <div className="error-category-um">{formik.errors.mapType}</div>
              ) : null}
            </div>


            {/* Animation */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                margin: "20px 0px",
                borderRadius: "5px",
              }}
            >
              <div
                style={{
                  alignItems: "flex-start",
                  flex: 1,
                  marginBottom: "13px",
                }}
              >
                <label
                  style={{
                    //maxWidth: "22%",
                    fontSize: "16px",
                  }}
                >
                  {"Animation"}
                </label>
              </div>
              <div
                style={{
                  alignItems: "center",
                  flex: 1,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    border: "1px solid #1b5449",
                    width: "100%",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 21px",
                  }}
                >
                  <label
                    htmlFor="upload-audio"
                    style={{
                      position: "relative",
                      maxWidth: "max-content",
                    }}
                  >
                    <div
                      className="button-list addturna "
                      style={{
                        width: "100%",
                        padding: 0,
                        fontWeight: "600",
                        maxWidth: "min-content",
                        border: "0",
                        margin: "3px",
                        marginLeft: "0px",
                        color: "#fff",
                        padding: "10px 10px",
                        cursor: "pointer",
                        borderStyle: "solid",
                        backgroundColor: "#1b5449",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img src={appImage?.uploadImg} width={"20px"} />
                    </div>
                  </label>
                  <input
                    id="upload-audio"
                    type={"file"}
                    accept=".json"
                    style={{
                      width: "8%",
                      fontSize: "16px",
                      cursor: "pointer",
                      display: "none",
                    }}
                    onChange={(e) => {

                      const files = e.target.files;
                      if (files[0]) {

                        setAnimation(files[0])
                        formik.setFieldValue("animation", files[0])
                        setAnimationLable(files[0].name)
                      }
                    }}
                  />
                  {(
                    <label
                      style={{
                        fontSize: "16px",
                        marginLeft: "8px",
                        maxWidth: "unset",
                      }}
                    >
                      {animationLabel ? animationLabel : 'Please upload the animation.'}
                    </label>
                  )}
                </div>
              </div>
              {formik.errors.animation ? (
                <div className="error-category-um">{formik.errors.animation}</div>
              ) : null}
            </div>
            {/* Music */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                margin: "20px 0px",
                borderRadius: "5px",
              }}
            >
              <div
                style={{
                  alignItems: "flex-start",
                  flex: 1,
                  marginBottom: "13px",
                }}
              >
                <label
                  style={{
                    //maxWidth: "22%",
                    fontSize: "16px",
                  }}
                >
                  {"Music"}
                </label>
              </div>
              <div
                style={{
                  alignItems: "center",
                  flex: 1,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    border: "1px solid #1b5449",
                    width: "100%",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 21px",
                  }}
                >
                  <label
                    htmlFor="upload-music"
                    style={{
                      position: "relative",
                      maxWidth: "max-content",
                    }}
                  >
                    <div
                      className="button-list addturna "
                      style={{
                        width: "100%",
                        padding: 0,
                        fontWeight: "600",
                        maxWidth: "min-content",
                        border: "0",
                        margin: "3px",
                        marginLeft: "0px",
                        color: "#fff",
                        padding: "10px 10px",
                        cursor: "pointer",
                        borderStyle: "solid",
                        backgroundColor: "#1b5449",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img src={appImage?.uploadImg} width={"20px"} />
                    </div>
                  </label>
                  <input
                    id="upload-music"
                    type={"file"}
                    accept=".mpeg, .mp3,"
                    style={{
                      width: "8%",
                      fontSize: "16px",
                      cursor: "pointer",
                      display: "none",
                    }}
                    onChange={(e) => {
                      e.target.files[0] && setMusic(e.target.files[0])
                      e.target.files[0] && formik.setFieldValue("music", e.target.files[0])
                    }}
                  />
                  {(
                    <label
                      style={{
                        fontSize: "16px",
                        marginLeft: "8px",
                        maxWidth: "unset",
                      }}
                    >
                      {music ? music?.name : 'Please upload music.'}
                    </label>
                  )}
                </div>
              </div>
              {formik.errors.music ? (
                <div className="error-category-um">{formik.errors.music}</div>
              ) : null}
            </div>

            {/* upload images */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* Question Background Image */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "column",
                  margin: "20px 0px",
                  borderRadius: "5px",
                }}
              >
                <div
                  style={{
                    alignItems: "flex-start",
                    flex: 1,
                    marginBottom: "13px",
                  }}
                >
                  <label
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    {"Question Background Image"}
                  </label>
                </div>
                <div
                  style={{
                    alignItems: "flex-start",
                    flex: 1,
                  }}
                >
                  <Tooltip
                    placement={"right"}
                    title={
                      !formik.values.questionBackground
                        ? "Click to add image. "
                        : "Click to change image. "
                    }
                    key={"#143C64"}
                  >
                    <label
                      htmlFor="upload-qn"
                      style={{
                        width: "unset",
                        maxWidth: "unset",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          height: "100px",
                          width: "100px",
                        }}
                      >
                        <img
                          src={typeof formik?.values?.questionBackground === "object" ? URL.createObjectURL(formik.values.questionBackground) : formik?.values?.questionBackground || appImage?.plusCircle}
                          style={{
                            width: "100%",
                            height: "100%",
                            border: `1px solid ${formik.errors.questionBackground ? "red" : "#1b5449"
                              }`,
                            borderRadius: "5px",
                            padding: "9px",
                            background: "#fff",
                            margin: 0,
                            backgroundSize: "conatin",
                          }}
                        />
                        {formik.errors.questionBackground ? (
                          <div className="img-error-category-um">{formik.errors.questionBackground}</div>
                        ) : null}
                      </div>
                    </label>

                  </Tooltip>

                  <input
                    id="upload-qn"
                    type={"file"}
                    accept=".png,.svg,.jpg"
                    style={{
                      display: "none",
                    }}
                    onChange={(e) => {
                      e.target.files[0] && setQuestionBackground(e.target.files[0])
                      e.target.files[0] && formik.setFieldValue("questionBackground", e.target.files[0])
                    }}
                  />
                </div>
              </div>

              {/* Category Background Image */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  margin: "20px 0px",
                  alignItems: "center",
                  borderRadius: "5px",
                }}
              >
                <div
                  style={{
                    alignItems: "flex-start",
                    flex: 1,
                    marginBottom: "13px",
                  }}
                >
                  <label
                    style={{
                      //maxWidth: "22%",
                      fontSize: "16px",
                    }}
                  >
                    {"Category Background Image"}
                  </label>
                </div>
                <div
                  style={{
                    alignItems: "flex-start",
                    flex: 1,
                  }}
                >
                  <>
                    <Tooltip
                      placement={"right"}
                      title={
                        !formik.values.categoryBackground
                          ? "Click to add image. "
                          : "Click to change image. "
                      }
                      key={"#143C64"}
                    >
                      <label
                        htmlFor="upload-cat"
                        style={{
                          width: "unset",
                          maxWidth: "unset",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          style={{
                            height: "100px",
                            width: "100px",
                          }}
                        >
                          <img
                            src={typeof formik?.values?.categoryBackground === "object" ? URL.createObjectURL(formik.values.categoryBackground) : formik?.values?.categoryBackground || appImage?.plusCircle}
                            style={{
                              width: "100%",
                              height: "100%",
                              border: `1px solid ${formik.errors.categoryBackground ? "red" : "#1b5449"
                                }`,
                              borderRadius: "5px",
                              padding: "9px",
                              background: "#fff",
                              backgroundSize: "conatin",
                            }}
                          />
                          {formik.errors.categoryBackground ? (
                            <div className="img-error-category-um">{formik.errors.categoryBackground}</div>
                          ) : null}
                        </div>
                      </label>
                    </Tooltip>
                    <input
                      id="upload-cat"
                      type={"file"}
                      accept=".png,.svg,.jpg"
                      style={{
                        display: "none",
                      }}
                      onChange={(e) => {
                        e.target.files[0] && setCategoryBackground(e.target.files[0])
                        e.target.files[0] && formik.setFieldValue("categoryBackground", e.target.files[0])
                      }}
                    />
                  </>
                </div>
              </div>
              {/* Icon */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "column",
                  margin: "20px 0px",
                  borderRadius: "5px",
                }}
              >
                <div
                  style={{
                    alignItems: "flex-start",
                    flex: 1,
                    marginBottom: "13px",
                  }}
                >
                  <label
                    style={{
                      //maxWidth: "22%",
                      fontSize: "16px",
                    }}
                  >
                    {"Icon"}
                  </label>
                </div>
                <div
                  style={{
                    alignItems: "flex-start",
                    flex: 1,
                  }}
                >
                  <>
                    <Tooltip
                      placement={"right"}
                      title={
                        !formik.values.icon
                          ? "Click to add icon. "
                          : "Click to change icon. "
                      }
                      key={"#143C64"}
                    >
                      <label
                        htmlFor="upload-icon"
                        style={{
                          width: "unset",
                          maxWidth: "unset",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          style={{
                            height: "100px",
                            width: "100px",
                          }}
                        >
                          <img
                            src={typeof formik?.values?.icon === "object" ? URL.createObjectURL(formik.values.icon) : formik?.values?.icon || appImage?.plusCircle}
                            style={{
                              width: "100%",
                              height: "100%",
                              border: `1px solid ${formik.errors.icon ? "red" : "#1b5449"
                                }`, borderRadius: "5px",
                              padding: "9px",
                              background: "#fff",
                              backgroundSize: "conatin",
                            }}
                          />
                          {formik.errors.icon ? (
                            <div className="img-error-category-um">{formik.errors.icon}</div>
                          ) : null}
                        </div>
                      </label>
                    </Tooltip>
                    <input
                      id="upload-icon"
                      type={"file"}
                      accept=".png,.svg,.jpg,.gif"
                      style={{
                        display: "none",
                      }}
                      onChange={(e) => {
                        e.target.files[0] && setIcon(e.target.files[0])
                        e.target.files[0] && formik.setFieldValue("icon", e.target.files[0])
                      }}
                    />
                  </>
                </div>
              </div>
            </div >
            <button
              onClick={formik.handleSubmit}
              type="submit"
              className="addturna submit-button"
              style={{
                maxWidth: "15%",
                margin: "30px 0 0",
              }}
            >
              {appconstant.submit}
            </button>
          </div >
        </LayoutHoc >

      </div >
    </Spin>

  );
};

export default AddCategory;
