import './App.css';
import '../src/css/style.css'
import '../src/css/responsive.css'
import 'antd/dist/antd.min.css'
import RoutesNew from './Route/Routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    const spinner = document.getElementById('spinner');
    if (spinner && !spinner.hasAttribute('hidden')) {
      spinner.setAttribute('hidden', 'true');
    }
  }, [])

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeButton={false}
      />
      <RoutesNew />
    </div>
  );
}

export default App;

