import axios from "axios";
import appconstant from "../../themes/appconstant";

// base url
const http = axios.create({
  baseURL: appconstant.baseUrl,
});

http.interceptors.request.use(async (config) => {
  const token = localStorage.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers["Content-Type"] = "application/json";
  }

  return config;
});

export default http;
