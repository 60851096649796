import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useLocation } from "react-router-dom";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import appconstant from "../../themes/appconstant";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import InputField from "../../common/DisableInputField";
import { useSelector, useDispatch } from "react-redux";
import { Spin } from "antd";
import LayoutHoc from "../../common/LayoutHoc";
import "./superAdmin.css";
import { isInternetConnected } from "../../Utils/InternetConnection";
import { capitalFirstLetter } from "../../Utils/helpers";
import { viewSuperAdminAction } from "../../Redux/Action/superAdminAction";

const ViewSubAdmin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const superAdminDetail = useSelector((state) => state.superAdminManagementReducer);
    const { adminDetail, isLoading } = superAdminDetail;
    useEffect(() => {
        if (state?._id) {
            isInternetConnected() &&
                dispatch(viewSuperAdminAction({ adminId: state?._id }));
        } else {
            navigate("/sub-admin");
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const itemsT = [
        {
            key: '5',
            label: (
                "5"
            ),
        },
        {
            key: '10',
            label: (
                "10"
            ),
        },
        {
            key: '20',
            label: (
                "20"
            ),
        },
        {
            key: '50',
            label: (
                "50"
            ),
        },
    ];





    const userFields = [
        {
            label: appconstant.name,
            value: adminDetail?.name ? capitalFirstLetter(adminDetail?.name) : "N/A",
        },
        {
            label: appconstant.EmailAddress,
            value: adminDetail?.email ?? "N/A",
        },
        {
            label: appconstant.createdOn,
            value: adminDetail?.createdAt ?
                new Date(adminDetail?.createdAt).toLocaleDateString('en-AU', {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric'
                })
                : "N/A",
        },
    ];
    return (
        <Spin spinning={isLoading}>
            <div>
                <BreadcrumbFn
                    path={["dashboard", "sub-admin"]}
                    pathName={[
                        <FontAwesomeIcon icon={faHome} />,
                        appconstant.superAdmin,
                        appconstant.superAdminDetail,
                    ]}
                />
                <div
                    class="bg-gradient-primary shadow-primary border-radius-lg pt-4 "
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        background: "#fff",
                        padding: "17px 28px 0px 25px",
                        // padding: "10px 28px 0 25px",
                        alignItems: "center",
                    }}>
                    <h6 class="text-white text-capitalize ps-3">{"Sub Admin Details"}</h6>
                </div>
                <LayoutHoc customClass={"no-padding-margin"}>
                    <div
                        className="wrapper_line view_modules view"
                        style={{
                            width: "100%",
                        }}>

                        <div>
                            {userFields.map((item, index) => {
                                return (
                                    <InputField
                                        label={item?.label}
                                        style={{
                                            margin: "0.5em 0 1em",
                                        }}
                                        value={item?.value}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </LayoutHoc>
            </div>
        </Spin>




    );
};

export default ViewSubAdmin;
