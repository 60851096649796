import { apiConstants as types } from "../../themes/apiConstants";

// In App listing Action
export const inAppListingAction = (
  search,
  offset,
  limit,
  sortBy,
  order,
  navigate
) => ({
  type: types.API_INAPP_MANAGEMENT_LISTING_LOAD,
  search,
  offset,
  limit,
  sortBy,
  order,
  navigate,
});

// // poll details Action
// export const pollDetailsAction = (id,navigate) => ({
//     type: types.API_POLL_MANAGEMENT_DETAILS_LOAD,
//     id,navigate
// })

// // poll update
// export const pollUpdateAction = (data,navigate) => ({
//     type: types.API_POLL_MANAGEMENT_UPDATE_LOAD,
//     data,
//     navigate
// })

// // poll block
// export const pollBlockAction = (id,search,navigate) => ({
//     type: types.API_POLL_MANAGEMENT_BLOCK_LOAD,
//     id,
//     search,
//     navigate
// })

// // poll country votes
// export const countryVotesAction = (payload,navigate) => ({
//     type: types.API_POLL_MANAGEMENT_COUNTRY_VOTES_LOAD,
//    payload,
//     navigate
// })

// // poll country votes
// export const votesOnReactionsAction = (payload,navigate) => ({
//     type: types.API_POLL_MANAGEMENT_COUNTRY_VOTES_ON_REACTIONS_LOAD,
//    payload,
//     navigate
// })

// // Clear poll details
// export const clearPollDetails = () => {
//     return {
//         type: types.API_CLEAR_POLL_DETAILS_LOAD,
//     }
// }

// // total no. of votes
// export const totalVotes = (payload) => {
//     return {
//         type: types.API_TOTAL_VOTES_LOAD,
//         payload
//     }
// }
