import { apiConstants as types } from "../../themes/apiConstants";


// Super admin listing Action
export const superAdminListAction = (data) => (
  {
    type: types.API_SUPER_ADMIN_LISTING_LOAD,
    data
  });

// View Super admin 
export const viewSuperAdminAction = (data) => (
  {
    type: types.API_SUPER_ADMIN_DETAIL_LOAD,
    data
  });

// Delete Super admin 
export const deleteSuperAdminAction = (data) => (
  {
    type: types.API_SUPER_ADMIN_DELETE_LOAD,
    data
  });

// Add Super admin 
export const addSuperAdminAction = (data, navigate, path) => (
  {
    type: types.API_SUPER_ADMIN_ADD_LOAD,
    data,
    navigate,
    path
  });
