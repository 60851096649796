import { apiConstants as types } from "../../themes/apiConstants";
const initialState = {
  isLoading: false,
  result: {},
  errorState: null,
  isExpired: null,
  message: null,
  success: null,
  pageLoading: true,
  adminType: null
};

export const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.API_LOGIN_LOAD:
      return { ...state, isLoading: true, errorState: null };
    case types.API_LOGIN_SUCCESS:
      localStorage.setItem("token", action.result.access_token);
      localStorage.setItem("adminType", action.result.adminType);
      return {
        ...state,
        isLoading: false,
        result: action.result,
        message: action.message,
        forgetPasswordSuccess: false,
        adminType: action.result.adminType
      };

    // Forgot Password
    case types.API_FORGOT_PASSWORD_LOAD:
      return { ...state, errorState: null, isLoading: true };
    case types.API_FORGOT_PASSWORD_SUCCESS:
      return { ...state, isLoading: false, ...action.result, forgetPasswordSuccess: true };

    // Check Reset Password
    case types.API_CHECK_RESET_PASSWORD_LOAD:
      return { ...state, errorState: null };
    case types.API_CHECK_RESET_PASSWORD_SUCCESS:
      return { ...state, isExpired: action.result?.success, pageLoading: false };

    // Reset Password
    case types.API_RESET_PASSWORD_LOAD:
      return { ...state, errorState: null };
    case types.API_RESET_PASSWORD_SUCCESS:
      return { ...state, success: action.result, pageLoading: false };

    case types.API_CHANGE_PASSWORD_LOAD:
      return { ...state, isLoading: true, errorState: null };
    case types.API_CHANGE_PASSWORD_SUCCESS:
      return { ...state, isLoading: false, result: action.result };

    //Authentication Failed Case
    case types.API_AUTHENTICATION_FAILED:
      return { ...state, isLoading: false, isExpired: false, pageLoading: false };
    case types.API_AUTHENTICATION_ERROR:
      return { ...state, isLoading: false, isExpired: false, pageLoading: false };

    // change status for forgot password success
    case types.API_FORGOT_PASSWORD_SUCCESS_STATUS_CHANGE:
      return { forgetPasswordSuccess: false };

    //Default case
    default:
      return { ...state };
  }
};
