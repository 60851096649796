import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useLocation } from "react-router-dom";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import appconstant from "../../themes/appconstant";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { useSelector, useDispatch } from "react-redux";
import { Spin } from "antd";
import LayoutHoc from "../../common/LayoutHoc";
import "./reportedUser.css";
import { reportDetailsAction } from "../../Redux/Action/reportManagementAction";
import { userBlockAction } from "../../Redux/Action/userManagementAction";
import { isInternetConnected } from "../../Utils/InternetConnection";
import { confirmationModalBlockAndDelete } from "../../common/modal";


const AppealDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [appeal, setAppeal] = useState("");
  const { state } = useLocation();
  const reportDetailsData = useSelector((state) => state.reportManagementReducer);
  const { reportDetails, isLoading } = reportDetailsData;

  useEffect(() => {
    if (state?.id) {
      isInternetConnected() &&
        dispatch(reportDetailsAction(state?.id, navigate));
    } else {
      navigate("/usermanagement");
    }
  }, []);


  useEffect(() => {
    setAppeal(reportDetails.message)
  }, [reportDetails])
  // setAppeal(reportDetail)

  return (
    <Spin spinning={isLoading}>
      <div>
        <BreadcrumbFn
          path={["dashboard", "usermanagement"]}
          pathName={[
            <FontAwesomeIcon icon={faHome} />,
            appconstant.reportManagement1,
            appconstant.appealDetails,
          ]}
        />
        <div
          class="bg-gradient-primary shadow-primary border-radius-lg pt-4 "
          style={{
            display: "flex",
            justifyContent: "space-between",
            background: "#fff",
            padding: "17px 28px 0px 25px",
            // padding: "10px 28px 0 25px",
            alignItems: "center",
          }}>
          <h6 class="text-white text-capitalize ps-3">{"Appeal Details"}</h6>
        </div>
        <LayoutHoc customClass={"no-padding-margin"}>
          <div
            className="wrapper_line view_modules view"
            style={{
              width: "100%",
            }}>
            <div className="">
              {appeal}
            </div>
            <button
              // type="submit"
              onClick={() => {
                confirmationModalBlockAndDelete(reportDetails, () =>
                  isInternetConnected() && dispatch(
                    userBlockAction(
                      { userId: reportDetails.userId, status: false, navigate },
                      // navigate('/reported-user-management')
                    )
                  )
                );
              }
              }
              className="button-list addturna addButton"
              style={{
                maxWidth: "15%",
                margin: "30px 0 0",
              }}>
              {appconstant.block}
            </button>
          </div>
        </LayoutHoc>
      </div >
    </Spin >
  );
};

export default AppealDetail;
