import React from "react";
import { Layout, Menu, Tooltip } from "antd";
import { useLocation } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import images from "../themes/appImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { isInternetConnected } from "../Utils/InternetConnection";
import appconstant from "../themes/appconstant";
import appImage from "../themes/appImage";
import { useDispatch, useSelector } from "react-redux";
import { logoutAction } from "../Redux/Action/authentication";
import { VideoCameraAddOutlined } from "@ant-design/icons";

const { Sider } = Layout;

const Sidebar = (props) => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  const adminType = useSelector(x => x.authenticationReducer.adminType) || localStorage.getItem("adminType")
  const onMenuClick = (e) => {
    if (e.key === "/login") {
      if (isInternetConnected()) {

        dispatch(logoutAction(navigate));
      }
    } else {
      navigate(e.key);
    }
  };

  let activeKey = "";
  switch (location.pathname) {
    case "/dashboard":
      activeKey = "/dashboard";
      break;
    case "/changepassword":
      activeKey = "/changepassword";
      break;
    case "/usermanagement":
      activeKey = "/usermanagement";
      break;
    case "/reported-user-management":
      activeKey = "/reported-user-management";
      break;
    case "/inapp-purchase-management":
      activeKey = location.pathname; //"/inapp-purchase-management";
      break;
    case "/category-management":
      activeKey = location.pathname; //"/inapp-purchase-management";
      break;
    case "/suggested-category-management":
      activeKey = location.pathname; //"/inapp-purchase-management";
      break;
    case "/questions-management":
      activeKey = location.pathname; //"/inapp-purchase-management";
      break;
    // case "/player-answer-management":
    //   activeKey = location.pathname;
    //   break;
    case "/daily-question":
      activeKey = location.pathname;
      break;
    case "/sub-admin":
      activeKey = location.pathname;
      break;
    case "/suggested-questions-management":
      activeKey = location.pathname; //"/inapp-purchase-management";
      break;
    case "/pendingrequests":
      activeKey = "/usermanagement";
      break;
    case "/userdetails":
      activeKey = "/usermanagement";
      break;
    case "/coinmanagement":
      activeKey = "/coinmanagement";
      break;
    case "/usertransactiondetails":
      activeKey = "/coinmanagement";
      break;
    case "/ridemanagement":
      activeKey = "/ridemanagement";
      break;
    case "/edit-questions":
      activeKey = "/questions-management";
      break;
    case "/add-questions":
      activeKey = "/questions-management";
      break;
    case "/add-daily-questions":
      activeKey = "/daily-question";
      break;
    case "/edit-daily-questions":
      activeKey = "/daily-question";
      break;
    case "/add-sub-admin":
      activeKey = "/sub-admin";
      break;
    case "/view-sub-admin":
      activeKey = "/sub-admin";
      break;
    case "/add-category":
      activeKey = "/category-management";
      break;
    case "/edit-category":
      activeKey = "/category-management";
      break;
    case "/purchase-history":
      activeKey = "/usermanagement";
      break;
    case "/mapmanagement":
      activeKey = "/mapmanagement";
      break;
    case "/mapdetails":
      activeKey = "/mapmanagement";
      break;

    case "/addmap":
      activeKey = "/mapmanagement";
      break;
    case "/editmap":
      activeKey = "/mapmanagement";
      break;

    case "/starmanagement":
      activeKey = "/starmanagement";
      break;
    case "/addstar":
      activeKey = "/starmanagement";
      break;
    case "/viewstar":
      activeKey = "/starmanagement";
      break;
    case "/editstar":
      activeKey = "/starmanagement";
      break;
    case "/mapandquestionpackmanagement":
      activeKey = "/mapandquestionpackmanagement";
      break;
    case "/addpack":
      activeKey = "/mapandquestionpackmanagement";
      break;
    case "/viewpack":
      activeKey = "/mapandquestionpackmanagement";
      break;
    case "/editpack":
      activeKey = "/mapandquestionpackmanagement";
      break;
  }
  return (
    <Sider
      className={props.isVisible ? "sider_left new closedd" : "sider_left new"}
      collapsedWidth="0"
      width={props.isVisible ? "108" : "332"}
      // style={{

      // }}
      onBreakpoint={(broken) => {
      }}
      onCollapse={(collapsed, type) => {
      }}
    >
      <div className="logo">
        <Link to="/dashboard">
          <img src={images.login} />
        </Link>
      </div>

      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={[activeKey]}
        selectedKeys={[activeKey]}
        onClick={(e) => {


          onMenuClick(e)
        }}
        className="main-menu"
      >
        <Menu.Item
          key="/dashboard"
          className={
            activeKey === "/dashboard" ? "line_dash active" : "line_dash"
          }
        >
          <Link to="">
            <Tooltip
              placement={"right"}
              title={!props?.isVisible ? "" : appconstant?.home}
            >
              <img
                src={
                  activeKey == "/dashboard"
                    ? appImage?.dashboardWhite
                    : appImage?.dashboard
                }
                className={
                  activeKey == "/dashboard" ? "sideBarImageActive" : "notActive"
                }
              />
            </Tooltip>
            <span
              className={
                activeKey == "/dashboard"
                  ? "line_dash text-active"
                  : "line_dash"
              }
            >
              {appconstant?.home}
            </span>
          </Link>
        </Menu.Item>

        <Menu.Item
          key="/usermanagement"
          className={
            activeKey == "/usermanagement" ? "line_dash active" : "line_dash"
          }
        >
          <Tooltip
            placement={"right"}
            title={!props?.isVisible ? "" : appconstant?.UserManagement}
          >
            <img
              src={
                activeKey == "/usermanagement"
                  ? appImage?.userMngmntWhite
                  : appImage?.userMngmnt
              }
              style={{ color: "white" }}
              className={
                activeKey == "/usermanagement"
                  ? "sideBarImageActive"
                  : "notActive"
              }
            />
          </Tooltip>
          <span
            className={
              activeKey == "/usermanagement"
                ? "line_dash text-active"
                : "line_dash"
            }
          >
            User Management
          </span>
        </Menu.Item>

        <Menu.Item
          key="/reported-user-management"
          className={
            activeKey == "/reported-user-management"
              ? "line_dash active"
              : "line_dash"
          }
        >
          <Tooltip
            placement={"right"}
            title={
              !props?.isVisible ? "" : appconstant?.reportedUsersManagement
            }
          >
            <img
              src={
                activeKey == "/reported-user-management"
                  ? appImage?.reportUserMngmntWhite
                  : appImage?.reportUserMngmnt
              }
              className={
                activeKey == "/reported-user-management"
                  ? "sideBarImageActive"
                  : "notActive"
              }
            />
          </Tooltip>
          <span
            className={
              activeKey == "/reported-user-management"
                ? "line_dash text-active"
                : "line_dash"
            }
          >
            {appconstant?.reportedUsersManagement}
          </span>
        </Menu.Item>
        {/* <Menu.Item
          key="/inapp-purchase-management"
          className={
            activeKey == "/inapp-purchase-management"
              ? "line_dash active"
              : "line_dash"
          }
        >
          <Tooltip
            placement={"right"}
            title={
              !props?.isVisible ? "" : appconstant?.inAppPurchaseManagement
            }
          >
            <img
              src={
                activeKey == "/inapp-purchase-management"
                  ? appImage?.inAppUserMngmntWhite
                  : appImage?.inAppUserMngmnt
              }
              className={
                activeKey == "/inapp-purchase-management"
                  ? "sideBarImageActive"
                  : "notActive"
              }
            />
          </Tooltip>
          <span
            className={
              activeKey == "/inapp-purchase-management"
                ? "line_dash text-active"
                : "line_dash"
            }
          >
            {appconstant?.inapp}
          </span>
        </Menu.Item> */}
        <Menu.Item
          key="/category-management"
          className={
            activeKey == "/category-management"
              ? "line_dash active"
              : "line_dash"
          }
        >
          <Tooltip
            placement={"right"}
            title={!props?.isVisible ? "" : appconstant?.categoryMngmnt}
          >
            <img
              src={
                activeKey == "/category-management"
                  ? appImage?.categoryMngmntWhite
                  : appImage?.categoryMngmnt
              }
              className={
                activeKey == "/category-management"
                  ? "sideBarImageActive"
                  : "notActive"
              }
            />
          </Tooltip>
          <span
            className={
              activeKey == "/category-management"
                ? "line_dash text-active"
                : "line_dash"
            }
          >
            {appconstant?.categoryMngmnt}
          </span>
        </Menu.Item>

        <Menu.Item
          key="/questions-management"
          className={
            activeKey == "/questions-management"
              ? "line_dash active"
              : "line_dash"
          }
        >
          <Tooltip
            placement={"right"}
            title={!props?.isVisible ? "" : appconstant?.qnsMngmnt}
          >
            <img
              src={
                activeKey == "/questions-management"
                  ? appImage?.qnsMngmntWhite
                  : appImage?.qnsMngmnt
              }
              className={
                activeKey == "/questions-management"
                  ? "sideBarImageActive"
                  : "notActive"
              }
            />
          </Tooltip>
          <span
            className={
              activeKey == "/questions-management"
                ? "line_dash text-active"
                : "line_dash"
            }
          >
            {appconstant?.questionsMngmnt}
          </span>
        </Menu.Item>

        {/* <Menu.Item
          key="/player-answer-management"
          className={
            activeKey == "/player-answer-management"
              ? "line_dash active"
              : "line_dash"
          }
        >
          <Tooltip
            placement={"right"}
            title={!props?.isVisible ? "" : appconstant?.playerAnswer}
          >
            <img
              src={
                activeKey == "/player-answer-management"
                  ? appImage?.playerAnswerWhite
                  : appImage?.playerAnswer
              }
              className={
                activeKey == "/player-answer-management"
                  ? "sideBarImageActive"
                  : "notActive"
              }
            />
          </Tooltip>
          <span
            className={
              activeKey == "/player-answer-management"
                ? "line_dash text-active"
                : "line_dash"
            }
          >
            {appconstant?.playerAnswer}
          </span>
        </Menu.Item> */}

        {(adminType === "admin") && (<Menu.Item
          key="/sub-admin"
          className={
            activeKey == "/sub-admin"
              ? "line_dash active"
              : "line_dash"
          }
        >
          <Tooltip
            placement={"right"}
            title={!props?.isVisible ? "" : appconstant?.superAdmin}
          >
            <img
              src={
                activeKey == "/sub-admin"
                  ? appImage?.userMngmntWhite
                  : appImage?.userMngmnt
              }
              className={
                activeKey == "/sub-admin"
                  ? "sideBarImageActive"
                  : "notActive"
              }
            />
          </Tooltip>
          <span
            className={
              activeKey == "/sub-admin"
                ? "line_dash text-active"
                : "line_dash"
            }
          >
            {appconstant?.superAdmin}
          </span>
        </Menu.Item>)}

        <Menu.Item
          key="/daily-question"
          className={
            activeKey == "/daily-question"
              ? "line_dash active"
              : "line_dash"
          }
        >
          <Tooltip
            placement={"right"}
            title={!props?.isVisible ? "" : appconstant?.addDailyQuestion}
          >
            <img
              src={
                activeKey == "/daily-question"
                  ? appImage?.qnsMngmntWhite
                  : appImage?.qnsMngmnt
              }
              className={
                activeKey == "/daily-question"
                  ? "sideBarImageActive"
                  : "notActive"
              }
            />
          </Tooltip>
          <span
            className={
              activeKey == "/daily-question"
                ? "line_dash text-active"
                : "line_dash"
            }
          >
            {appconstant?.dailyQuestion}
          </span>
        </Menu.Item>


        <Menu.Item
          key="/suggested-questions-management"
          className={
            activeKey == "/suggested-questions-management"
              ? "line_dash active"
              : "line_dash"
          }
        >
          <Tooltip
            placement={"right"}
            title={!props?.isVisible ? "" : appconstant?.suggestedQuestionsMngmnt}
          >
            <img
              src={
                activeKey == "/suggested-questions-management"
                  ? appImage?.suggestedQnsMngmntWhite
                  : appImage?.suggestedQnsMngmnt
              }
              className={
                activeKey == "/suggested-questions-management"
                  ? "sideBarImageActive"
                  : "notActive"
              }
            />
          </Tooltip>
          <span
            className={
              activeKey == "/suggested-questions-management"
                ? "line_dash text-active"
                : "line_dash"
            }
          >
            {appconstant?.suggestedQuestionsMngmnt}
          </span>
        </Menu.Item>

        <Menu.Item
          key="/suggested-category-management"
          className={
            activeKey == "/suggested-category-management"
              ? "line_dash active"
              : "line_dash"
          }
        >
          <Tooltip
            placement={"right"}
            title={!props?.isVisible ? "" : appconstant?.suggestedCategoryManagement}
          >
            <img
              src={
                activeKey == "/suggested-category-management"
                  ? appImage?.suggestedCategoryMngmntWhite
                  : appImage?.suggestedCategoryMngmnt
              }
              className={
                activeKey == "/suggested-category-management"
                  ? "sideBarImageActive"
                  : "notActive"
              }
            />
          </Tooltip>
          <span
            className={
              activeKey == "/suggested-category-management"
                ? "line_dash text-active"
                : "line_dash"
            }
          >
            {appconstant?.suggestedCategoryManagement}
          </span>
        </Menu.Item>

        <Menu.Item
          key="/changepassword"
          className={
            activeKey == "/changepassword" ? "line_dash active" : "line_dash"
          }
        >
          <Tooltip
            placement={"right"}
            title={!props?.isVisible ? "" : appconstant?.changepassword}
          >
            <img
              src={
                activeKey == "/changepassword"
                  ? appImage?.changePassWhite
                  : appImage?.changePass
              }
              className={
                activeKey == "/changepassword"
                  ? "sideBarImageActive"
                  : "notActive"
              }
            />
          </Tooltip>
          <span
            className={
              activeKey == "/changepassword"
                ? "line_dash text-active"
                : "line_dash"
            }
          >
            Change Password
          </span>
        </Menu.Item>
        <Menu.Item key="/login">
          <Tooltip
            placement={"right"}
            title={!props?.isVisible ? "" : "Logout"}
          >
            <img
              src={
                activeKey == "/login" ? appImage?.logoutWhite : appImage?.logout
              }
              className={
                activeKey == "/login" ? "sideBarImageActive" : "notActive"
              }
            />
          </Tooltip>
          <span
            className={
              activeKey == "/changepassword"
                ? "line_dash text-active"
                : "line_dash"
            }
          >
            {" "}
            Logout
          </span>
        </Menu.Item>
      </Menu>
      <div className="angle-btn">
        <button type="submit" onClick={props.handlewClick} className=" btn ">
          <FontAwesomeIcon
            className="icon-angle-left"
            icon={props.isVisible ? faAngleRight : faAngleLeft}
          />
        </button>
      </div>
    </Sider>
  );
};

export default Sidebar;
