import React from "react";
import appconstant from "../themes/appconstant";

//**  This is Editabel field function **/

const Textfiled1 = (props) => {
  const { type = "text", className,onKeyDown,style, maxLength,disabled, defaultValue, label,placeholder,id,name,value ,onChange} = props;
  return (
    <div  className="form-group">
      <label>{label}</label>
      <div className="input_fields" style={style}>
        <input
          type={type}
          placeholder={placeholder}
          defaultValue={defaultValue}
          className={`form-control ${className ?? ""}`}
          disabled={disabled}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          maxLength={maxLength}
        />
      </div>
    </div>
  );
};

export default Textfiled1;

