import React, { useState, useEffect, useRef } from "react";
import { Button, Pagination, Row } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faHome } from "@fortawesome/free-solid-svg-icons";
import { Table, Modal } from "antd";
import appconstant from "../../themes/appconstant";
import images from "../../themes/appImage";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { useSelector, useDispatch } from "react-redux";
import { isInternetConnected } from "../../Utils/InternetConnection";
import { tableInitialData, capitalFirstLetter } from "../../Utils/helpers";
import LayoutHoc from "../../common/LayoutHoc";
import "./categoryManagement.css";
import { categoryDeleteAction, categoryListingAction, categoryEnableDisable } from "../../Redux/Action/categoryManagementAction";
import Checkbox from "antd/lib/checkbox/Checkbox";
import Icon from "@ant-design/icons/lib/components/Icon";
import { ExclamationCircleOutlined } from "@ant-design/icons";


const CategoryManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [payloadData, setPayload] = useState(tableInitialData)
  const userListingData = useSelector((state) => state.categoryManagementReducer);
  const { result, pagination, isLoading } = userListingData;
  const [addToUnassigned, setAddToUnassigned] = useState(false)
  const [currentDeleteRecord, setCurrentDeleteRecord] = useState(null)
  const [currentDisableRecord, setCurrentDisableRecord] = useState(null)
  const [currentDownloadRecord, setCurrentDownloadRecord] = useState(null)



  const fetchDataListing = () => {
    dispatch(categoryListingAction(payloadData, navigate));
  };
  localStorage.setItem("isUnassigned", false);


  const confirmationModalDisable = () => {

    return Modal.confirm({
      title: currentDisableRecord.isActive ? appconstant.disable : appconstant.enable,
      content: currentDisableRecord.isActive ? appconstant.categoryDisableConfirmation : appconstant.categoryEnableConfirmation,
      okText: "Yes",
      centered: true,
      cancelText: "No",

      onOk() {

        isInternetConnected() && dispatch(categoryEnableDisable({ categoryId: currentDisableRecord._id, isActive: !currentDisableRecord.isActive }));
        setCurrentDisableRecord(null)

      },
      onCancel() {
        setCurrentDisableRecord(null)
      },
      className: ".new-button-close",
      // maskClosable: true
    });
  };

  useEffect(() => {

    if (currentDisableRecord) {
      const modal = confirmationModalDisable()
      return () => {
        modal.destroy()
      }
    }
  }, [currentDisableRecord])
  // useState(() => {
  //   console.log({ addToUnassigned })
  // }, [addToUnassigned])

  const confirmDelete = () => {

    isInternetConnected() && dispatch(categoryDeleteAction(currentDeleteRecord?._id, addToUnassigned, navigate))
    setCurrentDeleteRecord(null)
    setAddToUnassigned(false)
  }
  const download = async (url) => {

    const originalImage = url;
    const image = await fetch(originalImage);

    // Split image name
    const nameSplit = originalImage.split("/");
    const duplicateName = nameSplit.pop();

    const imageBlog = await image.blob()
    const imageURL = URL.createObjectURL(imageBlog)
    const link = document.createElement('a')
    link.href = imageURL;
    link.download = "" + duplicateName + "";
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  };
  const downloadModal = (record) => {

    return Modal.success({
      title: appconstant.download,
      content:
        <div className="download-category-data-container">
          <table>
            <tr>
              <td>Animation</td>
              <td><Button onClick={() => download(appconstant.imageUrl + record.animation)}> <FontAwesomeIcon icon={faDownload} /></Button></td>
            </tr>
            <tr>
              <td>Category Background</td>
              <td><Button onClick={() => download(appconstant.imageUrl + record.categoryBackground)}> <FontAwesomeIcon icon={faDownload} /></Button></td>
            </tr>
            <tr>
              <td>Question Background</td>
              <td><Button onClick={() => download(appconstant.imageUrl + record.questionBackground)}> <FontAwesomeIcon icon={faDownload} /></Button></td>
            </tr>
            <tr>
              <td>Icon</td>
              <td><Button onClick={() => download(appconstant.imageUrl + record.icon)}> <FontAwesomeIcon icon={faDownload} /></Button></td>
            </tr>
          </table>
        </div>,
      centered: true,
      okText: "Close",
      onOk() {
        setCurrentDownloadRecord(null)
      }, onCancel() {
        setCurrentDownloadRecord(null)
      },
      className: "new-button",
      // maskClosable: true
    });
  };


  useEffect(() => {
    if (currentDownloadRecord) {
      const modal1 = downloadModal(currentDownloadRecord)
      return () => {
        modal1.destroy()
      }
    }
  }, [currentDownloadRecord])

  useEffect(() => {
    isInternetConnected() &&
      fetchDataListing()
  }, [payloadData]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [result])

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",
      sorter: false,
      render: (text, record, index) => index + 1 + (pagination?.nextPage - payloadData.limit),
    },
    {
      title: "Category Image",
      dataIndex: "categoryBackground",
      key: "categoryBackground",
      sorter: false,
      render: (img, row) => (
        < div >
          <img src={appconstant.imageUrl + row?.icon}></img>
        </div >
      ),
    },
    {
      title: "Category Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Category Accessability",
      dataIndex: "isPaid",
      key: "isPaid",
      // sorter: true,
      render: (isPaid, record) => (
        <div>
          {isPaid ? 'Paid' : 'Unpaid'}
        </div>
      ),
    },
    {
      title: "Category Map Type",
      dataIndex: "mapData",
      key: "mapData",
      // sorter: true,
      render: (mapData, record) => (
        <div>
          {mapData?.length > 0 ? mapData?.map(e => capitalFirstLetter(e?.name)).join(", ") : ""}
        </div>
      ),
    },
    {
      title: "Questions Count",
      dataIndex: "totalQuestions",
      key: "totalQuestions",
      sorter: true,
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      align: 'center',
      render: (text, record) => {
        return (
          <div className="action-icon category-table-action-wrapper"
          >
            {/* edit button */}
            <button
              onClick={() =>
                navigate("/edit-category", { state: { record } })
              }
            >
              {appconstant.edit}
            </button>
            <button
              onClick={() => {
                setCurrentDeleteRecord(
                  record
                );
              }}
            >
              {appconstant.delete}
            </button>
            <button
              onClick={() => {
                setCurrentDisableRecord(record)
              }}

              className={!record.isActive ? "red-background" : ""}
            >
              {record.isActive ? appconstant.disable : appconstant.enable}
            </button>
            <button
              onClick={() => {
                setCurrentDownloadRecord(record)
              }}
            >
              <FontAwesomeIcon icon={faDownload} />
            </button>
          </div>
        );
      },
    },
  ];

  const onPaginationChange = (e) => {
    setPayload((state) => ({ ...state, offset: (e - 1) * 10 }))
  }

  const handleChange = async (pagination, filters, sorter) => {
    setPayload((state) => ({
      ...state,
      order: sorter.order === "ascend"
        ? 1
        : sorter.order === "descend"
          ? -1 : null,
      sortBy: sorter.order ? sorter.columnKey : ""
    }))
  };

  return (
    <div>
      <BreadcrumbFn
        path={["dashboard"]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          appconstant.categoryMngmnt,
        ]}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          background: "#fff",
          padding: "17px 28px 0px 25px",
          alignItems: "center",
        }}
      >
        <h6 class="text-white text-capitalize ps-3 pt-10" >
          {appconstant.categoryMngmnt}
        </h6>
        <input
          type="text"
          className="iocn-search  search-bar"
          placeholder="Search"
          value={payloadData.search}
          onChange={(e) => {
            if (e.target.value.charAt() === " ") {
              return;
            }
            setPayload((state) => ({ ...state, search: e.target.value.trimStart() }));
          }
          }
        />
      </div>
      <LayoutHoc
        customClass={"userManagement"}
        child2={
          <>
            <Table
              className="user-management-table"
              dataSource={result}
              loading={isLoading}
              columns={columns}
              showSorterTooltip={false}
              onChange={handleChange}
              pagination={false}
            />
            <div className="table-pagination">
              <Pagination
                current={pagination?.currentPage}
                onChange={onPaginationChange}
                total={pagination?.totalCount}
                showSizeChanger={false}
              ></Pagination>
            </div>
          </>
        }
      >
        <Link to="/add-category">
          <button type="submit" className="addturna add-button">
            {appconstant.add}
          </button>
        </Link>
      </LayoutHoc>

      <Modal wrapClassName="category-delete-modal" visible={!!currentDeleteRecord} closable={false}
        maskClosable={false}
        title={
          <div>
            <ExclamationCircleOutlined />
            {appconstant.delete}
          </div>
        }
        okText="Yes" centered={true} cancelText="No" onOk={confirmDelete} className="new-button"
        onCancel={() => {
          setCurrentDeleteRecord(null)
          setAddToUnassigned(false)
        }}
      >
        <div className="category-delete-confirmation-container">
          <p>{appconstant.categoryDeleteConfirmation}</p>
          <div>
            <input type="checkbox" id="addToUnassigned" checked={addToUnassigned} onChange={(e) => {
              console.log(e.target.checked)
              setAddToUnassigned(!addToUnassigned)
            }} />
            <label >Save questions to unassigned category?</label>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CategoryManagement;
