import { apiConstants as types } from "../../themes/apiConstants";
const initialState = {
  isLoading: false,
  result: [],
  errorState: null,
  pagination: {},
  pollDetails: {},
  deleteData: false,
  pollPagination: {},
  reactionsData: {},
  countryCount: {},
  totalVotes1: [],
  countryReaction: {},
};

export const inAppManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    //poll listing
    case types.API_INAPP_MANAGEMENT_LISTING_LOAD:
      return { ...state, isLoading: true, errorState: null };
    case types.API_INAPP_MANAGEMENT_LISTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        result: action.result.data.list,
        pagination: action.result.data.pagination,
      };

    // //poll details
    // case types.API_POLL_MANAGEMENT_DETAILS_LOAD:
    //   return { ...state, isLoading: true, errorState: null };
    // case types.API_POLL_MANAGEMENT_DETAILS_SUCCESS:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     pollDetails: action.result.data,
    //     countryReaction: action.result?.countryObj,
    //     pollPagination: action.result.data.pagination,
    //   };

    // //poll update
    // case types.API_POLL_MANAGEMENT_UPDATE_LOAD:
    //   return { ...state, isLoading: true, errorState: null };
    // case types.API_POLL_MANAGEMENT_UPDATE_SUCCESS:
    //   return { ...state, isLoading: false };

    // //poll block
    // case types.API_POLL_MANAGEMENT_BLOCK_LOAD:
    //   return { ...state, isLoading: true, errorState: null };
    // case types.API_POLL_MANAGEMENT_BLOCK_SUCCESS:
    //   return { ...state, isLoading: false };

    // //poll country votes
    // case types.API_POLL_MANAGEMENT_COUNTRY_VOTES_LOAD:
    //   return { ...state, isLoading: true, errorState: null };
    // case types.API_POLL_MANAGEMENT_COUNTRY_VOTES_SUCCESS:
    //   return { ...state, isLoading: false, countryCount: action.result.data };

    // //poll country votes reactions
    // case types.API_POLL_MANAGEMENT_COUNTRY_VOTES_ON_REACTIONS_LOAD:
    //   return { ...state, isLoading: true, errorState: null };
    // case types.API_POLL_MANAGEMENT_COUNTRY_VOTES_ON_REACTIONS_SUCCESS:
    //   return { ...state, isLoading: false, reactionsData: action.result.data };

    // case types.API_CLEAR_POLL_DETAILS_LOAD:
    //   return { ...state, countryCount: {}, pollDetails: {} };

    // //total number of votes
    // case types.API_TOTAL_VOTES_LOAD:
    //   return { ...state, isLoading: true, errorState: null };
    // case types.API_TOTAL_VOTES_SUCCESS:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     totalVotes1: action.result.data.graph,
    //   };

    //poll Failed Case
    case types.API_POLL_MANAGEMENT_FAILED:
      return { ...state, isLoading: false };
    case types.API_POLL_MANAGEMENT_ERROR:
      return { ...state, isLoading: false };

    //Default case
    default:
      return { ...state };
  }
};
