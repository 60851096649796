import { combineReducers } from "redux";
import { authenticationReducer } from "./authenticationReducer";
import { dashboardReducer } from "./dashboardReducer";
import { categoryManagementReducer } from "./categoryManagementReducer";
import { userManagementReducer } from "./userManagementReducer";
import { reportManagementReducer } from "./reportListingReducer";
import { inAppManagementReducer } from "./inAppManagement";
import { questionManagementReducer } from "./questionManagementReducer";
import { playerAnswerReducer } from "./playerAnswerReducer";
import { suggestedCategoryManagementReducer } from "./suggestedCategoryManagementReducer ";
import { suggestedQuestionManagementReducer } from "./suggestedQuestionManagementReducer ";
import { superAdminManagementReducer } from "./superAdminManagementReducer";

const rootReducer = combineReducers({
  authenticationReducer,
  dashboardReducer,
  categoryManagementReducer,
  userManagementReducer,
  reportManagementReducer,
  inAppManagementReducer,
  questionManagementReducer,
  suggestedCategoryManagementReducer,
  suggestedQuestionManagementReducer,
  playerAnswerReducer,
  superAdminManagementReducer
});

export default rootReducer;
