import React, { useState, useEffect, useRef } from "react";
import { Button, Dropdown, Menu, Pagination, Spin, Tabs } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Table, Modal } from "antd";
import appconstant from "../../themes/appconstant";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { useSelector, useDispatch } from "react-redux";
import { isInternetConnected } from "../../Utils/InternetConnection";
import { capitalFirstLetter, handlePaginationChange, tableInitialData } from "../../Utils/helpers";
import LayoutHoc from "../../common/LayoutHoc";
import "./superAdmin.css";
import { superAdminListAction, deleteSuperAdminAction } from "../../Redux/Action/superAdminAction";
import { ExclamationCircleOutlined, UploadOutlined } from "@ant-design/icons";

const SubAdminManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const adminListingData = useSelector((state) => state.superAdminManagementReducer);


  let { result, pagination, isLoading } = adminListingData;
  const [payloadData, setPayload] = useState({ ...tableInitialData })
  const [currentDeleteRecord, setCurrentDeleteRecord] = useState(null)
  const [addToUnassigned, setAddToUnassigned] = useState(false)

  const fetchDataListing = () => {
    dispatch(superAdminListAction({ ...payloadData }))
  };


  useEffect(() => {
    isInternetConnected()
      &&
      fetchDataListing()
  }, [payloadData]);


  const itemsT = [
    {
      key: '5',
      label: (
        "5"
      ),
    },
    {
      key: '10',
      label: (
        "10"
      ),
    },
    {
      key: '20',
      label: (
        "20"
      ),
    },
    {
      key: '50',
      label: (
        "50"
      ),
    },
  ];



  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",
      sorter: false,
      render: (text, record, index) => <div>{
        payloadData.offset + (index + 1)
      }</div>
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (name, record) => (
        <div>
          {capitalFirstLetter(record?.name) || "N/A"}
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
      render: (email, record) => <div className="question-table-question-col">{record?.email ? record?.email : "N/A"}</div>,
    },
    {
      title: "Created on",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: false,
      render: (createdAt, record) => <div> {createdAt ?
        new Date(createdAt).toLocaleDateString('en-AU', {
          day: '2-digit',
          month: 'long',
          year: 'numeric'
        })
        : "N/A"
      }</div>,
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      align: 'center',
      render: (text, record) => {
        return (
          <div className="action-icon"
          >
            {/* view button */}
            <button

              onClick={() =>
                navigate("/view-sub-admin", { state: record })
              }
            >
              {appconstant.view}
            </button>
            {/* delete button */}
            <button
              onClick={() => {
                setCurrentDeleteRecord(record);
              }}
            >
              {appconstant.delete}
            </button>
          </div>
        );
      },
    },
  ];

  const onPaginationChange = (e) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setPayload((state) => ({ ...state, offset: (e - 1) * payloadData.limit }))
  }

  const handleChange = async (pagination, filters, sorter) => {
    setPayload((state) => ({
      ...state,
      order: sorter.order === "ascend"
        ? 1
        : sorter.order === "descend"
          ? -1 : null,
      sortBy: sorter.order ? sorter.columnKey : ""
    }))
  };

  const confirmDelete = () => {

    isInternetConnected() &&
      dispatch(deleteSuperAdminAction({ adminId: currentDeleteRecord?._id, search }));
    setCurrentDeleteRecord(null)
    setAddToUnassigned(false)
  }

  return (

    <div>
      <BreadcrumbFn
        path={["dashboard"]}
        pathName={[
          <FontAwesomeIcon icon={faHome} />,
          appconstant.superAdmin,
        ]}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          background: "#fff",
          // padding: "10px 28px 0 25px",
          padding: "17px 28px 0px 25px",
          alignItems: "center",
        }}
      >
        <h6 class="text-white text-capitalize ps-3 pt-10" >
          {appconstant.superAdmin}
        </h6>
        <input
          type="text"
          className="iocn-search  search-bar"
          placeholder="Search"
          value={payloadData.search}
          onChange={(e) => {
            if (e.target.value.charAt() === " ") {
              return;
            }
            setSearch(e.target.value)
            setPayload((state) => ({ ...state, offset: 0, search: e.target.value.trimStart() }));
          }
          }
        />
      </div>
      <LayoutHoc
        customClass={"userManagement"}
        child2={
          <>
            <Table
              className="user-management-table"
              dataSource={result?.map(e => ({ ...e, key: e._id }))}
              loading={isLoading}
              columns={columns}
              showSorterTooltip={false}
              onChange={handleChange}
              pagination={false}

            />
            <div className="table-pagination">
              <Pagination
                current={pagination?.currentPage}
                onChange={onPaginationChange}
                total={pagination?.totalCount}
                showSizeChanger={false}
                pageSize={payloadData.limit}
              ></Pagination>


            </div>

          </>
        }
      >
        <div className="flex">
          <Link to="/add-sub-admin">
            <button type="submit" className="addturna add-button">
              {appconstant.add}
            </button>
          </Link>

        </div>
      </LayoutHoc >

      <Modal wrapClassName="category-delete-modal" visible={!!currentDeleteRecord} closable={false}
        maskClosable={false}
        title={
          <div>
            <ExclamationCircleOutlined />
            {appconstant.delete}
          </div>
        }
        okText="Yes" centered={true} cancelText="No" onOk={confirmDelete} className="new-button"
        onCancel={() => {
          setCurrentDeleteRecord(null)
          setAddToUnassigned(false)
        }}
      >
        <div className="category-delete-confirmation-container">
          <p>{appconstant.adminDeleteConfirmation}</p>

        </div>
      </Modal>


    </div >
  );
};

export default SubAdminManagement;
