import { takeLatest, call, put } from "redux-saga/effects";
import { apiConstants as types, appMessages } from "../../themes/apiConstants";
import axios from "../api";
import { toast } from "react-toastify";

function* authenicationUser(result, navigate) {
  console.log("navigate", navigate)
  toast.error(result?.error);
  localStorage.removeItem("token");
  localStorage.clear();
  navigate.navigate("/");
}

function* suggestedQuestionFailedSaga(result) {
  yield put({
    type: types.API_SUGGESTED_QUESTION_MANAGEMENT_FAILED,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

function* suggestedQuestionErrorSaga(result) {
  yield put({
    type: types.API_SUGGESTED_QUESTION_MANAGEMENT_FAILED,
  });
  if (result.status === 2) {
    toast.error(appMessages.messageStatus500);
  } else {
    toast.error(result?.error, { toastId: result?.status || "est" });
  }
}

function* suggestedQuestionListingSaga(action) {
  try {
    const result = yield call(
      axios.suggestedQuestionListing,
      action.data
    );
    if (result.status === 1) {
      yield put({
        type: types.API_SUGGESTED_QUESTION_MANAGEMENT_LISTING_SUCCESS,
        result: result.result.data,
      });
    } else if (result.status === 4) {
      yield call(authenicationUser, result, action);
    } else {
      yield call(suggestedQuestionFailedSaga, result);
    }
  } catch (error) {
    yield call(suggestedQuestionErrorSaga, error);
  }
}

function* suggestedQuestionAcceptSaga(action) {

  try {
    const result = yield call(axios.suggestedQuestionAccept, action.data);
    if (result.status === 1) {
      const resultStatus = yield call(
        axios.suggestedQuestionListing, {
        limit: 10,
        offset: 0,
        order: null,
        search: "",
        sortBy: ""
      }
      );

      if (resultStatus.status === 1) {
        yield put({
          type: types.API_SUGGESTED_QUESTION_MANAGEMENT_LISTING_SUCCESS,
          result: resultStatus.result.data,
        });
      }
      if (result.status === 1) {
        yield put({
          type: types.API_SUGGESTED_QUESTION_MANAGEMENT_ACCEPT_SUCCESS,
          result: result.result.data,
        });
      }

      toast.success(result.result.data.message);
    } else if (result.status === 4) {
      yield call(authenicationUser, result, action);
    } else {
      yield call(suggestedQuestionFailedSaga, result);
    }
  } catch (error) {
    yield call(suggestedQuestionErrorSaga, error);
  }
}

function* suggestedQuestionArchiveSaga(action) {

  try {
    const result = yield call(axios.suggestedArchive, action.data);
    if (result.status === 1) {
      const resultStatus = yield call(
        axios.suggestedQuestionListing, {
        limit: 10,
        offset: 0,
        order: null,
        search: "",
        sortBy: ""
      }
      );

      if (resultStatus.status === 1) {
        yield put({
          type: types.API_SUGGESTED_QUESTION_MANAGEMENT_LISTING_SUCCESS,
          result: resultStatus.result.data,
        });
      }

      toast.success(result.result.data.message);
    } else if (result.status === 4) {
      yield call(authenicationUser, result, action);
    } else {
      yield call(suggestedQuestionFailedSaga, result);
    }
  } catch (error) {
    yield call(suggestedQuestionErrorSaga, error);
  }
}

export default function* rootSuggestedQuestionManagementSaga() {
  yield takeLatest(
    types.API_SUGGESTED_QUESTION_MANAGEMENT_LISTING_LOAD,
    suggestedQuestionListingSaga
  );
  yield takeLatest(
    types.API_SUGGESTED_QUESTION_MANAGEMENT_ACCEPT_LOAD,
    suggestedQuestionAcceptSaga
  );

  yield takeLatest(
    types.API_SUGGESTED_QUESTION_MANAGEMENT_ARCHIVE_LOAD,
    suggestedQuestionArchiveSaga
  );
}
