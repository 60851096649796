import { takeLatest, call, put } from "redux-saga/effects";
import { apiConstants as types, appMessages } from "../../themes/apiConstants";
import axios from "../api";
import { toast } from "react-toastify";
import { authenticationFailedSaga } from "./authenticationSaga";

// function* authenticationFailedSaga(result, navigate) {
//   toast.error(result?.error);
//   localStorage.removeItem("token");
//   localStorage.clear();
//   navigate("/");
// }

function* pollFailedSaga(result) {
  yield put({
    type: types.API_POLL_MANAGEMENT_FAILED,
  });
  toast.error(result?.error, { toastId: result?.status || "est" });
}

function* pollErrorSaga(result) {
  yield put({
    type: types.API_POLL_MANAGEMENT_ERROR,
  });
  if (result.status === 2) {
    toast.error(appMessages.messageStatus500);
  } else {
    toast.error(result?.error, { toastId: result?.status || "est" });
  }
}

function* inAppListingSaga(action) {
  const { search, offset, limit, sortBy, order, status } = action;
  try {
    const result = yield call(
      axios.inAppListing,
      search,
      offset,
      limit,
      sortBy,
      order,
      status
    );
    if (result.status === 1) {
      yield put({
        type: types.API_INAPP_MANAGEMENT_LISTING_SUCCESS,
        result: result.result.data,
      });
    } else if (result.status === 4) {
      yield call(authenticationFailedSaga, result, action.navigate);
    } else {
      yield call(pollFailedSaga, result);
    }
  } catch (error) {
    yield call(pollErrorSaga, error);
  }
}

// const obj = { ageReactions: {} };
// function* pollDetailsSaga(action) {
//   const { id } = action;
//   try {
//     const result = yield call(axios.pollDetails, id);

//     if (result.status === 1) {
//       const countryObj = {};
//       const PollDetails = JSON.parse(JSON.stringify(result.result.data.data));

//       PollDetails.data.countryReactions.forEach((elm) => {
//         countryObj[elm.name] = elm.reactions;
//       });
//       yield put({
//         type: types.API_POLL_MANAGEMENT_DETAILS_SUCCESS,
//         result: { ...PollDetails, countryObj },
//       });
//     } else if (result.status === 4) {
//       yield call(authenticationFailedSaga, result, action.navigate);
//     } else {
//       yield call(pollFailedSaga, result);
//     }
//   } catch (error) {
//     console.log("eeeeeeee", error);
//     yield call(pollErrorSaga, error);
//   }
// }

// function* pollUpdateSaga(action) {
//   const { data } = action;
//   try {
//     const result = yield call(axios.pollUpdate, data);
//     if (result.status === 1) {
//       yield put({
//         type: types.API_POLL_MANAGEMENT_UPDATE_SUCCESS,
//         result: result.result.data,
//       });
//       action.navigate("/pollmanagement");
//       toast.success(result.result.data.message, {
//         toastId: result?.status || "abc",
//       });
//     } else if (result.status === 4) {
//       yield call(authenticationFailedSaga, result, action.navigate);
//     } else {
//       yield call(pollFailedSaga, result);
//     }
//   } catch (error) {
//     yield call(pollErrorSaga, error);
//   }
// }

// function* pollBlockSaga(action) {
//   const { id, search } = action;
//   try {
//     const result = yield call(axios.pollBlock, id);
//     if (result.status === 1) {
//       const resultStatus = yield call(
//         axios.pollListing,
//         search,
//         0,
//         10,
//         "",
//         null
//       );
//       if (result.status === 1) {
//         yield put({
//           type: types.API_POLL_MANAGEMENT_LISTING_SUCCESS,
//           result: resultStatus.result.data,
//         });
//       }
//       yield put({
//         type: types.API_POLL_MANAGEMENT_BLOCK_SUCCESS,
//         result: result.result.data,
//       });
//       toast.success(result.result.data.message, {
//         toastId: result?.status || "abc",
//       });
//     }
//     // else if (result.status === 4) {
//     //     yield call(authenticationFailedSaga, result, action.navigate)
//     // }
//     else {
//       yield call(pollFailedSaga, result);
//     }
//   } catch (error) {
//     yield call(pollErrorSaga, error);
//   }
// }

// function* countryVotesSaga(action) {
//   const { payload } = action;
//   try {
//     const result = yield call(axios.countryVotes, payload);
//     if (result.status === 1) {
//       yield put({
//         type: types.API_POLL_MANAGEMENT_COUNTRY_VOTES_SUCCESS,
//         result: result.result.data,
//       });
//     } else if (result.status === 4) {
//       yield call(authenticationFailedSaga, result, action.navigate);
//     } else {
//       yield call(pollFailedSaga, result);
//     }
//   } catch (error) {
//     yield call(pollErrorSaga, error);
//   }
// }

// function* votesOnReactionsSaga(action) {
//   const { payload } = action;
//   try {
//     const result = yield call(axios.VotesOnReactions, payload);
//     if (result.status === 1) {
//       yield put({
//         type: types.API_POLL_MANAGEMENT_COUNTRY_VOTES_ON_REACTIONS_SUCCESS,
//         result: result.result.data,
//       });
//     } else if (result.status === 4) {
//       yield call(authenticationFailedSaga, result, action.navigate);
//     } else {
//       yield call(pollFailedSaga, result);
//     }
//   } catch (error) {
//     yield call(pollErrorSaga, error);
//   }
// }

// function* totalVotesSaga(action) {
//   const { payload } = action;
//   try {
//     const result = yield call(axios.totalVotes, payload);
//     if (result.status === 1) {
//       yield put({
//         type: types.API_TOTAL_VOTES_SUCCESS,
//         result: result.result.data,
//       });
//     } else if (result.status === 4) {
//       yield call(authenticationFailedSaga, result, action.navigate);
//     } else {
//       yield call(pollFailedSaga, result);
//     }
//   } catch (error) {
//     yield call(pollErrorSaga, error);
//   }
// }

export default function* inAppManagementSaga() {
  yield takeLatest(types.API_INAPP_MANAGEMENT_LISTING_LOAD, inAppListingSaga);
  // yield takeLatest(types.API_POLL_MANAGEMENT_DETAILS_LOAD, pollDetailsSaga);
  // yield takeLatest(types.API_POLL_MANAGEMENT_UPDATE_LOAD, pollUpdateSaga);
  // yield takeLatest(types.API_POLL_MANAGEMENT_BLOCK_LOAD, pollBlockSaga);
  // yield takeLatest(
  //   types.API_POLL_MANAGEMENT_COUNTRY_VOTES_LOAD,
  //   countryVotesSaga
  // );
  // yield takeLatest(
  //   types.API_POLL_MANAGEMENT_COUNTRY_VOTES_ON_REACTIONS_LOAD,
  //   votesOnReactionsSaga
  // );
  // yield takeLatest(types.API_TOTAL_VOTES_LOAD, totalVotesSaga);
}
