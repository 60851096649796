import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import appconstant from "../../themes/appconstant";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { useSelector, useDispatch } from "react-redux";
import { categoryListingActionForDropDown } from "../../Redux/Action/categoryManagementAction";
import LayoutHoc from "../../common/LayoutHoc";
import "./questionsManagement.css";
import { isInternetConnected } from "../../Utils/InternetConnection";
import * as Yup from "yup";
import { AutoComplete, Select, Spin } from "antd";
import { useFormik } from "formik";
import { ValidationConstant } from "../../themes/validationConstant";
import { questionAddAction, questionListingAction } from "../../Redux/Action/questionManagementAction";



const AddQuestions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [categoryList, setCategoryList] = useState([]);
  const categoryListingData = useSelector((state) => state.categoryManagementReducer);
  const { result } = categoryListingData;
  const questionListingData = useSelector((state) => state.questionManagementReducer);
  const [questions, setQuestions] = useState([])

  const validationSchema = Yup.object().shape({
    category: Yup.string()
      .required(ValidationConstant.category.empty).min(2, ValidationConstant.category.min),
    question: Yup.string()
      .required(ValidationConstant?.question.empty).min(2, ValidationConstant?.question.min).max(150, ValidationConstant?.question.max),
  });

  const initialValues = {
    category: "",
    question: ""
  };

  const fetchDataListing = () => {
    dispatch(categoryListingActionForDropDown(navigate));
  };

  useEffect(() => {
    document.title = appconstant.MANIFEST_MOTO;
    isInternetConnected() &&
      fetchDataListing();
    // dispatch(clearUserDetails());
  }, []);

  useEffect(() => {
    setCategoryList(result)
  }, [result]);

  useEffect(() => {
    console.log({ questionListingData });
    setQuestions(questionListingData.result.map(e => ({ value: `${e?.question} (${e.category?.name})`, key: e._id })))
  }, [questionListingData])

  useEffect(() => {
    console.log({ questions });
  }, [questions])
  useEffect(() => {
    formik.setFieldValue("category", categoryList.find(e => e?.name.toLowerCase() == "unassigned")?._id)
  }, [categoryList])


  const onSubmit = (values, { setSubmitting }) => {
    let data = {
      categoryId: values.category,
      question: values?.question,
      type: "simple"
    };
    isInternetConnected() && dispatch(questionAddAction(data, navigate, "/questions-management"));
  };

  const validate = (e) => {
    let error = {};
    for (let i in e) {
      if (e[i] === null) {
        error[i] = ValidationConstant.category[i]
      }
    }

    return error
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validate,
    onSubmit,
    // enableReinitialize: true
  });

  return (
    <Spin spinning={questionListingData?.isLoading}>

      <div>
        <BreadcrumbFn
          path={["dashboard", "questions-management"]}
          pathName={[
            <FontAwesomeIcon icon={faHome} />,
            appconstant.qnsMngmnt,
            appconstant.addQn,
          ]}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            background: "#fff",
            padding: "17px 28px 0px 25px",
            // padding: "10px 28px 0 25px",
            alignItems: "center",
          }}
        >
          <h6 class="text-white text-capitalize ps-3 ">
            {appconstant.addQn}
          </h6>
        </div>
        <LayoutHoc customClass={"userManagement"}>
          <div
            className="wrapper_line view_modules view"
            style={{
              width: "100%",
            }}
          >
            <form onSubmit={formik.handleSubmit}>
              {/* category name nd questions */}
              <div>

                <div className="form-group margin-0" >
                  <label>Category Name</label>

                  <Select
                    id={"id"}
                    size="large"
                    value={formik.values.category || "Select Category"}
                    className="category-accessability"
                    style={{ width: "100%" }}
                    name="category"
                    onChange={(e) => { formik.setFieldValue("category", e) }}
                    placeholder="Select Category"
                    options={categoryList.map((item) => {
                      return { value: item._id, label: item?.name + (item?.name !== "UNASSIGNED" ? `(${item?.mapData?.map(e => e.name.charAt(0).toUpperCase() + e.name?.slice(1)).join(', ')})` : '') }
                    }
                    )}

                  />
                </div>
                <div className="error-question-um">{formik.errors.category || ""}</div>


                <div className="form-group margin-0">
                  <label>Question</label>
                  {/* <input
                value={formik.values.question}
                style={{
                  margin: "0.5em 0 1em",
                }}
                name="question"
                maxLength={160}
                placeholder="Question"
                onChange={(e) => {
                  formik.setFieldValue("question", e.target.value.trimStart())
                  isInternetConnected() && dispatch(questionListingAction({search: e.target.value.trimStart()}, navigate));
                }}
              /> */}
                  <AutoComplete
                    className="add-question-auto-complete"
                    options={questions}
                    name="question"
                    maxLength={160}
                    onBlur={formik.handleBlur}
                    placeholder="Question"
                    value={formik.values?.question}
                    dropdownClassName="add-question-auto-complete-dropdown"
                    onChange={(e) => {
                      if (e.charAt() === " ") {
                        return;
                      }
                      formik.setFieldValue("question", e.trimStart())
                      if (e.length <= 10) {
                        isInternetConnected() && dispatch(questionListingAction({ search: e.trimStart(), isUnassigned: localStorage.isUnassigned, offset: 0, limit: 10, type: "simple" }, navigate))
                      }

                    }}
                    //onKeyDown={ }
                    filterOption={(inputValue, option) =>
                      option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                  {/* NOTE: autocomplete dropdown pointer events is set none in questionsManagement.css */}
                </div>
                {(formik.touched?.question && formik.errors?.question) &&
                  <div className="error-question-um">{formik.errors?.question || ""}</div>
                }


              </div>

              {/* submit button */}
              <div className="add-question-btn-wrapper">
                <button
                  type="submit"
                  className="addturna submit-button"
                >
                  {appconstant.submit}
                </button>
              </div>
            </form>
          </div>
        </LayoutHoc>
      </div >
    </Spin>
  );
};

export default AddQuestions;
