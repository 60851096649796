import { apiConstants as types } from "../../themes/apiConstants";
const initialState = {
  isLoading: false,
  result: {},
  errorState: null,
  userRegisteredToday: 0,
  userRegisteredTotal: 0,
  pollRegisteredToday: 0,
  pollRegisteredTotal: 0,
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.API_DASHBOARD_LOAD:
      return { ...state, isLoading: true, errorState: null };
    case types.API_DASHBOARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        result: action.result,
        // userRegisteredToday: action.result.user.today,
        // userRegisteredTotal: action.result.user.total,
        // pollRegisteredToday: action.result.poll.today,
        // pollRegisteredTotal: action.result.poll.total,
      };

    case types.API_REFERRAL_LOAD:
      return { ...state, isLoading: true, errorState: null };
    case types.API_REFERRAL_SUCCESS:
      return { ...state, isLoading: false, result: { ...initialState.result, referralAmount: action.result.referralAmount } }

    //Authentication Failed Case
    case types.API_DASHBOARD_FAILED:
      return { ...state, isLoading: false };
    case types.API_DASHBOARD_ERROR:
      return { ...state, isLoading: false };

    //Default case
    default:
      return { ...state };
  }
};
