import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import appconstant from "../../themes/appconstant";
import appImage from "../../themes/appImage";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { useDispatch, useSelector } from "react-redux";
import LayoutHoc from "../../common/LayoutHoc";
import "./categoryManagement.css";
import { Select, Spin, Tooltip } from "antd";
import { isInternetConnected } from "../../Utils/InternetConnection";
import { useFormik } from "formik";
import { ValidationConstant } from "../../themes/validationConstant";
import { categoryMapListAction, categoryUpdateAction } from "../../Redux/Action/categoryManagementAction";
import { capitalFirstLetter, animationNameForCategory } from "../../Utils/helpers";


const EditCategory = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation()


  const [animation, setAnimation] = useState(appconstant.imageUrl + location.state.record.animation)
  const [music, setMusic] = useState(appconstant.imageUrl + location.state.record.music)
  const [categoryBackground, setCategoryBackground] = useState(appconstant.imageUrl + location.state.record.categoryBackground)
  const [questionBackground, setQuestionBackground] = useState(appconstant.imageUrl + location.state.record.questionBackground)
  const [icon, setIcon] = useState(appconstant.imageUrl + location.state.record.icon)
  const mapList = useSelector(state => state.categoryManagementReducer.mapList)
  const loadingData = useSelector(state => state.categoryManagementReducer.isLoading)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [loadingData])
  const selectOptions = [
    {
      value: 'Paid',
      name: 'Paid'
    },
    {
      value: 'Unpaid',
      name: 'Unpaid'
    }
  ]

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(ValidationConstant.category.empty).min(2, ValidationConstant.category.min).max(22, ValidationConstant.category.max),
    type: Yup.string()
      .required(ValidationConstant.category.categoryAccessibility),
    description: Yup.string().when('type', {
      is: 'Paid',
      then: Yup.string().required(ValidationConstant.category.descriptionRequire).min(2, ValidationConstant.category.descriptionMin).max(150, ValidationConstant.category.descriptionMax),
    }),
    stars: Yup.string().when('type', {
      is: 'Paid',
      then: Yup.string()
        .required(ValidationConstant.category.starRequire).test('not-starts-with-zero', 'Stars can not be zero.', value => {
          return !/^0/.test(value);
        })
        .min(2, ValidationConstant.category.starMin)
        .max(10, ValidationConstant.category.starMax)
    }),
    animation: Yup.mixed()
      .test('fileType', 'Only JSON files are allowed', (value) => {
        if (value instanceof File) return value && value.type === 'application/json';
        return true;
      }),
    icon: Yup.mixed()
      .test('fileType', 'Only JPG, PNG, and SVG files are allowed', (value) => {
        if (value instanceof File) return value && ['image/jpeg', 'image/png', 'image/svg+xml'].includes(value.type);
        return true;
      })
      .test("fileSize", "Max allowed size is 100KB",
        value => {
          if (!(value instanceof File)) return true
          return value && value.size <= 102400
        }),
    questionBackground: Yup.mixed()
      .test('fileType', 'Only JPG, PNG, and SVG files are allowed', (value) => {
        if (value instanceof File) return value && ['image/jpeg', 'image/png', 'image/svg+xml'].includes(value.type);
        return true;
      })
      .test("fileSize", "Max allowed size is 1000KB",
        value => {
          if (!(value instanceof File)) return true
          return value && value.size <= 1000000
        }),
    categoryBackground: Yup.mixed()
      .test('fileType', 'Only JPG, PNG, and SVG files are allowed', (value) => {
        if (value instanceof File) return value && ['image/jpeg', 'image/png', 'image/svg+xml'].includes(value.type);
        return true;
      })
      .test("fileSize", "Max allowed size is 1000KB",
        value => {
          if (!(value instanceof File)) return true
          return value && value.size <= 1000000
        }),
    music: Yup.mixed()
      .required('Please select an MP3 file.')
      .test('fileType', 'Only MP3 files are allowed', (value) => {
        if (value instanceof File) return value && ['audio/mpeg'].includes(value.type);
        return true;
      })
      .test("fileSize", "Max allowed size is 1000KB",
        value => {
          if (!(value instanceof File)) return true
          return value && value.size <= 10485760
        }),
  });

  const initialValues = {
    name: location.state.record.name,
    description: location.state.record?.description || "",
    stars: location.state.record.stars,
    type: location.state.record.isPaid ? 'Paid' : 'Unpaid',
    animation,
    categoryBackground,
    questionBackground,
    icon,
    music,
    mapType: location?.state?.record?.mapData?.map(e => e?._id) || []
  };
  const onSubmit = (values) => {
    console.log("datacheck", values)
    let form = new FormData()
    form.append("categoryId", location.state.record._id)
    form.append("type", values.type)
    form.append("mapType", JSON.stringify(values.mapType))
    form.append("name", values.name)
    form.append("stars", values.stars)
    form.append("description", values.description)
    if (typeof values.icon === 'object') form.append("icon", values.icon)
    if (typeof values.animation === 'object') form.append("animation", values.animation)
    if (typeof values.music === 'object') form.append("music", values.music)
    if (typeof values.categoryBackground === 'object') form.append("categoryBackground", values.categoryBackground)
    if (typeof values.questionBackground === 'object') form.append("questionBackground", values.questionBackground)

    isInternetConnected() && dispatch(categoryUpdateAction(form, navigate));
  };



  const validate = (e) => {
    let error = {};
    for (let i in e) {
      if (e[i] === null) {
        error[i] = ValidationConstant.category[i]
      }
    }

    return error
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validate,
    onSubmit,
  });

  console.log("formikkkkerror", formik.errors)
  useEffect(() => {
    dispatch(categoryMapListAction())
  }, [])


  return (
    <Spin spinning={loadingData}>
      <div>
        <BreadcrumbFn
          path={["dashboard", "category-management"]}
          pathName={[
            <FontAwesomeIcon icon={faHome} />,
            appconstant.categoryMngmnt,
            appconstant.editCategory,
          ]}
        />
        <div
          style={{

            display: "flex",
            justifyContent: "space-between",
            background: "#fff",
            padding: "17px 28px 0px 25px",
            alignItems: "center",
          }}
        >
          <h6 class="text-white text-capitalize ps-3">
            {appconstant.editCategory}
          </h6>
        </div>
        <LayoutHoc customClass={"userManagement"}>
          <div
            className="wrapper_line view_modules view"
            style={{
              width: "100%",
            }}
          >
            {/* category name */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                margin: "20px 0px",
                borderRadius: "5px",
              }}
            >
              <div
                style={{
                  alignItems: "flex-start",
                  flex: 1,
                  marginBottom: "13px",
                }}
              >
                <label
                  style={{
                    //maxWidth: "22%",
                    fontSize: "16px",
                  }}
                >
                  {"Category Name"}
                </label>
              </div>
              <div
                style={{
                  alignItems: "flex-start",
                  flex: 1,
                }}
              >
                <input

                  maxLength={22}
                  value={formik.values.name}
                  style={{
                    width: "100%",
                    margin: 0,
                    fontSize: "16px",
                  }}
                  placeholder="Category Name"
                  onChange={(e) => {
                    formik.setFieldValue("name", e.target.value.trimStart())
                  }}
                />
              </div>
              {formik.errors.name ? (
                <div className="error-category-um">{formik.errors.name}</div>
              ) : null}
            </div>
            {/* category accessability */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                margin: "20px 0px",
                borderRadius: "5px",
              }}
            >
              <div
                style={{
                  alignItems: "flex-start",
                  flex: 1,
                  marginBottom: "13px",
                }}
              >
                <label
                  style={{
                    fontSize: "16px",
                  }}
                >
                  {"Category Accessibility"}
                </label>
              </div>
              <div
                style={{
                  alignItems: "flex-start",
                  flex: 1,
                }}
              >
                <Select
                  id={"id"}
                  size="large"
                  value={formik.values.type}
                  className="category-accessability"
                  style={{ width: "100%" }}
                  name="type"
                  onChange={(e) => { formik.setFieldValue("type", e) }}
                  placeholder="Select Category Accessibility"
                  options={selectOptions}
                />
              </div>
              {formik.errors.type ? (
                <div className="error-category-um">{formik.errors.type}</div>
              ) : null}
            </div>

            {/* CATEGORY DESCRIPTION */}
            {formik.values.type === "Unpaid" ? null : (<div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                margin: "20px 0px",
                borderRadius: "5px",
              }}
            >
              <div
                style={{
                  alignItems: "flex-start",
                  flex: 1,
                  marginBottom: "13px",
                }}
              >
                <label
                  style={{
                    //maxWidth: "22%",
                    fontSize: "16px",
                  }}
                >
                  {"Category Description"}
                </label>
              </div>
              <div
                style={{
                  alignItems: "flex-start",
                  flex: 1,
                }}
              >
                <input

                  maxLength={160}
                  value={formik.values.description}
                  style={{
                    width: "100%",
                    margin: 0,
                    fontSize: "16px",
                  }}
                  placeholder="Category Description"
                  onChange={(e) => {
                    formik.setFieldValue("description", e.target.value.trimStart())
                  }}
                />
              </div>
              {formik.errors.description ? (
                <div className="error-category-um">{formik.errors.description}</div>
              ) : null}
            </div>)}

            {/* STARS */}
            {formik.values.type === "Unpaid" ? null : (<div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                margin: "20px 0px",
                borderRadius: "5px",
              }}
            >
              <div
                style={{
                  alignItems: "flex-start",
                  flex: 1,
                  marginBottom: "13px",
                }}
              >
                <label
                  style={{
                    //maxWidth: "22%",
                    fontSize: "16px",
                  }}
                >
                  {"Category Stars"}
                </label>
              </div>
              <div
                style={{
                  alignItems: "flex-start",
                  flex: 1,
                }}
              >
                <input

                  maxLength={4}
                  value={formik.values.stars}
                  style={{
                    width: "100%",
                    margin: 0,
                    fontSize: "16px",
                  }}
                  placeholder="Category Stars"
                  onChange={
                    (e) => {
                      if (e.target.value.startsWith(' ') || ! /^[0-9]*$/.test(e.target.value)) e.preventDefault()
                      else formik.setFieldValue("stars", e.target.value.trimStart())
                    }}
                />
              </div>
              {formik.errors.stars ? (
                <div className="error-category-um">{formik.errors.stars}</div>
              ) : null}
            </div>)}

            {/* Category map type */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                margin: "20px 0px",
                borderRadius: "5px",
              }}
            >
              <div
                style={{
                  alignItems: "flex-start",
                  flex: 1,
                  marginBottom: "13px",
                }}
              >
                <label
                  style={{
                    //maxWidth: "22%",
                    fontSize: "16px",
                  }}
                >
                  {"Category Map Type"}
                </label>
              </div>
              <div
                style={{
                  alignItems: "flex-start",
                  flex: 1,
                }}
              >
                <Select
                  id={"id"}
                  size="large"
                  value={formik.values.mapType}
                  className="category-accessability"
                  style={{ width: "100%" }}
                  mode="multiple"
                  name="mapType"
                  onChange={(e) => formik.setFieldValue("mapType", e)}
                  placeholder="Select Category Map Type"
                  options={mapList.map(e => ({ label: capitalFirstLetter(e.name), value: e._id }))}
                />
              </div>
              {formik.errors.mapType ? (
                <div className="error-category-um">{formik.errors.mapType}</div>
              ) : null}
            </div>

            {/* Sound */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                margin: "20px 0px",
                borderRadius: "5px",
              }}
            >
              <div
                style={{
                  alignItems: "flex-start",
                  flex: 1,
                  marginBottom: "13px",
                }}
              >
                <label
                  style={{
                    //maxWidth: "22%",
                    fontSize: "16px",
                  }}
                >
                  {"Animation"}
                </label>
              </div>
              <div
                style={{
                  alignItems: "center",
                  flex: 1,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    border: '1px solid #1b5449',
                    width: '100%',
                    borderRadius: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 21px'
                  }}
                >
                  <label
                    htmlFor="upload-audio"
                    style={{
                      position: "relative",
                      maxWidth: 'max-content'
                    }}
                  >

                    <Tooltip
                      title={'Click to change animation.'}
                      placement="top"
                    >
                      <div
                        className="button-list addturna "
                        style={{
                          width: "100%",
                          padding: 0,
                          fontWeight: "600",
                          maxWidth: "min-content",
                          border: "0",
                          margin: "3px",
                          marginLeft: "0px",
                          color: "#fff",
                          padding: "10px 10px",
                          cursor: "pointer",
                          borderStyle: "solid",
                          backgroundColor: "#1b5449",
                          borderRadius: "8px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img src={appImage?.uploadImg} width={'20px'} />
                      </div>
                    </Tooltip>
                  </label>
                  <input
                    id="upload-audio"
                    type={"file"}
                    accept=".json"
                    style={{
                      width: "8%",
                      fontSize: "16px",
                      cursor: "pointer",
                      display: "none",
                    }}
                    onChange={(e) => {
                      const files = e.target.files;
                      if (e.target.files[0]?.type == "application/json") setAnimation(files[0])
                      formik.setFieldValue("animation", files[0])
                    }}
                  />
                  {
                    <label
                      style={{
                        fontSize: "16px",
                        marginLeft: '8px',
                        maxWidth: 'unset',

                      }}
                    >

                      {initialValues.animation && initialValues?.animation instanceof File ? initialValues?.animation?.name : animationNameForCategory(initialValues?.animation)}
                    </label>
                  }
                </div>
              </div>
              {formik.errors.animation ? (
                <div className="error-category-um">{formik.errors.animation}</div>
              ) : null}
            </div>
            {/* Music */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                margin: "20px 0px",
                borderRadius: "5px",
              }}
            >
              <div
                style={{
                  alignItems: "flex-start",
                  flex: 1,
                  marginBottom: "13px",
                }}
              >
                <label
                  style={{
                    //maxWidth: "22%",
                    fontSize: "16px",
                  }}
                >
                  {"Music"}
                </label>
              </div>
              <div
                style={{
                  alignItems: "center",
                  flex: 1,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    border: '1px solid #1b5449',
                    width: '100%',
                    borderRadius: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 21px'
                  }}
                >
                  <label
                    htmlFor="upload-music"
                    style={{
                      position: "relative",
                      maxWidth: 'max-content'
                    }}
                  >

                    <Tooltip
                      title={'Click to change animation.'}
                      placement="top"
                    >
                      <div
                        className="button-list addturna "
                        style={{
                          width: "100%",
                          padding: 0,
                          fontWeight: "600",
                          maxWidth: "min-content",
                          border: "0",
                          margin: "3px",
                          marginLeft: "0px",
                          color: "#fff",
                          padding: "10px 10px",
                          cursor: "pointer",
                          borderStyle: "solid",
                          backgroundColor: "#1b5449",
                          borderRadius: "8px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img src={appImage?.uploadImg} width={'20px'} />
                      </div>
                    </Tooltip>
                  </label>
                  <input
                    id="upload-music"
                    type={"file"}
                    accept="mpeg, .mp3"
                    style={{
                      width: "8%",
                      fontSize: "16px",
                      cursor: "pointer",
                      display: "none",
                    }}
                    onChange={(e) => {
                      const files = e.target.files;
                      if (e.target.files[0]?.type == "audio/mpeg") setMusic(files[0])
                      formik.setFieldValue("music", files[0])
                    }}
                  />
                  {
                    <label
                      style={{
                        fontSize: "16px",
                        marginLeft: '8px',
                        maxWidth: 'unset',

                      }}
                    >
                      {
                        initialValues.music ?
                          initialValues.music instanceof File ?
                            initialValues.music.name :
                            initialValues.music.endsWith('/null') ? "N/A" :
                              (initialValues.music.substring(initialValues.music.lastIndexOf("/") + 1, initialValues.music.indexOf(".mp3") + 4))
                          : "N/A"
                      }
                    </label>
                  }
                </div>
              </div>
              {formik.errors.music ? (
                <div className="error-category-um">{formik.errors.music}</div>
              ) : null}
            </div>
            {/* upload images */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              {/* Question Background Image */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  margin: "20px 0px",
                  borderRadius: "5px",
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    alignItems: "flex-start",
                    flex: 1,
                    marginBottom: "13px",
                  }}
                >
                  <label
                    style={{
                      // //maxWidth: "22%",
                      fontSize: "16px",
                    }}
                  >
                    {"Question Background Image"}
                  </label>
                </div>
                <div
                  style={{
                    alignItems: "flex-start",
                    flex: 1,
                  }}
                >
                  <Tooltip placement={'right'}
                    title={
                      !formik.values.questionBackground
                        ? "Click to add image."
                        : "Click to change image."
                    }
                    key={"#143C64"}

                  >
                    <label
                      htmlFor="upload-qn"
                      style={{
                        width: "unset",
                        maxWidth: "unset",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          height: "100px",
                          width: "100px",
                        }}
                      >
                        <img
                          src={typeof formik?.values?.questionBackground === "object" ? URL.createObjectURL(formik.values.questionBackground) : formik?.values?.questionBackground || appImage?.plusCircle}
                          style={{
                            width: "100%",
                            height: "100%",
                            border: `1px solid ${formik.errors.questionBackground ? "red" : "#1b5449"
                              }`,
                            borderRadius: "5px",
                            padding: "9px",
                            background: "#fff",
                            margin: 0,
                            backgroundSize: "conatin",
                          }}
                        />

                        {formik.errors.questionBackground ? (
                          <div className="img-error-category-um">{formik.errors.questionBackground}</div>
                        ) : null}
                      </div>
                    </label>
                  </Tooltip>
                  <input
                    id="upload-qn"
                    type={"file"}
                    accept=".png,.svg,.jpg"
                    style={{
                      display: "none",
                    }}
                    onChange={(e) => {
                      setQuestionBackground(e.target.files[0])
                      formik.setFieldValue("questionBackground", e.target.files[0])
                    }}
                  />
                </div>
              </div>
              {/* Category Background Image */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  margin: "20px 0px",
                  borderRadius: "5px",
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    alignItems: "flex-start",
                    flex: 1,
                    marginBottom: "13px",
                  }}
                >
                  <label
                    style={{
                      //maxWidth: "22%",
                      fontSize: "16px",
                    }}
                  >
                    {"Category Background Image"}
                  </label>
                </div>
                <div
                  style={{
                    alignItems: "flex-start",
                    flex: 1,
                  }}
                >
                  <>
                    <Tooltip placement={'right'}
                      title={
                        !formik.values.categoryBackground
                          ? "Click to add image."
                          : "Click to change image."
                      }
                      key={"#143C64"}

                    >
                      <label
                        htmlFor="upload-cat"
                        style={{
                          width: "unset",
                          maxWidth: "unset",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          style={{
                            height: "100px",
                            width: "100px",
                          }}
                        >
                          <img
                            src={typeof formik?.values?.categoryBackground === "object" ? URL.createObjectURL(formik.values.categoryBackground) : formik?.values?.categoryBackground || appImage?.plusCircle}
                            style={{
                              width: "100%",
                              height: "100%",
                              border: `1px solid ${formik.errors.categoryBackground ? "red" : "#1b5449"}`,
                              borderRadius: "5px",
                              padding: "9px",
                              background: "#fff",
                              // margin: "15px 20px 14px 44%",
                              backgroundSize: "conatin",
                            }}
                          />

                          {formik.errors.categoryBackground ? (
                            <div className="img-error-category-um">{formik.errors.categoryBackground}</div>
                          ) : null}
                        </div>
                      </label>
                    </Tooltip>
                    <input
                      id="upload-cat"
                      type={"file"}
                      accept=".png,.svg,.jpg"
                      style={{
                        display: "none",
                      }}
                      onChange={(e) => {

                        formik.setFieldValue("categoryBackground", e.target.files[0])
                        setCategoryBackground(e.target.files[0])

                      }}
                    />
                  </>
                </div>
              </div>
              {/* Icon */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  margin: "20px 0px",
                  borderRadius: "5px",
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    alignItems: "flex-start",
                    flex: 1,
                    marginBottom: "13px",
                  }}
                >
                  <label
                    style={{
                      //maxWidth: "22%",
                      fontSize: "16px",
                    }}
                  >
                    {"Icon"}
                  </label>
                </div>
                <div
                  style={{
                    alignItems: "flex-start",
                    flex: 1,
                  }}
                >
                  <>
                    <Tooltip placement={'right'}
                      title={
                        !formik.values.icon
                          ? "Click to add icon. "
                          : "Click to change icon. "
                      }
                      key={"#143C64"}

                    >
                      <label
                        htmlFor="upload-icon"
                        style={{
                          width: "unset",
                          maxWidth: "unset",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          style={{
                            height: "100px",
                            width: "100px",
                          }}
                        >
                          <img
                            src={typeof formik?.values?.icon === "object" ? URL.createObjectURL(formik.values.icon) : formik?.values?.icon || appImage?.plusCircle}
                            style={{
                              width: "100%",
                              height: "100%",
                              border: `1px solid ${formik.errors.icon ? "red" : "#1b5449"
                                }`, borderRadius: "5px",
                              padding: "9px",
                              background: "#fff",
                              // margin: "15px 20px 14px 44%",
                              backgroundSize: "conatin",
                            }}
                          />

                          {formik.errors.icon ? (
                            <div className="img-error-category-um">{formik.errors.icon}</div>
                          ) : null}
                        </div>
                      </label>
                    </Tooltip>
                    <input
                      id="upload-icon"
                      type={"file"}
                      accept=".png,.svg,.jpg"
                      style={{
                        display: "none",
                      }}
                      onChange={(e) => {

                        setIcon(e.target.files[0])
                        formik.setFieldValue("icon", e.target.files[0])
                      }}
                    />
                  </>
                </div>
              </div>
            </div>
            <button
              onClick={formik.handleSubmit}
              className="addturna submit-button"
              style={{
                maxWidth: "15%",
                margin: "30px 0 0",
              }}
            >
              {appconstant.update}
            </button>
          </div>
        </LayoutHoc>
      </div>
    </Spin>
  );
};

export default EditCategory;
