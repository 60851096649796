import { all, fork } from "redux-saga/effects";
import rootAuthenticationSaga from "./authenticationSaga";
import rootDashboardSaga from "./dashboardSaga";
import rootCategoryManagementSaga from "./categoryManagementSaga";
import rootUserManagementSaga from "./userManagementSaga";
import rootReportManagementSaga from "./reportManagementSaga";
import inAppManagementSaga from "./inAppManagement";
import rootQuestionManagementSaga from "./questionManagementSaga";
import rootSuggestedCategoryManagementSaga from "./suggestedCategoryManagementSaga"
import rootSuggestedQuestionManagementSaga from "./suggestedQuestionManagementSaga"
import rootSuperAdminManagementSaga from "./superAdminManagementSaga"

export default function* rootSaga() {
  yield all([
    fork(rootAuthenticationSaga),
    fork(rootDashboardSaga),
    fork(rootCategoryManagementSaga),
    fork(rootUserManagementSaga),
    fork(inAppManagementSaga),
    fork(rootReportManagementSaga),
    fork(rootQuestionManagementSaga),
    fork(rootSuggestedCategoryManagementSaga),
    fork(rootSuggestedQuestionManagementSaga),
    fork(rootSuperAdminManagementSaga)
  ]);
}

