import { apiConstants as types } from "../../themes/apiConstants";

// category listing Action
export const suggestedCategoryListingAction = (data, navigate) => ({
  type: types.API_SUGGESTED_CATEGORY_MANAGEMENT_LISTING_LOAD,
  data,
  navigate
});

// category delete
export const suggestedCategoryAcceptRejectAction = (data, navigate) => ({
  type: types.API_SUGGESTED_CATEGORY_MANAGEMENT_ACCEPT_LOAD,
  data,
  navigate
});
export const suggestedCategoryArchiveAction = (data, navigate) => ({
  type: types.API_SUGGESTED_CATEGORY_MANAGEMENT_ARCHIVE_LOAD,
  data,
  navigate
});