import http from "./apiKit";
import moment from "moment";
import { appMessages } from "../../themes/apiConstants";
import { Navigate } from "react-router-dom";


export default {
  // ********** AUTHENTICATION **********
  // Login Api Call
  login(email, password) {
    let data = JSON.stringify({
      email: email,
      password: password,
    });
    return Method.POST("admin/login", data);
  },

  //user answer listing load
  userAnswerList(data) {
    return Method.POST(`/admin/user/user-answer`, data);
  },

  // forgot password Api Call
  forgotpassword(email) {
    let data = JSON.stringify({
      email: email,
    });
    return Method.POST("admin/forgot-password", data);
  },

  // Reset Password Api call
  resetPassword(newPassword, query) {
    let data = JSON.stringify({
      password: newPassword,
      token: query,
    });
    return Method.POST(`/admin/reset-password`, data);
  },

  // Check reset Password
  checkResetPassword(id) {
    let data = JSON.stringify({
      token: id,
    });
    return Method.POST(`admin/reset-password-token-validity`, data);
  },

  changePasswordApi(oldPassword, newPassword) {
    let data = JSON.stringify({
      oldPassword: oldPassword,
      newPassword: newPassword,
    });
    return Method.POST(`admin/change-password`, data);
  },

  logoutApi() {
    return Method.GET(`admin/logout`);
  },

  // ********** DASHBOARD **********
  // dashboard
  dashboard() {
    let a = moment().format("YYYY-MM-DDTHH:mm:ssZ");
    let data = {
      time: a,
    };
    return Method.POST("admin/dashboard", data);
  },

  // ********** Update referral **********

  updateReferral(data) {
    return Method.POST(`admin/update-referral`, data);
  },

  // ********** SUGGESTED CATEGORY MANAGEMENT **********
  suggestedCategoryListing(data) {
    return Method.POST(`/admin/suggested-category-list`, data);
  },
  suggestedCategoryAccept(data) {
    return Method.POST(`/admin/suggested-category-accept-reject`, data);
  },

  // ********** SUGGESTED QUESTION MANAGEMENT **********
  suggestedQuestionListing(data) {
    return Method.POST(`/admin/suggested-question-list`, data);
  },

  suggestedQuestionAccept(data) {
    return Method.POST(`/admin/suggested-question-accept-reject`, data);
  },

  suggestedArchive(data) {
    return Method.POST(`/admin/suggested-archive`, data);
  },

  // ********** CATEGORY MANAGEMENT **********
  // category listing
  categoryListing(data) {
    return Method.POST(`/admin/categories`, data);
  },

  categoryListingForDrop(data) {
    return Method.GET(`/admin/categories/list`);
  },

  categoryAdd(data) {
    return Method.POST(`admin/category/create`, data);
  },

  categoryDetails(id) {
    return Method.GET(`category/detail/${id}`);
  },

  categoryUpdate(data) {
    return Method.PUT(`admin/category/update`, data);
  },

  categoryDelete(id, addToUnassigned) {
    return Method.DELETE(`/admin/category/delete/${id}?addToUnassigned=${addToUnassigned}`);
  },

  categoryEnableDisable(data) {
    return Method.PUT(`/admin/category/enable-disable`, data);
  },

  mapList() {
    return Method.GET(`/admin/map-list`);
  },

  // ********** SUPER ADMIN MANAGEMENT **********

  // super admin listing
  adminListingApi(data) {
    return Method.POST(`/admin/admin-list`, data);
  },

  // super admin detail
  adminDetailApi(data) {
    return Method.POST(`/admin/view-admin`, data);
  },

  // delete super admin
  adminDeleteApi(data) {
    return Method.POST(`/admin/delete-admin`, data);
  },

  // add super admin
  adminAddApi(data) {
    return Method.POST(`/admin/create-admin`, data);
  },


  // ********** QUESTIONS MANAGEMENT **********
  // questions listing
  questionListing(data) {
    return Method.POST(`/admin/question/list`, data);
  },

  questionAdd(data) {
    return Method.POST(`/admin/question/add`, data);
  },

  questionDetails(id) {
    return Method.GET(`/admin/questions/detail/${id}`);
  },

  questionUpdate(data) {
    return Method.PUT(`/admin/question/update`, data);
  },

  questionDelete(data) {
    return Method.POST(`/admin/question/delete`, data);
  },

  questionImport(data) {
    return Method.POST(`/admin/import-csv`, data);
  },

  playerAnswerList(data) {
    return Method.POST(`/admin/answer-list`, data);
  },

  // ********** USER MANAGEMENT **********
  // user listing
  userListing(search, offset, limit, sortBy, order) {
    let data = JSON.stringify({
      search: search,
      offset: offset,
      limit: limit,
      sort: sortBy,
      order: order,
    });
    return Method.POST(`/admin/users`, data);
  },

  userDetails(id) {
    return Method.GET(`admin/user/detail/${id}`);
  },

  userUpdate(payload) {
    let formData = new FormData();
    formData.append("userId", payload.id);
    formData.append("firstName", payload.firstName);
    formData.append("lastName", payload.lastName);
    if (payload.gender) {
      formData.append("gender", payload.gender);
    }
    if (payload.education) {
      formData.append("education", payload.education);
    }
    if (payload.country) {
      formData.append("country", payload.country);
    }
    if (payload.employed) {
      formData.append("employed", payload.employed);
    }
    if (payload.city) {
      formData.append("city", payload.city);
    }
    if (payload.bio) {
      formData.append("bio", payload.bio);
    }
    formData.append("profile", payload.image);
    return Method.PUT(`admin/edituser`, formData);
  },

  userBlock(data) {
    return Method.POST(`admin/user/block-unblock`, data);
  },

  userDelete(id) {
    return Method.DELETE(`admin/user/delete/${id}`);
  },

  // ********** IN-APP MANAGEMENT **********
  // IN-APP listing
  inAppListing(search, offset, limit, sortBy, order) {
    let data = JSON.stringify({
      search: search,
      offset: offset,
      limit: limit,
      sort: sortBy,
      order: order,
    });
    return Method.POST(`/admin/purchases/list`, data);
  },

  // ********** REPORT MANAGEMENT **********
  // report listing
  reportListing(data) {
    return Method.POST(`/admin/report/list`, data);
  },

  reportAppeal(id) {
    return Method.GET(`admin/appeal/view/${id}`);
  },

};



const Method = {
  // Get Method
  async GET(url) {
    return await new Promise((resolve, reject) => {
      http
        .get(url, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((result) => {
          if (result.status === 200) {
            return resolve({
              status: 1,
              result: result,
            });
          } else {
            if (result) {
              return reject({
                status: 3,
                error: result.data.message,
              });
            } else {
              return reject({
                status: 5,
                error: appMessages.messageStatus500,
              });
            }
          }
        })
        .catch((err) => {
          if (err.response) {
            if (
              err.response.status !== null &&
              err.response.status !== undefined
            ) {
              if (err.response.status === 500) {
                return reject({
                  status: 2,
                  error: err,
                });
              } else if (err.response.status === 401) {
                return resolve({
                  status: 4,
                  error: err?.response?.data?.message,
                });
              } else if (
                err.response.status === 404
              ) {
                //  logout()
                return reject({
                  status: 4,
                  error: err?.response?.data?.message,
                });
              } else if (err.response.status === 400) {
                return resolve({
                  status: 9,
                  error: err?.response?.data?.message,
                });
              } else {
                return reject({
                  status: 8,
                  error: err,
                });
              }
            }
          } else {
            return reject({
              status: 6,
              error: appMessages.messageStatus500,
            });
          }
        });
    });
  },

  // Post Method

  async POST(url, body) {
    return await new Promise((resolve, reject) => {
      http
        .post(url, body, {
          headers: {
            "access-control-allow-origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((result) => {
          if (result.status === 200) {
            return resolve({
              status: 1,
              result: result,
            });
          } else {
            if (result) {
              return reject({
                status: 3,
                error: result.data.message,
              });
            } else {
              return reject({
                status: 5,
                error: appMessages.messageStatus500,
              });
            }
          }
        })
        .catch((err) => {
          if (err.response) {
            if (
              err.response.status !== null &&
              err.response.status !== undefined
            ) {
              if (err.response.status === 500) {
                return reject({
                  status: 2,
                  error: err,
                });
              } else if (err.response.status === 401) {
                // logout();
                return resolve({
                  status: 4,
                  error: err?.response?.data?.message,
                });
              } else if (
                err.response.status === 400 ||
                err.response.status === 401 ||
                err.response.status === 404
              ) {
                return reject({
                  status: 4,
                  error: err?.response?.data?.message,
                });
              } else if (err.response.status === 400) {
                return resolve({
                  status: 9,
                  error: err?.response?.data?.message,
                });
              } else {
                return reject({
                  status: 8,
                  error: err,
                });
              }
            }
          } else {
            return reject({
              status: 6,
              error: appMessages.messageStatus500,
            });
          }
        });
    });
  },

  // Put Method
  async PUT(url, body) {
    return await new Promise((resolve, reject) => {
      http
        .put(url, body, {
          headers: {
            "access-control-allow-origin": "*",
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((result) => {
          if (result.status === 200) {
            return resolve({
              status: 1,
              result: result,
            });
          } else {
            if (result) {
              return reject({
                status: 3,
                error: result.data.message,
              });
            } else {
              return reject({
                status: 5,
                error: appMessages.messageStatus500,
              });
            }
          }
        })
        .catch((err) => {
          if (err.response) {
            if (
              err.response.status !== null &&
              err.response.status !== undefined
            ) {
              if (err.response.status === 500) {
                return reject({
                  status: 2,
                  error: err?.response?.data?.error?.message,
                });
              } else if (err.response.status === 401) {
                // logout();
                return resolve({
                  status: 4,
                  error: err?.response?.data?.message,
                });
              } else if (
                err.response.status === 400 ||
                err.response.status === 401 ||
                err.response.status === 404
              ) {
                return reject({
                  status: 4,
                  error: err?.response?.data?.message,
                  // result: err?.response?.data?.data
                });
              } else if (err.response.status === 400) {
                return resolve({
                  status: 9,
                  error: err?.response?.data?.message,
                });
              } else {
                return reject({
                  status: 8,
                  error: err,
                });
              }
            }
          } else {
            return reject({
              status: 6,
              error: appMessages.messageStatus500,
            });
          }
        });
    });
  },

  // Delete Method
  async DELETE(url) {
    return await new Promise((resolve, reject) => {
      http
        .delete(url, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        })

        .then((result) => {
          if (result.status === 200) {
            return resolve({
              status: 1,
              result: result,
            });
          }
          // else if (result.status === 212) {
          //   return resolve({
          //     status: 4,
          //     result: result
          //   });
          // }
          else {
            if (result) {
              return reject({
                status: 3,
                error: result.data.message,
              });
            } else {
              return reject({
                status: 5,
                error: appMessages.messageStatus500,
              });
            }
          }
        })
        .catch((err) => {
          if (err.response) {
            if (
              err.response.status !== null &&
              err.response.status !== undefined
            ) {
              if (err.response.status === 500) {
                return reject({
                  status: 2,
                  error: err,
                });
              } else if (err.response.status === 403) {
                // logout();
                return reject({
                  status: 4,
                  error: err?.response?.data?.message,
                });
              } else if (err.response.status === 401 || err.response.status === 404) {
                return resolve({
                  status: 4,
                  error: err?.response?.data?.message,
                });
              } else if (err.response.status === 400) {
                return resolve({
                  status: 9,
                  error: err?.response?.data?.message,
                });
              } else {
                return reject({
                  status: 8,
                  error: err,
                });
              }
            }
          } else {
            return reject({
              status: 6,
              error: appMessages.messageStatus500,
            });
          }
        });
    });
  },
};
