import React from "react";
import { Navigate } from "react-router-dom";

export const PrivateRoute = ({ children }) => {
  let tokenCheck = localStorage.token;
  return tokenCheck ? children : <Navigate to={`/login`} />;
};

export const PublicRoute = ({ children }) => {
  let tokenCheck = localStorage.token;
  return !tokenCheck ? children : <Navigate to="/" />;
};

export default PrivateRoute;
