import React from 'react';
import './layout.css';

const LayoutHoc = ({ children, customClass, child2, customClassParent }) => {
  return (
    <div
      className={`site-layout-background ${customClassParent}`}
      style={{ padding: 24, minHeight: 360 }}
    >
      <div className="content-e">
        <div class={`bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line ${customClass}`}>
          {
            children
          }
        </div>
        {child2}
      </div>
    </div>
  )
}

export default LayoutHoc