import React from 'react'
import Card from '../../common/Card';
import LayoutHoc from '../../common/LayoutHoc' 

const TabContent = (props) => {
    const {
        mapItems ,
        tabContentTitle,
        customClass,
        smallImg = false
    } = {...props} ;
  return ( 
      <>
        {/* <div className="heading-top-desk">
            <h1>{tabContentTitle}</h1>
        </div> */}
        <LayoutHoc
            customClass = {customClass}
            customClassParent={'pt-10'}
            >
                {
                    mapItems.map((item)=>{
                        return (
                            <Card
                              imageSrc={item?.imageSrc}
                              title={item?.title}
                              count={item?.count}
                              smallImg = {smallImg}
                            />
                        )
                    })
                }
          </LayoutHoc>
      </>
  )
}

export default TabContent;